import { Add, ArrowBack } from '@mui/icons-material';
import {
  Container,
  Card,
  Box,
  Stack,
  Button,
  CardContent,
  TextField,
  Grid,
  CircularProgress,
  CardActions,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import CustomAddButton from '../../components/customAddButton/CustomAddButton';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import { apiCall, handleSuccessSubmit } from '../../components/functions/apiCall';
import { ADD_COMPANY, API_SIGN } from '../../variables/api-variables';
import FormAlerts from '../../components/form/FormAlerts';
import { decryptToken } from '../../components/security/securityFunctions';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';

const AddCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line camelcase
  const { company_id, company_name, company_address, company_status } = location.state;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isCreate, setIsCreate] = useState(true);
  const buttonTitle = isCreate ? 'Add' : 'Update';
  const [isLoading, setIsLoading] = useState(false);

  const saveGoBack = () => {
    // navigate back
    navigate('/dashboard/companies');
  }

  const saveAddAnother = () => {
    if (isCreate) {
      resetForm();
      setIsCreate(true);
    }
  }

  const resetForm = () => {
    setFormValues('');
  };

  const navigateBack = () => {
    if (location.state !== null && location.state.allowBack !== null && location.state.allowBack === true) {
      window.history.back();
    } else {
      navigate('/dashboard/companies');
    }
  };

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (company_id) {
      setIsCreate(false);
      setFormValues({
        // eslint-disable-next-line camelcase
        name: company_name,
        // eslint-disable-next-line camelcase
        address: company_address,
        // eslint-disable-next-line camelcase
        status: company_status,
      });
    }
    // eslint-disable-next-line camelcase
  }, [company_id, company_name, company_address, company_status]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: formValues?.name || '',
      address: formValues?.address || '',
      status: formValues?.status || 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required'),
      address: Yup.string().max(255).required('Address is required'),
    }),
    onSubmit: async (values, helpers) => {
      setIsLoading(false);
      setErrorMessage('');
      try {
        const payload = {
          company_name: values.name,
          company_address: values.address,
          status: values.status,
          function_type: isCreate ? 'create_company' : 'alter_company',
          api_signature: API_SIGN,
          jwt_token: userToken,
        };

        if (!isCreate) {
          // eslint-disable-next-line camelcase
          payload.company_id = company_id;
        }
        const inputData = JSON.stringify(payload);
        // Make the API call
        setIsLoading(true);
        apiCall(inputData, 'POST', ADD_COMPANY, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              enqueueSnackbar(isCreate ? 'Company added successfully. ' : 'Company details updated successfully', {
                variant: 'success',
                autoHideDuration: 2000
              });
              if (isCreate) {
                formik.resetForm();
                resetForm();
              }
              handleSuccessSubmit(saveGoBack, saveAddAnother);
            } else {
              enqueueSnackbar(response.data.response, {
                variant: 'error',
                autoHideDuration: 2000
              });
              setErrorMessage(response.data.response);
            }
          })
          .catch((error) => {
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 2000
            });
            console.log('Error adding company:', error.message);
            setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: 'error',
          autoHideDuration: 2000
        });
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>{isCreate ? 'Add' : 'Update'} Company | Rejoice Diary</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path="companies" />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title={isCreate ? 'Create Company' : 'Update Company'} />
                <div>
                  {location.state && location.state.showCancelBtn ? (
                    <Button
                      color="info"
                      variant="text"
                      size="small"
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        navigateBack();
                      }}
                    >
                      <ArrowBack />
                      &nbsp; Back
                    </Button>
                  ) : null}
                  {!isCreate ? (
                    <Button
                      color="info"
                      variant="text"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        navigateBack();
                      }}
                    >
                      <ArrowBack />
                      &nbsp; Back
                    </Button>
                  ) : null}
                </div>
              </Stack>

              {/* starting form here */}
              <Box>
                <div>
                  <Card sx={{ p: 2, margin: '15px 0px' }}>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={6}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                          <Stack spacing={3}>
                            <TextField
                              variant="outlined"
                              label="Name"
                              name="name"
                              error={!!(formik.touched.name && formik.errors.name)}
                              helperText={formik.touched.name && formik.errors.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ marginBottom: '15px' }}
                              value={formik.values.name}
                            />
                            <TextField
                              variant="outlined"
                              label="Address"
                              name="address"
                              error={!!(formik.touched.address && formik.errors.address)}
                              helperText={formik.touched.address && formik.errors.address}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ marginBottom: '15px' }}
                              value={formik.values.address}
                            />
                            {!isCreate ? (
                              <FormControl>
                                <InputLabel id="role-status">Status</InputLabel>
                                <Select
                                  labelId="role-status"
                                  id="demo-simple-select1"
                                  variant="outlined"
                                  label="Status"
                                  name="status"
                                  error={!!(formik.touched.status && Boolean(formik.errors.status))}
                                  onChange={(e) => {
                                    formik.setFieldValue('status', e.target.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  inputlabelprops={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.status}
                                >
                                  <MenuItem value={'1'}>Active</MenuItem>
                                  <MenuItem value={'0'}>Blocked</MenuItem>
                                </Select>
                              </FormControl>
                            ) : null}
                          </Stack>
                          {formik.errors.submit && (
                            <Typography color="error" sx={{ mt: 3 }} variant="body2">
                              {formik.errors.submit}
                            </Typography>
                          )}
                          <FormAlerts errorMessage={errorMessage} />

                          {/* <CardActions sx={{ justifyContent: 'end', padding: '0px' }}>
                            <Button variant="contained" disabled={isLoading} type="submit" sx={{ margin: '20px 0px' }}>
                              {!isLoading ? buttonTitle : <CircularProgress color={'info'} size={20} />}
                            </Button>
                          </CardActions> */}

                          <CustomAddButton
                            btnType={'submit'}
                            buttonTitle={buttonTitle}
                            variant="contained"
                            disabled={false}
                            styleProps={{ margin: '20px 0px', padding: '0px' }}
                            onClick={null}
                            isLoading={isLoading}
                          />
                        </form>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddCompany;
