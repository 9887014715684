export const handlePdfDownload = (response, filename) => {
  if (response.status === 200) {
    const contentType = response.headers['content-type'];

    if (contentType.includes('application/json')) {
      const jsonData = response.data;
      console.log('JSON Data:', jsonData);
    } else {
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${filename}.pdf`;
      link.click();
    }
  } else {
    console.error('File download failed', response.status);
  }
};

export const handleSpreadsheetDownload = (response, filename) => {
  if (response.status === 200) {
    const contentType = response.headers['content-type'];

    if (contentType.includes('application/json')) {
      const jsonData = response.data;
      console.log('JSON Data:', jsonData);
    } else {
      const blob = new Blob([response.data], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${filename}.xlsx`;
      link.click();
    }
  } else {
    console.error('File download failed', response.status);
  }
};

export const handlePrint = (response) => {
  const printWindow = window.open('', '_blank');

  printWindow.document.write(response.data);

  printWindow.document.close();

  printWindow.print();
};
