import { BrowserRouter, Route, Routes, unstable_HistoryRouter as useHistory } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';

// routes
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import CookieConsentBanner from './CookieConsentBanner';
import store from './store';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider>
        <HelmetProvider>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider>
                <StyledChart />
                <ScrollToTop />
                <Router />
                <CookieConsentBanner />
              </ThemeProvider>
            </LocalizationProvider>
          </BrowserRouter>
        </HelmetProvider>
      </SnackbarProvider>
    </Provider>
  );
}
