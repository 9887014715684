import React from 'react';
import Cookies from 'js-cookie';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { API_SIGN, DELETE_COMPANY } from '../../variables/api-variables';
import { decryptToken } from '../../components/security/securityFunctions';
import { apiCall } from '../../components/functions/apiCall';

const DeleteSpecificCompany = ({ open, onClose, deleteId, fetchCompanies, companyToDelete }) => {
  const { userToken } = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  // console.log(deleteId)

  const deleteCompany = async () => {
    try {
      const input_data = JSON.stringify({
        function_type: 'delete_company',
        company_id: deleteId,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
    //   console.log(input_data);
      apiCall(input_data, 'POST', DELETE_COMPANY, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            enqueueSnackbar('Company deleted successfully.', {
              variant: 'success',
              autoHideDuration: 2000,
            });
            fetchCompanies();
            onClose();
          } else {
            enqueueSnackbar("Couldn't delete the company.", {
              variant: 'error',
              autoHideDuration: 2000,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('An error occurred during company deletion.', { variant: 'error', autoHideDuration: 2000 });
        });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('An error occurred during company deletion.', { variant: 'error', autoHideDuration: 2000 });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: 'red' }}>Delete Company</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete {companyToDelete} ?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button
          onClick={() => {
            deleteCompany();
            onClose();
          }}
          autoFocus
          sx={{ color: 'red' }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSpecificCompany;
