import Cookies from "js-cookie";
import { decryptToken } from "../security/securityFunctions";

// export function objectToValuesArray(data) {
//     return (Object.values(data));
//   }

//   export const reorderObject = (obj, keys) => {
//     if (Array.isArray(obj)) {
//       return obj.map((elem) => reorderObject(elem, keys));
//     }
//     const result = {};
//     for (const key of keys) {
//       if (Object.prototype.hasOwnProperty.call(obj, key)) {
//         result[key] = obj[key];
//       }
//     }
//     for (const key of Object.keys(obj)) {
//       if (!keys.includes(key)) {
//         delete result[key];
//       }
//     }
//     return result;
//   };

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    if (i === 1) {
        const valueInKB = bytes / k ** i;
        let roundedValue;

        // Apply rounding rule: round up if fractional part is above 0.5, otherwise round down
        if (valueInKB - Math.floor(valueInKB) > 0.5) {
            roundedValue = Math.ceil(valueInKB);
        } else {
            roundedValue = Math.floor(valueInKB);
        }

        return `${roundedValue} ${sizes[i]}`;
    }
    return `${parseFloat((bytes / (k ** i)).toFixed(dm))}   ${sizes[i]}`;
}

export function reformatDate(strdate) {
    const dateParts = strdate.split("-");
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export function indianFormatNumber(number, digits = 2) {
    // Currency Format
    const urlDetailing = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}');
    digits = 2;

    if (number) {
        return parseFloat(number).toLocaleString('en-IN', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }
    else
        return number;
}

