import { Add, FolderShared, Group, Lock } from '@mui/icons-material';
import { Box, Grid, TablePagination, ToggleButton, TableContainer, TableCell, Table, TableHead, TableRow, TableBody, Typography, Paper, Badge, Fab } from '@mui/material';


import React, { useState } from 'react'
import SpecificImage from "../../../pages/my_files/specific_file/SpecificFile";
import { formatBytes, reformatDate } from '../../functions/data_functions';
import ToggleMode from './ToggleMode';


const DataView = ({ data,
  handlePageChange,
  handleRowsPerPageChange,
  page,
  rowsPerPage,
  setOpenAddFolderOrFile,
  openAddFolderOrFile,
  viewType,
  onSetViewType,
  setOpenEditFile = { setOpenEditFile },
  length,
  viewDriveItems,

}) => {
  const [showSpecificImage, setShowSpecificImage] = useState(false);
  const [imageInfo, setImageInfo] = useState({});
  const handleViewChange = (type) => {
    onSetViewType(type);
  };

  const columnsPerRow = 5;


  const rows = Array.isArray(data)
    ? Array.from({ length: Math.ceil(data.length / columnsPerRow) }, (_, rowIndex) =>
      data.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow)
    )
    : [];

  const renderFileIcon = (item) => {
    const extension = item.file_extension;

    const iconPaths = {
      xlsx: "/assets/logos/excelLogo.png",
      pdf: "/assets/logos/LogoPdf.png",
      doc: "/assets/logos/docxLogo.png",
    };

    if (iconPaths[extension]) {
      if (viewType === "grid") {
        return (
          <img
            src={iconPaths[extension]}
            alt={item.filename}
            style={{
              width: "80px",
              height: "80px",
              backgroundColor: extension === ("xlsx" || "xls") ? "white" : "transparent",
            }}
          />
        );
      } else {
        return (
          <img
            src={iconPaths[extension]}
            alt={item.filename}
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: extension === ("xlsx" || "xls") ? "white" : "transparent",
            }}
          />
        );
      }
    }

    if (viewType === "grid") {
      // console.log(item.key)
      return <img src={item.key} alt={item.filename} style={{ width: "80px", height: "80px" }} />;

    }
    else {
      return <img src={item.key} alt={item.filename} style={{ width: "30px", height: "30px" }} />;

    }
  };

  const handleImageShow = (imgInfo) => {
    setShowSpecificImage(imgInfo);
    setImageInfo({ ...imgInfo });
  };

  return (
    <Box>

      {showSpecificImage ? (
        <SpecificImage
          imageInfo={imageInfo}
          setShowSpecificImage={setShowSpecificImage}
          setOpenAddFolderOrFile={setOpenAddFolderOrFile}
          openAddFolderOrFile={openAddFolderOrFile}
          setOpenEditFile={setOpenEditFile}
          viewDriveItems={viewDriveItems}
        />
      ) : null}

      <ToggleMode viewType={viewType} onChange={handleViewChange} />

      {viewType === "grid" ? (
        <>
          <Grid container spacing={2} component={Paper} style={{ padding: "16px" }}>
            {rows.map((row) =>
              row.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={2.4}
                  key={index}
                  onClick={() => handleImageShow(item)}
                  sx={{
                    cursor: "pointer",
                    transition: "0.2s",
                    "&:hover": {
                      transform: "scale(1.0)",
                      boxShadow: "0 4px 20px rgba(0,0,0,0.12)",
                      padding: '20px',
                      width: "100%",
                      maxWidth: '180px'
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                      border: "1px solid #e0e0e0",
                      borderRadius: "4px",
                      height: "160px",
                      width: "100%",
                      maxWidth: "150px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        p: "2px 5px",
                        fontSize: "10px",
                        borderRadius: "0 0 0 4px",
                      }}
                    >
                      {item.share_mode === "public" && (
                        <Group sx={{ color: "#f0f0f0", fontSize: "15px" }} />
                      )}
                      {item.share_mode === "private" && (
                        <Lock sx={{ color: "#f0f0f0", fontSize: "15px" }} />
                      )}
                      {item.share_mode === "protected" && (
                        <FolderShared sx={{ color: "#f0f0f0", fontSize: "15px" }} />
                      )}
                    </Box>
                    <Box
                      sx={{
                        p: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {renderFileIcon(item)}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      <Typography
                        variant="body2"
                        title={item.filename}
                        sx={{
                          mt: "5px",
                          fontSize: "12px",
                          maxWidth: "140px",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.filename}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "9px", color: "#f0f0f0", textAlign: "center" }}
                      >
                        {formatBytes(item.size_of_file)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            page={page}
            count={Number(length)}
            rowsPerPage={rowsPerPage}
            component="div"
            onPageChange={(e, value) => {
              handlePageChange(e, value);
            }}
            onRowsPerPageChange={(e) => {
              handleRowsPerPageChange(e);
            }}
          />
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">File</TableCell>
                <TableCell align="right">Size</TableCell>
                <TableCell align="left">Date created</TableCell>
                <TableCell align="left">Termination date</TableCell>
                <TableCell align="left">Document date</TableCell>
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) &&
                data.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => handleImageShow(item)}
                    sx={{
                      cursor: "pointer",
                      transition: "0.2s",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <TableCell align="left" style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                      {renderFileIcon(item)}
                      <Typography variant="body2" style={{ marginLeft: "10px" }}>
                        {item.filename ? <>
                          {item.filename}
                          <span style={{ fontSize: "9px" }}> <br />{item?.reminder_date ? <> Reminder Date {reformatDate(item.reminder_date)}</> : ""}</span>
                        </>
                          : "-"}
                      </Typography>
                      {/* </div> */}
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                        {formatBytes(item.size_of_file ? item.size_of_file : "-")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {item.date_created ? reformatDate(item.date_created) : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {item.termination_document_date ? reformatDate(item.termination_document_date) : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {item.date_of_document ? reformatDate(item.date_of_document) : "-"}
                    </TableCell>
                    <TableCell align="left" sx={{ textAlign: "center" }}>
                      {item.share_mode ? (
                        item.share_mode === "public" ? (
                          <Group sx={{ color: "grey", fontSize: "15px" }} />
                        ) : item.share_mode === "private" ? (
                          <Lock sx={{ color: "grey", fontSize: "15px" }} />
                        ) : item.share_mode === "protected" ? (
                          <FolderShared sx={{ color: "grey", fontSize: "15px" }} />
                        ) : null
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            page={page}
            count={Number(length)}
            rowsPerPage={rowsPerPage}
            component="div"
            onPageChange={(e, value) => {
              handlePageChange(e, value);
            }}
            onRowsPerPageChange={(e) => {
              handleRowsPerPageChange(e.target.value);
            }}
          />
        </TableContainer>
      )
      }

    </Box >
  )


}

export default DataView