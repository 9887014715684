import { FiberManualRecord, InfoOutlined, Repeat } from '@mui/icons-material';
import {
  Alert,
  Container,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { capitalCase } from 'change-case';
import { getDatedmY } from '../../components/functions/formattingFunctions';
import { API_SIGN, UPDATE_REMINDER_STATUS } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import palette from '../../theme/palette';

const DisplayReminderData = ({
  userToken,
  keyId,
  reminderData,
  totalRows,
  rowsPerPage,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  displayAddReminder,
}) => {
  const [componentKey, setComponentKey] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getRepeatOnlabel = (x) => {
    if (['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].includes(x)) {
      return `Every ${x}`;
    }
    return x;
  };

  const noReminderComponent = (
    <Container style={{ margin: '0px 10px', padding: '10px 10px' }} maxWidth={false}>
      <Alert severity="warning" sx={{ margin: '10px' }}>
        No reminders found for the selected criteria
      </Alert>
    </Container>
  );

  useEffect(() => {
    setComponentKey(keyId);
  }, []);

  const updateMarkStatus = (reminderId, markStatus) => {
    try {
      const inputData = JSON.stringify({
        function_type: 'update_reminders',
        mark_status: markStatus,
        reminder_id: reminderId,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', UPDATE_REMINDER_STATUS, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            // anything
          } else {
            enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 2000 });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return reminderData && reminderData.length > 0 ? (
    <Paper sx={{ width: '100%' }} key={componentKey}>
      {reminderData &&
        reminderData.map((row) => {
          return (
            <Container
              maxWidth={false}
              sx={{ borderBottom: 'solid 1px #eeeeee', padding: '10px 0px' }}
              key={row.id + new Date().getMilliseconds()}
            >
              <Stack direction={'row'} justifyContent={'start'} alignItems={'start'} overflow={'hidden'}>
                <FormControlLabel
                  control={
                    <input
                      type="checkbox"
                      name="reminder_checkbox"
                      style={{ marginRight: '10px' }}
                      value={row.id}
                      defaultChecked={row.mark_status === '1'}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          updateMarkStatus(row.id, 0);
                        } else {
                          updateMarkStatus(row.id, 1);
                        }
                      }}
                    />
                  }
                  sx={{ marginLeft: '0px', marginTop: '3px' }}
                />
                <Stack direction={'column'} overflow={'hidden'} flex={1}>
                  <Typography variant="subtitle2" letterSpacing={0.5} color={palette.grey[900]}>
                    {row.title}{' '}
                    <FiberManualRecord
                      sx={{ fontSize: 15, marginLeft: '5px' }}
                      color={row.priority === '1' ? 'error' : row.priority === '2' ? 'warning' : 'disabled'}
                    />
                  </Typography>
                  <Typography
                    variant="caption"
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    color={palette.grey[800]}
                  >
                    {row.description}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={
                      new Date(row.reminder_date) <= new Date() && row.mark_status === '0'
                        ? palette.error.main
                        : palette.grey[600]
                    }
                  >
                    {getDatedmY(new Date(row.reminder_date))}
                    {row?.reminder_time ? `, ${row.reminder_time}` : ''}
                    {row?.repeat_on !== 'never' ? (
                      <>
                        &nbsp;&nbsp;
                        <Repeat fontSize="28px" />
                        &nbsp;&nbsp;
                        {capitalCase(getRepeatOnlabel(row.repeat_on))}
                      </>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Stack>
                <IconButton
                  onClick={() => {
                    displayAddReminder(row.id);
                  }}
                >
                  <InfoOutlined fontSize="10px" />
                </IconButton>
              </Stack>
            </Container>
          );
        })}
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        page={page}
        rowsPerPage={rowsPerPage}
        count={parseInt(totalRows, 10)}
        component="div"
        onPageChange={(e, value) => {
          handlePageChange(e, value);
        }}
        onRowsPerPageChange={(e) => {
          handleRowsPerPageChange(e.target.value);
        }}
      />
    </Paper>
  ) : (
    noReminderComponent
  );
};

export default DisplayReminderData;
