import Cookies from 'js-cookie';      
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react'
import { Box, CardContent, Container, Stack, Card, Button, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Helmet } from 'react-helmet';
import { decryptToken } from '../../components/security/securityFunctions';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import ViewDriveFiles from './ViewDriveFiles';
import UploadMyFiles from './UploadMyFiles';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';
import CompanyTitle from '../../components/titles/CompanyTitle';

const DisplayFiles = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  const [openEditFile, setOpenEditFile] = useState(null);
  const [openAddFolderOrFile, setOpenAddFolderOrFile] = useState(false);
  const [viewType, setViewType] = useState("list");
  const [reminderDate, setReminderDate] = useState("");
  const [documentDate, setDocumentDate] = useState("");
  const [terminationDate, setTerminationDate] = useState("");
  const [searchCategory, setSearchCategory] = useState("all")
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [userSearchString, setUserSearchString] = useState("");
  const [displayFloatCategoryOption, setDisplayCategoryOption] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search)

  let companyId = searchParams.get("company_id")

  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = userToken.company_id;
    }
  }

  // console.log(companyId)
  const [shortCutToSelectFile, setShortCutToSelectFile] = useState(false)
  // console.log(shortCutToSelectFile)

  const handleButtonUploadBack = () => {
    if (openAddFolderOrFile) setOpenEditFile(null);
    setOpenAddFolderOrFile(!openAddFolderOrFile);
    setShortCutToSelectFile(!shortCutToSelectFile)
  };
  const navigate = useNavigate();
  const handleMangeCategory = () => {
    // display manage category in float window
    setDisplayCategoryOption(!displayFloatCategoryOption)
  }

  const redirectToCategoryPage = () => {
    navigate(`/dashboard/displayfiles/fileCategories?company_id=${companyId}`, { state: { allowBack: true } });

  }

  return (
    <>
      <Helmet> <title>My Files | Rejoice Diary</title></Helmet>

      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path="/companies" />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <CompanyTitle />
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>

                <TitleDisplay title="My Files" />
                <div>
                  <Button
                    variant={!openAddFolderOrFile ? "contained" : "outlined"}
                    onClick={handleButtonUploadBack}
                  >
                    {!openAddFolderOrFile ? "Upload" : "Back"}{" "}
                  </Button>
                  <IconButton onClick={handleMangeCategory}> <MoreVertIcon /></IconButton>
                  {displayFloatCategoryOption && <Box sx={{
                    position: 'absolute', // or 'fixed' depending on your requirements
                    top: '60px', // adjust top position as needed
                    right: '20px', // adjust left position as needed
                    boxShadow: 3,
                    margin: '2px',
                    backgroundColor: '#fff', // set the background color as needed
                    cursor: "pointer",
                    zIndex: 9999,
                  }}>
                    <Typography sx={{
                      margin: "2px",
                      padding: "5px"

                    }}
                      onClick={redirectToCategoryPage}
                    > Manage Category</Typography>

                  </Box>}
                </div>
              </Stack>
            </Box>
            <Box sx={{ margin: '30px 0px' }}>
              {
                !openAddFolderOrFile ? (
                  <ViewDriveFiles
                    openAddFolderOrFile={openAddFolderOrFile}
                    setOpenAddFolderOrFile={setOpenAddFolderOrFile}
                    setOpenEditFile={setOpenEditFile}
                    viewType={viewType}
                    setViewType={setViewType}
                    reminderDate={reminderDate}
                    setReminderDate={setReminderDate}
                    terminationDate={terminationDate}
                    setTerminationDate={setTerminationDate}
                    documentDate={documentDate}
                    setDocumentDate={setDocumentDate}
                    showAdvancedFilters={showAdvancedFilters}
                    setShowAdvancedFilters={setShowAdvancedFilters}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    searchCategory={searchCategory}
                    setSearchCategory={setSearchCategory}
                    userSearchString={userSearchString}
                    setUserSearchString={setUserSearchString}
                    handleButtonUploadBack={handleButtonUploadBack}
                    shortCutToSelectFile={shortCutToSelectFile}
                    setShortCutToSelectFile={setShortCutToSelectFile}
                  />


                ) : (
                  <UploadMyFiles
                    openEditFile={openEditFile}
                    shortCutToSelectFile={shortCutToSelectFile}
                  />
                )
              }
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );

}
export default DisplayFiles



