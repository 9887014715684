import React from 'react';
import Cookies from 'js-cookie';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { API_SIGN, DELETE_TRANSATION } from '../../variables/api-variables';
import { decryptToken } from '../../components/security/securityFunctions';
import { apiCall } from '../../components/functions/apiCall';


const DeleteTransation = ({ open, onClose, deleteId, navigateBack }) => {

    const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
    const companyId = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}').company.company_id;
    console.log(deleteId)

    const deleteFile = async () => {
        try {
            const input_data = JSON.stringify({
                function_type: "delete_transaction",
                company_id: Number(companyId),
                transaction_id: deleteId,
                api_signature: API_SIGN,
                jwt_token: userToken,
            });
            console.log(input_data);
            apiCall(input_data, "POST", DELETE_TRANSATION, "plain/text")
                .then((response) => {
                    console.log("Delete", response);
                    if (response.data.status === 1) {
                        enqueueSnackbar("Transation  deleted successfully.", {
                            variant: "success",
                            autoHideDuration: 2000,
                        });
                        onClose(false);
                        navigateBack();
                    } else {
                        enqueueSnackbar(response.data.response, {
                            variant: "error",
                            autoHideDuration: 2000,
                        });
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    enqueueSnackbar("An error occurred during transation deletion.", { variant: "error", autoHideDuration: 2000 });
                });
        } catch (err) {
            console.log(err);
            enqueueSnackbar("An error occurred during transation deletion.", { variant: "error", autoHideDuration: 2000 });
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ color: 'red' }}>Delete Transation</DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you really want to delete ?
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => {
                    onClose(false)
                }}>No</Button>
                <Button
                    onClick={() => {
                        deleteFile();
                        onClose(false);
                    }}
                    autoFocus
                    sx={{ color: 'red' }}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteTransation