import { API_SIGN, SPECIFIC_COMPANY } from '../../variables/api-variables';
import { apiCall } from '../functions/apiCall';

export const fetchCompany = async (company_id, userToken) => {
  const input_data = JSON.stringify({
    company_id,
    function_type: 'fetch_specific_companies',
    api_signature: API_SIGN,
    jwt_token: userToken,
  });
  const response = await apiCall(input_data, 'POST', SPECIFIC_COMPANY, 'plain/text');
  return response;
};
