import {
  Grid,
  TextField,
  Button,
  InputLabel,
  Autocomplete,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
} from '@mui/material';
import { SearchIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { API_SIGN, FETCH_TRANSACTION } from '../../../variables/api-variables';
import palette from '../../../theme/palette';
import { apiCall } from '../../../components/functions/apiCall';
import { SkeletonLoaderSingleForm } from '../../../components/skeleton_loader/skeletonLoader';
import TransactionsDisplay from './TransactionsDisplay';

// need filters in order of mindate, maxdate, narration search, specific ledger, specific category, wallet_filter, transactiontype

const TransactionFilter = ({
  companyId,
  handleSearch,
  categoryOptions,
  userToken,
  throughOptions,
  ledgerOptions,
  fetchLedgers,
}) => {
  const todayDate = new Date();
  const [showLoader, setShowLoader] = useState(false);
  const [response, setResponse] = useState(false);

  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate()
  ).padStart(2, '0')}`;

  console.log(formattedDate);
  const [maxOpeningDate, setMaxOpeningDate] = useState(formattedDate);

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const toggleAdvancedFilters = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  const fetchTransactions = (
    mindate,
    maxdate,
    search_string_narration,
    specific_ledgerid,
    wallet_filter,
    transactiontype,
    category_id,
    view_method,
    limit = 25,
    page = 1
  ) => {
    // setting the loader for it
    setShowLoader(true);
    setResponse(false);
    try {
      // API request for fetch company
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'fetch_transaction',
        company_id: companyId,
        jwt_token: userToken,
        // limit: rowsPerPageForce || rowsPerPage,
        // page: !pageForce ? page + 1 : parseInt(pageForce, 10) + 1,
        mindate,
        maxdate,
        category_id,
        wallet_filter,
        transactiontype,
        search_string_narration,
        view_method,
        specific_ledgerid: specific_ledgerid || '',
        limit,
        page,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_TRANSACTION, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            setResponse(response.data.response);
          } else {
            enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 3000 });
            console.log(response);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //     fetchTransactions();
  // }, [companyId]);

  const changeFilter = (key, value) => {
    formik.setFieldValue(key, value);
    formik.setFieldValue('page', 1);
    formik.setFieldValue('limit', 25);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mindate: `${todayDate.getFullYear()}-${String(todayDate.getMonth() + 1).padStart(2, '0')}-${String(
        todayDate.getDate()
      ).padStart(2, '0')}`,
      maxdate: `${todayDate.getFullYear()}-${String(todayDate.getMonth() + 1).padStart(2, '0')}-${String(
        todayDate.getDate()
      ).padStart(2, '0')}`,
      category: 'all',
      through: 'all',
      transactionType: 'all',
      narration: '',
      ledgername: '',
      viewMode: 'no_stack',
      page: 1,
      limit: 25,
    },
    validationSchema: Yup.object({
      mindate: Yup.date().required('Min Date is required'),
      maxdate: Yup.date().required('Max Date is required'),
      category: Yup.string().required('Category is required'),
      through: Yup.string().required('Through is required'),
      transactionType: Yup.string().required('Transaction type is required'),
      narration: Yup.string(),
      viewMode: Yup.string().required('Through is required'),
    }),
    onSubmit: async (values, helpers) => {
      // console.log(values.ledgername?.id)
      fetchTransactions(
        values.mindate,
        values.maxdate,
        values.narration,
        values.ledgername?.id,
        values.through,
        values.transactionType,
        values.category,
        values.viewMode,
        values.limit,
        values.page
      );
    },
  });

  useEffect(() => {
    formik.submitForm();
  }, [formik.values]);

  const navigate = useNavigate();

  const onClickRow = (id) => {
    if (formik.values.viewMode === 'stacked_category') {
      // set specific category
      formik.setFieldValue('category', id);
      formik.setFieldValue('viewMode', 'stacked_ledger');
    } else if (formik.values.viewMode === 'stacked_ledger') {
      formik.setFieldValue('ledgername', id);
      formik.setFieldValue('viewMode', 'no_stack');
    } else if (formik.values.viewMode === 'no_stack') {
      navigate(`/dashboard/transactions/addTransaction?company_id=${companyId}`, {
        state: { showCancelBtn: true, allowBack: true, transactionId: id },
      });
    }
  };

  const handlePageChange = (e, value) => {
    formik.setFieldValue('page', value + 1);
  };

  const handleRowsPerPageChange = (value) => {
    formik.setFieldValue('limit', value);
    formik.setFieldValue('page', 1);
  };

  return (
    <>
      <Grid
        container
        marginBottom={isMobileScreen ? '10px' : '0px'}
        spacing={isMobileScreen ? 2 : 1}
        justifyContent={'start'}
        alignItems={'top'}
      >
        <Grid item xs={6} sm={4}>
          <TextField
            size={isMobileScreen ? 'small' : 'small'}
            variant="outlined"
            label="From"
            name="mindate"
            type="date"
            error={!!(formik.touched.mindate && formik.errors.mindate)}
            helperText={formik.touched.mindate && formik.errors.mindate}
            onChange={(event) => {
              changeFilter('mindate', event.target.value);
            }}
            onBlur={formik.handleBlur}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: '15px' }}
            value={formik.values.mindate}
            inputProps={{ max: maxOpeningDate }}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            size={isMobileScreen ? 'small' : 'small'}
            variant="outlined"
            label="To"
            name="maxdate"
            type="date"
            error={!!(formik.touched.maxdate && formik.errors.maxdate)}
            helperText={formik.touched.maxdate && formik.errors.maxdate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: '15px' }}
            value={formik.values.maxdate}
            inputProps={{ max: maxOpeningDate }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="role-view-mode">View</InputLabel>
            <Select
              required
              size={isMobileScreen ? 'small' : 'small'}
              labelId="role-view-mode"
              variant="outlined"
              label="View"
              name={`viewMode`}
              error={!!(formik.touched?.viewMode && formik.errors?.viewMode)}
              onChange={(event, value) => formik.setFieldValue(`viewMode`, event.target.value)}
              onBlur={formik.handleBlur}
              inputlabelprops={{
                shrink: true,
              }}
              sx={{ backgroundColor: palette.background.paper }}
              value={formik.values?.viewMode || ''}
            >
              <MenuItem key="viewModeCategorycatergory" value="stacked_category">
                Grouped By Category
              </MenuItem>
              <MenuItem key="viewModeCategorystackedledger" value="stacked_ledger">
                Grouped By Ledgers
              </MenuItem>
              <MenuItem key="viewModeCategoryledger" value="no_stack">
                Transactions
              </MenuItem>
            </Select>
            {formik.touched?.viewMode && formik.errors?.viewMode && (
              <FormHelperText error>{formik.errors?.viewMode}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        marginBottom={isMobileScreen ? '10px' : '0px'}
        spacing={isMobileScreen ? 2 : 1}
        justifyContent={'start'}
        alignItems={'top'}
        style={{ display: showAdvancedFilters ? 'flex' : 'none' }}
      >
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="role-category">Category</InputLabel>
            <Select
              required
              size={isMobileScreen ? 'small' : 'small'}
              labelId="role-category"
              // id="demo-simple-select1"
              variant="outlined"
              label="Category"
              name={'category'}
              error={!!(formik.touched?.category && formik.errors?.category)}
              onChange={(event, value) => formik.setFieldValue(`category`, event.target.value)}
              onBlur={formik.handleBlur}
              inputlabelprops={{
                shrink: true,
              }}
              sx={{ marginBottom: '15px', backgroundColor: palette.background.paper }}
              value={formik.values.category || 'all'}
            >
              <MenuItem key="all_category" value="all">
                All
              </MenuItem>
              {categoryOptions.length > 0
                ? categoryOptions.map((data) => {
                    return (
                      <MenuItem key={`category${data.category_id}`} value={data.category_id}>
                        {data.name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
            {formik.touched?.category && formik.errors?.category && (
              <FormHelperText error>{formik.errors?.category}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="role-through">Through</InputLabel>
            <Select
              size={isMobileScreen ? 'small' : 'small'}
              required
              labelId="role-through"
              id="demo-simple-select1"
              variant="outlined"
              label="Through"
              name={`through`}
              error={!!(formik.touched?.through && formik.errors?.through)}
              onChange={(event, value) => formik.setFieldValue(`through`, event.target.value)}
              onBlur={formik.handleBlur}
              inputlabelprops={{
                shrink: true,
              }}
              sx={{ backgroundColor: palette.background.paper }}
              value={formik.values?.through || ''}
            >
              <MenuItem key="all_through" value="all">
                All
              </MenuItem>
              {throughOptions.length > 0
                ? throughOptions.map((data) => {
                    return (
                      <MenuItem key={data.id} value={data.id}>
                        {data.label}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
            {formik.touched?.through && formik.errors?.through && (
              <FormHelperText error>{formik.errors?.through}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            size={isMobileScreen ? 'small' : 'small'}
            fullWidth
            required
            noOptionsText="Type Something..."
            value={formik.values?.ledgername || ''}
            name={`ledgername`}
            onChange={(event, value) => {
              formik.setFieldValue('ledgername', value);
            }}
            options={ledgerOptions || []}
            getOptionLabel={(option) => (option?.ledgername ? option.ledgername : '')}
            onInputChange={(event) => {
              if (event && event.target.value && event.target.value.length > 2) fetchLedgers(event.target.value);
              // formik.setFieldValue('ledgername', event.target.value);
            }}
            renderInput={(params) => <TextField {...params} label={'Search Ledger'} />}
          />
          {formik.touched?.ledgername && formik.errors?.ledgername && (
            <FormHelperText error>{formik.errors?.ledgername}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        marginBottom={isMobileScreen ? '10px' : '0px'}
        spacing={isMobileScreen ? 2 : 1}
        justifyContent={'start'}
        alignItems={'top'}
        style={{ display: showAdvancedFilters ? 'flex' : 'none' }}
      >
        <Grid item xs={12} sm={8}>
          <TextField
            size={isMobileScreen ? 'small' : 'small'}
            variant="outlined"
            label="Search Remarks"
            name="narration"
            type="text"
            error={!!(formik.touched.narration && formik.errors.narration)}
            helperText={formik.touched.narration && formik.errors.narration}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            sx={{ marginBottom: '15px' }}
            value={formik.values.narration}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="role-transactionType">Transaction Type</InputLabel>
            <Select
              required
              size={isMobileScreen ? 'small' : 'small'}
              labelId="role-transactionType"
              // id="demo-simple-select1"
              variant="outlined"
              label="Transaction Type"
              name={'transactionType'}
              error={!!(formik.touched?.transactionType && formik.errors?.transactionType)}
              onChange={(event, value) => formik.setFieldValue(`transactionType`, event.target.value)}
              onBlur={formik.handleBlur}
              inputlabelprops={{
                shrink: true,
              }}
              sx={{ marginBottom: '15px', backgroundColor: palette.background.paper }}
              value={formik.values.transactionType || 'all'}
            >
              <MenuItem key="all_transactionType" value="all">
                All
              </MenuItem>
              <MenuItem key="transactionTypeReceipt" value="receipt">
                Receipt
              </MenuItem>
              <MenuItem key="transactionTypePayment" value="payment">
                Payment
              </MenuItem>
              {formik.values.viewMode === 'no_stack' ? (
                <MenuItem key="transactionTypeContra" value="contra">
                  Contra
                </MenuItem>
              ) : (
                ''
              )}
              <MenuItem key="transactionTypeSales" value="sales">
                Sales
              </MenuItem>
              <MenuItem key="transactionTypePurchase" value="purchase">
                Purchase
              </MenuItem>
            </Select>
            {formik.touched?.transactionType && formik.errors?.transactionType && (
              <FormHelperText error>{formik.errors?.transactionType}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={isMobileScreen ? 2 : 1} justifyContent={'start'} alignItems={'top'}>
        <Grid item xs={12} style={{ justifyContent: 'right', display: 'flex', alignItems: 'baseline' }}>
          <Button
            size={isMobileScreen ? 'small' : 'small'}
            variant="text"
            style={{ color: palette.grey[500] }}
            onClick={toggleAdvancedFilters}
          >
            {!showAdvancedFilters ? 'More' : 'Hide'} &nbsp;
            {!showAdvancedFilters ? <ExpandMore /> : <ExpandLess />}
          </Button>
        </Grid>
      </Grid>

      {showLoader ? (
        <SkeletonLoaderSingleForm />
      ) : (
        <TransactionsDisplay
          filterValues={formik.values}
          categoryOptions={categoryOptions}
          data={response}
          displayType={formik.values.viewMode}
          onClickRow={onClickRow}
          onClickEdit={null}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          throughOptions={throughOptions}
        />
      )}
    </>
  );
};

export default TransactionFilter;
