import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SvgIcon,
  TextField,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { ExpandLess, MoreHoriz } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { SkeletonLoaderSingleForm } from '../../components/skeleton_loader/skeletonLoader';
import { API_SIGN, FETCH_REMINDER } from '../../variables/api-variables';
import { decryptToken } from '../../components/security/securityFunctions';
import { apiCall } from '../../components/functions/apiCall';
import { getDateYmd } from '../../components/functions/formattingFunctions';
import DisplayReminderData from './DisplayReminderData';
import { ShallowRouting } from '../../components/routing/ShallowRouting';

const DisplayReminders = ({ displayAddReminder }) => {
  const navigate = useNavigate();
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [reminderRowsKey, setReminderRowsKey] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchString, setSearchString] = useState('');
  const [rows, setRowsChange] = useState([]);


  const getMondayOfCurrentWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const monday = new Date(today.setDate(today.getDate() + diff)); // Set the date to Monday of the current week
    return monday;
  };  
  
  const today = new Date();
  const currentWeekMonday = getMondayOfCurrentWeek();

  const handlePageChange = (e, value) => {
    const existingUrlSearchParams =
      urlSearchParams.toString() !== '' && urlSearchParams.toString() !== null
        ? JSON.parse(`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
            return key === '' ? value : decodeURIComponent(value);
          })
        : null;
    setPage(value);
    navigate(ShallowRouting({ ...existingUrlSearchParams, page: value }), { replace: true });
    // fetchCompanies(null, null, value.toString());
  };

  const handleRowsPerPageChange = (value) => {
    if (value !== rowsPerPage && [25, 50, 100].includes(value)) {
      const existingUrlSearchParams =
        (`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => {
          return key === '' ? value : decodeURIComponent(value);
        });
      navigate(ShallowRouting({ ...existingUrlSearchParams, limitRows: value }), { replace: true });
      setRowsPerPage(value);
      setPage(0);
      //   fetchCompanies(null, value, '0');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value.length >= 3) {
      setSearchString(value === '' ? null : value);
    }
    if (value.length === 0) {
      setSearchString(value === '' ? null : value);
    }
  };

  const fetchReminders = (markStatus, mindate, maxdate) => {
    setShowLoader(true);
    try {
      // API request for fetch reminders
      const inputData = JSON.stringify({
        function_type: 'fetch_reminders',
        api_signature: API_SIGN,
        jwt_token: userToken,
        search_string: searchString || formik.values.searchString,
        mark_status: markStatus || formik.values.status,
        mindate: mindate || formik.values.mindate,
        maxdate: maxdate || formik.values.maxdate,
        limit: rowsPerPage,
        page: page + 1,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_REMINDER, 'plain/text')
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            setShowLoader(false);
            const reminderData = response.data.response.data.reminder_data;
            if (reminderData && reminderData.length > 0) {
              setRowsChange([...reminderData]);
              setReminderRowsKey(reminderRowsKey + 1);
              setTotalRows(response.data.response.data.total_rows);
            } else {
              setRowsChange([]);
            }
          } else {
            console.log(response);
            enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 2000 });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchReminders();
  }, [page, rowsPerPage, searchString]);

  const [filterValue, setFitlerValue] = useState({
    status: '0',
    searchString: '',
    mindate: getDateYmd(new Date()),
    maxdate: getDateYmd(new Date()),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: filterValue.status,
      searchString: filterValue.searchString,
      mindate: getDateYmd(currentWeekMonday), // Set Monday of the current week
      maxdate: getDateYmd(today),
    },
  });

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  return (
    <>
      <Grid
        container
        marginBottom={'20px'}
        spacing={isMobileScreen ? 2 : 2}
        justifyContent={'start'}
        alignItems={'top'}
        padding={0}
      >
        <Grid item xs={6} sm={6} md={6}>
          <OutlinedInput
            defaultValue=""
            fullWidth
            size="small"
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            }
            sx={{}}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <FormControl fullWidth>
            <InputLabel id="status-select">Status</InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="status-select"
              id="demo-simple-select1"
              variant="outlined"
              label="Status"
              name="status"
              error={!!(formik.touched.status && Boolean(formik.errors.status))}
              onChange={(e) => {
                formik.setFieldValue('status', e.target.value);
                setPage(0);
                fetchReminders(e.target.value, null, null);
              }}
              onBlur={formik.handleBlur}
              inputlabelprops={{
                shrink: true,
              }}
              sx={{ marginRight: '10px' }}
              value={formik.values.status}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="1">Completed</MenuItem>
              <MenuItem value="0">Pending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Start Date"
            type="date"
            name="mindate"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!(formik.touched.mindate && formik.errors.mindate)}
            helperText={formik.touched.mindate && formik.errors.mindate}
            onChange={(e) => {
              formik.setFieldValue('mindate', e.target.value);
              setPage(0);
              fetchReminders(null, e.target.value, null);
            }}
            onBlur={formik.handleBlur}
            sx={{ marginRight: '10px' }}
            value={formik.values.mindate}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="End Date"
            type="date"
            name="maxdate"
            InputLabelProps={{
              shrink: true,
            }}
            error={!!(formik.touched.maxdate && formik.errors.maxdate)}
            helperText={formik.touched.maxdate && formik.errors.maxdate}
            onChange={(e) => {
              formik.setFieldValue('maxdate', e.target.value);
              setPage(0);
              fetchReminders(null, null, e.target.value);
            }}
            onBlur={formik.handleBlur}
            sx={{ marginRight: '10px' }}
            value={formik.values.maxdate}
          />
        </Grid>
      </Grid>

      {showLoader ? (
        <Container style={{ margin: '0px 0px', padding: '10px 10px' }}>
          <SkeletonLoaderSingleForm />
        </Container>
      ) : (
        <DisplayReminderData
          userToken={userToken}
          keyId={reminderRowsKey}
          reminderData={rows}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          displayAddReminder={displayAddReminder}
        />
      )}
    </>
  );
};

export default DisplayReminders;
