import { Button, ButtonGroup, } from "@mui/material";
import { ViewModule, ViewList } from "@mui/icons-material";
import { Stack } from "@mui/system";


const ToggleMode = ({ viewType, onChange }) => {
  return (
    <Stack direction={"row"} style={{ marginBottom: "10px" }}>
      <ButtonGroup size="small">
        <Button size="small" onClick={() => onChange("list")} disabled={viewType === "list"}>
          <ViewList />
        </Button>
        <Button size="small" onClick={() => onChange("grid")} disabled={viewType === "grid"}>
          <ViewModule />
        </Button>
      </ButtonGroup>
    </Stack>
  )
}

export default ToggleMode