
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../variables/api-variables';

export function apiCall(data, method, slug, content_type, responseType = 'json', onUploadProgressCallback = null) {
    let config = {
        method,
        maxBodyLength: Infinity,
        url: API_URL + slug,
        headers: {
            'Content-Type': content_type,
        },
        responseType,
        data,
        // onUploadProgress: config.onUploadProgress,
        // onUploadProgress: (progressEvent) => {onUploadProgressCallback(progressEvent)}
    };
    if (onUploadProgressCallback) {
        config.onUploadProgress = (progressEvent) => {
            onUploadProgressCallback(progressEvent);
        };
    }
    return new Promise((resolve, reject) => {
        axios.request(config)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export const handleSuccessSubmit = (saveGoBack, saveAddAnother) => {
    // fetch cookie again, fetch key also,
    // depending on the response decide to stay back or leave back
    const cookie = Cookies.get('user_components_rejoice_daybook');
    let savedPreference = 'save_go_back';
    if (cookie) {
        savedPreference = JSON.parse(Cookies.get('user_components_rejoice_daybook'))?.save_btn_preference;
    }
    if (savedPreference === 'save_go_back') {
        saveGoBack();
    } else {
        saveAddAnother();
    }
};