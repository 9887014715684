import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Stack,
  TextField,
  Box,
  MenuItem,
  Button,
  Container,
  Alert,
  AlertTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
// components
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { apiCall } from '../../../components/functions/apiCall';
import { API_SIGN, REGISTER } from '../../../variables/api-variables';
import palette from '../../../theme/palette';

// ----------------------------------------------------------------------

// function getMaxDate() {
//   return new Date();
// }

const RegisterForm = ({ moveFurther }) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [focus, setFocused] = useState(false);
  const onDobFocus = () => setFocused(true);
  const onDobBlur = () => setFocused(false);
  const [mobile, setMobile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const mobileChange = (mobile) => {
    setMobile(mobile);
    formik.values.mobile = mobile.replace(/\s/g, '').replace(/\+/g, '');
  };
  const [gender, setGender] = useState('');

  const genderChange = (gender) => {
    setGender(gender.value);
    formik.values.gender = gender.value;
  };

  const steps = ['Personal Information', 'Workspace Information'];
  const [activeStep, setActiveStep] = useState(0);
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      age: '',
      // dob: '',
      gender: '',
      mobile: '',
      workspace_name: '',
      workspace_address: '',
      designation: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        // .test('checkUsername', 'Username not available', 
        // async () => {
        //   const inputData = JSON.stringify({
        //     api_signature: API_SIGN,
        //     username: formik.values.email,
        //     // function_type: 'check_username_availability',
        //   });
        //   // console.log(inputData)
        //   return apiCall(inputData, 'POST', CHK_USER, 'plain/text')
        //     .then((response) => {
        //       console.log(response);
        //       if (response.data.status === 0) {
        //         return false;
        //       }
        //       return true;
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //       setErrorMessage(error.message);
        //     });
        // })
        .required('Email is required'),
      name: Yup.string().max(255).required('Name is required'),
      gender: Yup.string().required('Gender is required'),
      // dob: Yup.date()
      //   .required('DOB is required.')
      //   .max(getMaxDate(), 'Future dates not allowed.')
      //   .typeError('Please enter a valid date'),
      mobile: Yup.string()
        .max(15, "Contact Number can't be more than 15 characters.")
        .min(12, 'Contact Number must be of atleast 12 characters.')
        .required('Mobile number is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        if (activeStep === 1) {
          setIsLoading(true); // set the loading state to true
          // Submit the form data here
          // api request for registering a new account
          const inputData = JSON.stringify({
            name: values.name,
            // dob: values.dob,
            gender: values.gender,
            email: values.email,
            mobile: values.mobile,
            workspace_name: null,
            workspace_address: null,
            api_signature: API_SIGN,
            designation: null,
          });
          console.log(inputData);
          setIsLoading(true); // set the loading state to true before making the API call
          apiCall(inputData, 'POST', REGISTER, 'plain/text')
            .then((response) => {
              console.log(response);
              if (response.data.status === 1) {
                console.log('in success');
                Cookies.remove('user_data_rejoice_daybook');
                navigate('/registerationSuccessful', { state: { time: Math.floor(Date.now() / 1000) } });
              } else {
                setErrorMessage(response.data.response);
              }
            })
            .catch((error) => {
              // console.log(error);
              setErrorMessage(error.message);
            })
            .finally(() => {
              setIsLoading(false); // set the loading state to false after the API call resolves
            });
        } else {
          setActiveStep(activeStep + 1);
          setIsLoading(false); // set the loading state to false if the active step is not 1
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false); // set the loading state to false if an error occurs
      }
    },
  });

  return (
    <>
      <Box>
        <div>
          <form noValidate onSubmit={formik.handleSubmit}>
            {
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email.toLowerCase()}

                />
                <MuiTelInput
                  error={!!(formik.touched.mobile && formik.errors.mobile)}
                  fullWidth
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  label="Mobile"
                  name="mobile"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    mobileChange(e);
                  }}
                  value={mobile}
                  type="mobile"
                  defaultCountry="IN"
                />
                {/* <TextField
                  error={!!(formik.touched.dob && formik.errors.dob)}
                  fullWidth
                  helperText={formik.touched.dob && formik.errors.dob}
                  name="dob"
                  onFocus={onDobFocus}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    onDobBlur(e);
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (e.target.value) setHasValue(true);
                    else setHasValue(false);
                  }}
                  InputLabelProps={{ shrink: true }}
                  type={'date'}
                  value={formik.values.dob}
                  label="D.O.B."
                /> */}
                <TextField
                  id="select"
                  error={!!(formik.touched.gender && formik.errors.gender)}
                  fullWidth
                  helperText={formik.touched.gender && formik.errors.gender}
                  name="gender"
                  label="Gender"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    genderChange(e.target);
                  }}
                  value={gender}
                  select
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="M" selected={gender === 'M'}>
                    Male
                  </MenuItem>
                  <MenuItem value="F" selected={gender === 'F'}>
                    Female
                  </MenuItem>
                  <MenuItem value="O" selected={gender === 'O'}>
                    Other
                  </MenuItem>
                </TextField>
              </Stack>
            }
            {formik.errors.submit && (
              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                {formik.errors.submit}
              </Typography>
            )}
            <Box sx={{ mt: 3 }}>
              <Button variant="contained" type="submit" sx={{ ml: 1, bgcolor: palette.primary.main }} disabled={isLoading}>
                {!isLoading ? 'Submit' : <CircularProgress color={'info'} size={20} />}
              </Button>
              {errorMessage ? (
                <Alert severity="error" sx={{ mt: 3 }} variant="standard">
                  <AlertTitle>{errorMessage}</AlertTitle>
                </Alert>
              ) : (
                <Container />
              )}
            </Box>
          </form>
        </div>
      </Box>
    </>
  );
};
export default RegisterForm;