import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardContent, Container, Stack } from '@mui/material';
import Cookies from 'js-cookie';
import AddIcon from '@mui/icons-material/Add';
import TitleDisplay from '../../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../../components/breadCrumb/BreadCrumbs';
import { decryptToken } from '../../../components/security/securityFunctions';
import AddPurchaseBill from './AddPurchaseBill';

const ManagePurchaseBill = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let companyId = searchParams.get('company_id');

  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = userToken.company_id;
    }
  }

  const navigate = useNavigate();

  const navigateToAddBill = (showCancel = true) => {
    navigate(`/dashboard/transactions/addPurchaseBill?company_id=${companyId}`, {
      state: { showCancelBtn: showCancel, allowBack: true },
    });
  };

  return (
    <>
      <Helmet>
        {' '}
        <title>Purchase Bill | Rejoice Diary</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path={'billing'} />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title="Purchase Bill" />
                {/* {userToken.role !== 'other' ? (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      navigateToAddBill();
                    }}
                  >
                    <AddIcon />
                    &nbsp; Add
                  </Button>
                ) : null} */}
              </Stack>
            </Box>
            <Box sx={{ margin: '30px 0px' }}>
              <AddPurchaseBill />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ManagePurchaseBill;