import { Alert, Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { apiCall } from '../../../components/functions/apiCall';
import { API_SIGN, FORGOT_PASSWORD } from '../../../variables/api-variables';

const ForgetPass = ({ handleNext }) => {
  const [message, setMessage] = useState();
  const [loader, setLoader] = useState(false);
  //   console.log(`message= ${message}`);

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    onSubmit: async (values, helper) => {
      try {
        setLoader(true);
        const inputData = JSON.stringify({
          function_type: 'forgot_password',
          username: values.username,
          api_signature: API_SIGN,
        });
        // console.log(inputData);
        apiCall(inputData, 'POST', FORGOT_PASSWORD, 'plain/text')
          .then((response) => {
            // console.log(response);
            if (response.data.status === 1) {
              setMessage(response.data.response.success_msg);
              enqueueSnackbar('Kindly check your registered email id for the OTP.', {
                variant: 'success',
                autoHideDuration: 2000,
              });
              handleNext(response.data.response.username, response.data.response.user_token);
            } else {
              setMessage(response.data.response);
              enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 2000 });
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            enqueueSnackbar('Invalid Username!! ', { variant: 'error', autoHideDuration: 2000 });
          });
      } catch {
        enqueueSnackbar('Some error has occured.', { variant: 'error', autoHideDuration: 2000 });
        setLoader(false);
      }
    },
  });
  return (
    <div>
      <Typography sx={{ mb: 2 }}>Enter your Username</Typography>

      <form noValidate onSubmit={formik.handleSubmit}>
        <TextField
          error={!!(formik.touched.username && formik.errors.username)}
          fullWidth
          helperText={formik.touched.username && formik.errors.username}
          label="Username"
          name="username"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="text"
          value={formik.values.username.toLowerCase()}
        />
        {loader ? (
          <LoadingButton />
        ) : message ? (
          <Alert sx={{ margin: '20px 0px' }} severity="error">
            {message}
          </Alert>
        ) : (
          ''
        )}

        <Button fullWidth size="large" sx={{ marginTop: '20px' }} variant="contained" type="submit">
          Proceed
        </Button>
      </form>
    </div>
  );
};

export default ForgetPass;
