// import NavLink from 'react-router';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { redirect, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button, Link, useMediaQuery } from '@mui/material';
import Cookies from 'js-cookie';
import Footer from '../../../components/Footer/Footer';
import palette from '../../../theme/palette';
import InitialLoading from '../../initial_loader/InitialLoading';
// hookssrc
import useResponsive from '../../../hooks/useResponsive';

// components
import Logo from '../../../components/logo';
import RegisterForm from './RegisterForms';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('475')]: {
    justifyContent: 'start',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '90vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down('475')]: {
    minHeight: '50vh', // Change minHeight for smaller screens
  },
}));

// ----------------------------------------------------------------------

const Register = () => {
  const mdUp = useResponsive('up', 'md');
  const isMobileScreen = useMediaQuery('(max-width : 900px)');

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate('/login');
  };

  // verifying the login here
  const [moveFurther, setMoveFurther] = useState(false);

  const navigateToDashboard = () => {
    if (Cookies.get('user_data_rejoice_daybook') === undefined) {
      setMoveFurther(true);
      // redirect ahead
    } else {
      // redirect to dashboard
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    navigateToDashboard();
  });

  return (
    <>
      <Helmet>
        <title> Register | Rejoice Diary </title>
      </Helmet>
      {moveFurther === false ? (
        <InitialLoading />
      ) : (
        <>
          <StyledRoot>
            {isMobileScreen ? <Logo
              sx={{
                position: 'relative',
                marginTop: { xs: '10px', sm: '10px', md: '10px' },
                marginLeft: { xs: '16px', sm: '24px', md: '40px' },
                top: { sm: '40px' }
              }}
            /> : null}

            {mdUp && (
              <StyledSection style={{ paddingTop: '0px' }}>
                <Logo
                  sx={{
                    position: 'absolute',
                    marginTop: { xs: '10px', sm: '20px', md: '20px' },
                    marginLeft: { xs: '16px', sm: '24px', md: '40px' },
                    marginBottom: "40px",
                    top: '20px'
                  }}
                />
                {/* <Typography variant="h4" sx={{ px: 5, mt: 5, mb: 0, color: palette.primary.main, fontWeight: '500' }}>
                  Welcome to
                </Typography>
                <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5, color: palette.primary.main }}>
                  Rejoice Diary
                </Typography> */}
                <img src="/assets/illustrations/illustration_login.svg" alt="register" />
              </StyledSection>
            )}
            {isMobileScreen ? (
              <Container >
                <StyledContent style={{ paddingTop: '20px' }}>
                  <Typography variant="h4" gutterBottom>
                    Register
                  </Typography>

                  <Typography variant="body2" sx={{ mb: 5 }}>
                    Already have an account?
                    <Button
                      onClick={() => {
                        navigateToLogin();
                      }}
                      variant="text"
                      sx={{ padding: '0px 2px', color: palette.primary.main }}
                    >
                      Login
                    </Button>
                  </Typography>

                  <RegisterForm />
                </StyledContent>
                <Footer />
              </Container>
            ) : (
              <Container maxWidth="sm">
                <StyledContent style={{ paddingTop: '20px' }}>
                  <Typography variant="h4" gutterBottom>
                    Register
                  </Typography>

                  <Typography variant="body2" sx={{ mb: 5 }}>
                    Already have an account?
                    <Button
                      onClick={() => {
                        navigateToLogin();
                      }}
                      variant="text"
                      sx={{ padding: '0px 2px', color: palette.primary.main }}
                    >
                      Login
                    </Button>
                  </Typography>

                  <RegisterForm moveFurther={moveFurther} />
                </StyledContent>
                <Footer show={moveFurther} />
              </Container>
            )}
          </StyledRoot>
        </>
      )}
    </>
  );
};
export default Register;
