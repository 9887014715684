import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  Container,
  useMediaQuery,
  Grid,
} from '@mui/material';
import { useEffect } from 'react';
import palette from '../../../../../theme/palette';
import { indianFormatNumber } from '../../../../../components/functions/data_functions';

const LedgerView = ({ data, onClickRow, onClickEdit, showOpening }) => {
  // console.log(data);
  const isMobileScreen = useMediaQuery('(max-width:475px)');
  return isMobileScreen ? (
    data?.transaction_info && data?.transaction_info.length > 0 ? (
      data.transaction_info.map((element, index) => {
        return (
          <Grid
            container
            key={`daybook_rows${index}`}
            spacing={1}
            margin={'0px'}
            padding={'10px 0px'}
            style={{ background: index % 2 === 0 ? 'transparent' : '#F8F8F8' }}
            onClick={() => {
              onClickRow(element.transaction_id);
            }}
          >
            <Grid item xs={7}>
              <Typography variant="caption" color={palette.grey[600]}>
                {element.date_transaction}
              </Typography>
              <Typography
                color={
                  element.transactiontype === 'receipt'
                    ? palette.success.main
                    : element.transactiontype === 'payment'
                    ? palette.error.main
                    : palette.orange.main
                }
                variant="body1"
                fontWeight={'700'}
              >
                {element.wallet_label}
              </Typography>
              <Typography color={palette.grey[500]} variant="body2" fontSize={'9pt'} fontStyle={'italic'}>
                {element.remarks}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <br />
              <Typography
                align="right"
                fontWeight={500}
                color={
                  element.transactiontype === 'receipt'
                    ? palette.success.main
                    : element.transactiontype === 'payment'
                    ? palette.error.main
                    : palette.orange.main
                }
              >
                {element.transactiontype === 'contra'
                  ? `(+/-) ${indianFormatNumber(element.amount)}`
                  : indianFormatNumber(element.amount)}
              </Typography>
            </Grid>
          </Grid>
        );
      })
    ) : null
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Particulars</TableCell>
          <TableCell align={'right'}>Inward</TableCell>
          <TableCell align={'right'}>Outward</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.transaction_info && data?.transaction_info.length > 0
          ? data.transaction_info.map((element) => {
              return (
                <TableRow
                  key={element.transaction_id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onClickRow(element.transaction_id);
                  }}
                >
                  <TableCell>{element.date_transaction}</TableCell>
                  <TableCell>
                    <Typography
                      color={
                        element.transactiontype === 'receipt'
                          ? palette.success.main
                          : element.transactiontype === 'payment'
                          ? palette.error.main
                          : element.transactiontype === 'sales'
                          ? palette.primary.main
                          : element.transactiontype === 'purchase'
                          ? palette.purple.main
                          : palette.orange.main
                      }
                      variant="body1"
                      fontWeight={'700'}
                    >
                      {element.wallet_label}
                    </Typography>
                    {/* <Typography color={palette.grey[600]} variant='body2' fontSize={'11pt'}>
                                {element.transactiontype === 'contra' ?
                                    'Contra' : `Through : ${element.wallet_label}`}
                            </Typography> */}
                    <Typography color={palette.grey[500]} variant="body2" fontSize={'9pt'} fontStyle={'italic'}>
                      {element.remarks}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align={'right'}
                    style={{
                      background:
                        element.transactiontype === 'receipt' || element.transactiontype === 'contra'
                          ? palette.success.lighter
                          : element.transactiontype === 'sales'
                          ? palette.info.lighter
                          : 'transparent',
                    }}
                  >
                    {element.transactiontype === 'receipt' ||
                    element.transactiontype === 'sales' ||
                    element.transactiontype === 'contra'
                      ? indianFormatNumber(element.amount)
                      : ''}
                  </TableCell>
                  <TableCell
                    align={'right'}
                    style={{
                      background:
                        element.transactiontype === 'payment' || element.transactiontype === 'contra'
                          ? palette.error.lighter
                          : element.transactiontype === 'purchase'
                          ? palette.purple.lighter
                          : 'transparent',
                    }}
                  >
                    {element.transactiontype === 'payment' ||
                    element.transactiontype === 'purchase' ||
                    element.transactiontype === 'contra'
                      ? indianFormatNumber(element.amount)
                      : ''}
                  </TableCell>
                </TableRow>
              );
            })
          : null}
      </TableBody>
    </Table>
  );
};

export default LedgerView;
