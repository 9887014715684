import { useEffect, useState } from 'react';
import { useMediaQuery, Typography, Grid } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { PieChart } from '@mui/x-charts';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';
import { API_SIGN, FETCH_DASHBOARD_CHARTS } from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import { SkeletonLoaderSingleForm } from '../../components/skeleton_loader/skeletonLoader';
import palette from '../../theme/palette';

const DashboardChart = ({ userToken, selectedMinDate, selectedMaxDate, selectedCompanyId }) => {
  const [showLoaderDashboard, setShowLoaderDashboard] = useState(false);

  const [receiptData, setReceiptData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  console.log(paymentData, receiptData);

  const isMobileScreen = useMediaQuery('(max-width:475px)');
  const combineData = (receipts, payments) => {
    return receipts.map((receipt) => {
      const payment = payments.find((p) => p.label === receipt.label);
      return {
        label: receipt.label,
        receiptValue: receipt.value,
        paymentValue: payment ? payment.value : 0,
      };
    });
  };

  const combinedData = combineData(receiptData, paymentData);
  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, display: 'flex', flexWrap: 'wrap' }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ marginBottom: 10, marginRight: 20, display: 'flex', alignItems: 'center' }}
          >
            {/* Color box */}
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: colors[index % colors.length], // Assigning color based on the index
                marginRight: 8,
              }}
            />
            {/* Text information */}
            <span style={{ color: colors[index % colors.length] }}>
              {entry.payload.label} Receipt: {entry.payload.receiptValue}, Payment: {entry.payload.paymentValue}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  // Colors for the bars (you can add more for additional labels)
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

  // Custom legend to display label, receipt, and payment information with colored boxes

  const fetchDashboardCharts = () => {
    setShowLoaderDashboard(true);
    try {
      // API request for fetch company
      const inputData = JSON.stringify({
        api_signature: API_SIGN,
        function_type: 'dashboard_chart',
        jwt_token: userToken.userToken,
        company_id: selectedCompanyId,
        min_date_selected: selectedMinDate,
        max_date_selected: selectedMaxDate,
      });
      // console.log(inputData)
      apiCall(inputData, 'POST', FETCH_DASHBOARD_CHARTS, 'plain/text')
        .then((response) => {
          console.log(response);
          setShowLoaderDashboard(false);
          if (response.data.status === 1) {
            let resp = response.data.response;
            setReceiptData(resp.receipts_info);
            setPaymentData(resp.payments_info);
          } else {
            enqueueSnackbar(response.data.response, { variant: 'error', autoHideDuration: 15000 });
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDashboardCharts();
  }, [selectedCompanyId, selectedMinDate, selectedMaxDate]);
  const style = {
    top: 200,
    left: 600,
    lineHeight: '24px',
  };

  const colorSchemePayments = ['#FA8072', '#CD5C5C', '#FF6347', '#FFA500', '#B38B00', '#fffa00', '#ff9400', '#b36800'];
  const colorSchemeReceipts = ['#02b2af', '#017d7d', '#98FF98', '#2E8B57', '#32CD32', '#87CEEB', '#1E90FF'];
  return showLoaderDashboard ? (
    <SkeletonLoaderSingleForm />
  ) : (
    <Grid container spacing={1} margin={'20px 0px'}>
      {receiptData.length > 0 ? (
        <Grid item xs={12} sm={6} borderRight={'solid thin #f7f7f7'}>
          <Typography variant="body1" align="center" fontWeight={'600'} fontSize={'18px'} color={palette.primary.main}>
            Receipts & Sales
          </Typography>
          <div style={{ width: '100%', height: 300 }}>
            <PieChart
              colors={colorSchemeReceipts}
              slotProps={{
                legend: { direction: 'row', position: { vertical: 'bottom', horizontal: 'middle' } },
              }}
              series={[
                {
                  data: receiptData,
                  innerRadius: isMobileScreen ? 75 : 60,
                  outerRadius: 100,
                  // paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -90,
                  endAngle: 360,
                },
              ]}
            />
          </div>
        </Grid>
      ) : null}
      {paymentData.length > 0 ? (
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" align="center" fontWeight={'600'} fontSize={'18px'} color={'#FFA500'}>
            Payments & Purchase
          </Typography>
          <div style={{ width: '100%', height: 300 }}>
            <PieChart
              colors={colorSchemePayments}
              series={[
                {
                  data: paymentData,
                  innerRadius: isMobileScreen ? 75 : 60,
                  outerRadius: 100,
                  // paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -90,
                  endAngle: 360,
                },
              ]}
              slotProps={{
                legend: { direction: 'row', position: { vertical: 'bottom', horizontal: 'middle' } },
              }}
            />
          </div>
        </Grid>
      ) : null}

      {/* {combinedData.length > 0 ? (
        <RadialBarChart
          width={900}
          height={600}
          innerRadius="20%"
          outerRadius="100%"
          data={combinedData}
          startAngle={360}
          barSize={30}
          endAngle={0}
        >
          {combinedData.map((entry, index) => (
            <RadialBar
              key={index}
              minAngle={15}
              label={{ position: 'insideStart', fill: '#fff' }}
              background
              clockWise
              barSize={50}
              dataKey="receiptValue"
              data={[entry]}
              fill={colors[index % colors.length]} // Unique color for each bar
            />
          ))}

          {combinedData.map((entry, index) => (
            <RadialBar
              key={`payment-${index}`}
              minAngle={15}
              background
              clockWise
              barSize={60}
              dataKey="paymentValue"
              data={[entry]}
              fill={colors[(index + 1) % colors.length]} // Unique color for each payment bar
            />
          ))}

          <Legend
            iconSize={20}
            width={300}
            height={50}
            layout="vertical"
            content={renderCustomLegend}
            wrapperStyle={style}
          />
        </RadialBarChart>
      ) : null} */}
    </Grid>
  );
};

export default DashboardChart;