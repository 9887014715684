import { Helmet } from 'react-helmet';
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Box,
  Stack,
  useMediaQuery,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { decryptToken } from '../../components/security/securityFunctions';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';
import DashboardStats from './DashboardStats';
import CompanyTitle from '../../components/titles/CompanyTitle';

const DashboardSpecificCompany = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // workflow
  // if already existing as inddividual

  let companyId = searchParams.get('company_id');

  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = userToken.company_id;
    }
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Rejoice Diary</title>
      </Helmet>

      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path={'companies/dashboard'} />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent>
            <CompanyTitle />

            <Stack direction={'row'} justifyContent={'space-between'}>
              <TitleDisplay title={'Dashboard'} />
            </Stack>
            <Box sx={{ margin: '20px 0px', borderBottom: 'solid thin #f7f7f7' }}>
              <DashboardStats userToken={userToken} defaultCompany={companyId} />
            </Box>
            {/* <Box sx={{ margin: '20px 0px 10px 5px' }}>
                            <Typography variant="h4" fontWeight="600" gutterBottom>Reminders</Typography>
                            {(companyId && companyId !== 'all' && companyId !== '') ? <DisplayReminders /> : null}
                        </Box> */}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default DashboardSpecificCompany;
