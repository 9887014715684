import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LazyLoaderSuspense from "./components/LazyLoader";
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/login/signin/LoginPage';
import Page404 from './pages/error_404/Page404';
import Register from './pages/login/register/Register';
import RegistrationSuccess from './pages/login/register/RegistrationSuccess';
import DashboardHome from './pages/dashboard_home/DashboardHome';
import ManageCompanies from './pages/companies/ManageCompanies';
import AddCompany from './pages/companies/AddCompany';
import ForgotPasswordPage from './pages/login/forgetpassword/ForgotPasswordPage'
import ManageTransactions from './pages/transactions/ManageTransactions';
import AddTransaction from './pages/transactions/AddTransaction';
import DisplayFiles from './pages/my_files/DisplayFiles';
import ManageReminders from './pages/reminders/ManageReminders';
import DashboardSpecificCompany from './pages/dashboard_home/DashboardSpecificCompany';
import ManageBill from './pages/transactions/billing/ManageBill';
import ManageSettings from './pages/settings/ManageSettings';
import ManagePurchaseBill from './pages/transactions/purchase_bill/ManagePurchaseBill';
import ManageItems from './pages/transactions/items/ManageItems';

// const LazyDashboardLayout = lazy(() => import("./layouts/dashboard"));

// import CreateUser from './pages/users/AddUser';
const CreateUser = lazy(() => import("./pages/users/AddUser"));
// import ManageUser from './pages/users/ManageUser';
const ManageUser = lazy(() => import("./pages/users/ManageUser"));
// import UserProfile from './pages/users/UserProfile';
const UserProfile = lazy(() => import('./pages/users/UserProfile'));
// import ManageCategories from './pages/transactions/categories/ManageCategories';
const ManageCategories = lazy(() => import('./pages/transactions/categories/ManageCategories'));
// import AddCategory from './pages/transactions/categories/AddCategory';
const AddCategory = lazy(() => import('./pages/transactions/categories/AddCategory'));
// import ManageWallet from './pages/transactions/wallet/ManageWallet';
const ManageWallet = lazy(() => import('./pages/transactions/wallet/ManageWallet'));
// import AddWallet from './pages/transactions/wallet/AddWallet';
const AddWallet = lazy(() => import('./pages/transactions/wallet/AddWallet'));
// import ManageCategoriesInFiles from './pages/my_files/fileCategories/ManageCategoriesInFiles';
const ManageCategoriesInFiles = lazy(() => import('./pages/my_files/fileCategories/ManageCategoriesInFiles'));
// import AddCategoryInFiles from './pages/my_files/fileCategories/AddCategoryInFiles';
const AddCategoryInFiles = lazy(() => import('./pages/my_files/fileCategories/AddCategoryInFiles'));
// import ManageLedger from './pages/transactions/ledgers/ManageLedger';
const ManageLedger = lazy(() => import('./pages/transactions/ledgers/ManageLedger'));
// import AddLedger from './pages/transactions/ledgers/AddLedger';
const AddLedger = lazy(() => import('./pages/transactions/ledgers/AddLedger'));
// import AddBill from './pages/transactions/billing/AddBill';
const AddBill = lazy(() => import('./pages/transactions/billing/AddBill'));
// import AddPurchaseBill from './pages/transactions/purchase_bill/AddPurchaseBill';
const AddPurchaseBill = lazy(() => import('./pages/transactions/purchase_bill/AddPurchaseBill'));
// import AlterItems from './pages/transactions/items/AlterItems';
const AlterItems = lazy(() => import('./pages/transactions/items/AlterItems'));

// ----------------------------------------------------------------------

export default function Router() {
  // const workflow_type = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}')?.workflow_type;
  // console.log(workflow_type)
  // const individualRoutes = [
  //   {
  //     path: '/login',
  //     element: <LoginPage />,
  //   },
  //   {
  //     path: '/register',
  //     element: <Register />,
  //   }, {
  //     path: '/registerationSuccessful',
  //     element: <Suspense fallback={<LazyLoaderSuspense />}> <RegistrationSuccess /></Suspense>,
  //   },
  //   {
  //     path: "forgotpassword",
  //     element: <Suspense fallback={<LazyLoaderSuspense />}><ForgotPasswordPage /></Suspense>
  //   },

  //   {
  //     path: '/dashboard',
  //     // element: <Suspense fallback={<LazyLoaderSuspense />}><LazyDashboardLayout /></Suspense>,
  //     element: <DashboardLayout />,
  //     children: [
  //       { path: "companyDashboard", element: <DashboardSpecificCompany /> },
  //       { path: 'transactions', element: <ManageTransactions /> },
  //       { path: 'transactions/addTransaction', element: <AddTransaction /> },
  //       { path: 'transactions/categories', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageCategories /></Suspense> },
  //       { path: 'transactions/addCategory', element: <Suspense fallback={<LazyLoaderSuspense />}><AddCategory /></Suspense> },
  //       { path: "transactions/wallet", element: <Suspense fallback={<LazyLoaderSuspense />}><ManageWallet /></Suspense> },
  //       { path: "transactions/addUpdateWallet", element: <Suspense fallback={<LazyLoaderSuspense />}><AddWallet /></Suspense> },
  //       { path: 'transactions/ledgers', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageLedger /></Suspense> },
  //       { path: 'transactions/addLedger', element: <Suspense fallback={<LazyLoaderSuspense />}><AddLedger /></Suspense> },
  //       { path: "displayfiles", element: <DisplayFiles /> },
  //       { path: 'displayfiles/fileCategories', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageCategoriesInFiles /></Suspense> },
  //       { path: 'displayfiles/addcategories', element: <Suspense fallback={<LazyLoaderSuspense />}><AddCategoryInFiles /></Suspense> },
  //       { path: 'reminders', element: <ManageReminders /> },
  //     ],
  //   },
  //   {
  //     element: <SimpleLayout />,
  //     children: [
  //       { element: <Navigate to="/dashboard/home" />, index: true },
  //       { path: '404', element: <Page404 /> },
  //       { path: '*', element: <Navigate to="/404" /> },
  //     ],
  //   },
  //   {
  //     path: '*',
  //     element: <Navigate to="/404" replace />,
  //   },
  // ]
  const enterpriseRoute = [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/register',
      element: <Register />,
    }, {
      path: '/registerationSuccessful',
      element: <Suspense fallback={<LazyLoaderSuspense />}> <RegistrationSuccess /></Suspense>,
    },
    {
      path: "forgotpassword",
      element: <Suspense fallback={<LazyLoaderSuspense />}><ForgotPasswordPage /></Suspense>
    },

    {
      path: '/dashboard',
      // element: <Suspense fallback={<LazyLoaderSuspense />}><LazyDashboardLayout /></Suspense>,
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: 'home', element: <DashboardHome /> },
        { path: 'companies', element: <ManageCompanies /> },
        { path: 'companies/addCompany', element: <AddCompany /> },
        { path: "users", element: <Suspense fallback={<LazyLoaderSuspense />}><ManageUser /></Suspense> },
        { path: "users/addUser", element: <Suspense fallback={<LazyLoaderSuspense />}><CreateUser /></Suspense> },
        { path: "users/viewUser", element: <Suspense fallback={<LazyLoaderSuspense />}><UserProfile /></Suspense> },
        { path: "companyDashboard", element: <DashboardSpecificCompany /> },
        { path: 'transactions', element: <ManageTransactions /> },
        { path: 'transactions/addTransaction', element: <AddTransaction /> },
        { path: 'transactions/categories', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageCategories /></Suspense> },
        { path: 'transactions/addCategory', element: <Suspense fallback={<LazyLoaderSuspense />}><AddCategory /></Suspense> },
        { path: "transactions/wallet", element: <Suspense fallback={<LazyLoaderSuspense />}><ManageWallet /></Suspense> },
        { path: "transactions/addUpdateWallet", element: <Suspense fallback={<LazyLoaderSuspense />}><AddWallet /></Suspense> },
        { path: 'transactions/ledgers', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageLedger /></Suspense> },
        { path: 'transactions/addLedger', element: <Suspense fallback={<LazyLoaderSuspense />}><AddLedger /></Suspense> },
        { path: 'transactions/items', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageItems /></Suspense> },
        { path: 'transactions/addItems', element: <Suspense fallback={<LazyLoaderSuspense />}><AlterItems /></Suspense> },
        { path: 'transactions/billing', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageBill /></Suspense> },
        { path: 'transactions/addBill', element: <Suspense fallback={<LazyLoaderSuspense />}><AddBill /></Suspense> },
        { path: 'transactions/purchase_bill', element: <Suspense fallback={<LazyLoaderSuspense />}><ManagePurchaseBill /></Suspense> },
        { path: 'transactions/addPurchaseBill', element: <Suspense fallback={<LazyLoaderSuspense />}><AddPurchaseBill /></Suspense> },
        { path: "displayfiles", element: <DisplayFiles /> },
        { path: 'displayfiles/fileCategories', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageCategoriesInFiles /></Suspense> },
        { path: 'displayfiles/addcategories', element: <Suspense fallback={<LazyLoaderSuspense />}><AddCategoryInFiles /></Suspense> },
        { path: 'reminders', element: <ManageReminders /> },
        { path: 'settings', element: <ManageSettings /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]

  // const routes = workflow_type === "enterprise" ? enterpriseRoute : individualRoutes
  const routes = enterpriseRoute

  // const routes = useRoutes(
  //   [
  //     {
  //       path: '/login',
  //       element: <LoginPage />,
  //     },
  //     {
  //       path: '/register',
  //       element: <Register />,
  //     }, {
  //       path: '/registerationSuccessful',
  //       element: <Suspense fallback={<LazyLoaderSuspense />}> <RegistrationSuccess /></Suspense>,
  //     },
  //     {
  //       path: "forgotpassword",
  //       element: <Suspense fallback={<LazyLoaderSuspense />}><ForgotPasswordPage /></Suspense>
  //     },

  //     {
  //       path: '/dashboard',
  //       // element: <Suspense fallback={<LazyLoaderSuspense />}><LazyDashboardLayout /></Suspense>,
  //       element: <DashboardLayout />,
  //       children: [
  //         { element: <Navigate to="/dashboard/home" />, index: true },
  //         { path: 'home', element: <DashboardHome /> },
  //         { path: 'companies', element: <ManageCompanies /> },
  //         { path: 'companies/addCompany', element: <AddCompany /> },
  //         { path: "users", element: <Suspense fallback={<LazyLoaderSuspense />}><ManageUser /></Suspense> },
  //         { path: "users/addUser", element: <Suspense fallback={<LazyLoaderSuspense />}><CreateUser /></Suspense> },
  //         { path: "users/viewUser", element: <Suspense fallback={<LazyLoaderSuspense />}><UserProfile /></Suspense> },
  //         { path: "companyDashboard", element: <DashboardSpecificCompany /> },
  //         { path: 'transactions', element: <ManageTransactions /> },
  //         { path: 'transactions/addTransaction', element: <AddTransaction /> },
  //         { path: 'transactions/categories', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageCategories /></Suspense> },
  //         { path: 'transactions/addCategory', element: <Suspense fallback={<LazyLoaderSuspense />}><AddCategory /></Suspense> },
  //         { path: "transactions/wallet", element: <Suspense fallback={<LazyLoaderSuspense />}><ManageWallet /></Suspense> },
  //         { path: "transactions/addUpdateWallet", element: <Suspense fallback={<LazyLoaderSuspense />}><AddWallet /></Suspense> },
  //         { path: 'transactions/ledgers', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageLedger /></Suspense> },
  //         { path: 'transactions/addLedger', element: <Suspense fallback={<LazyLoaderSuspense />}><AddLedger /></Suspense> },
  //         { path: "displayfiles", element: <DisplayFiles /> },
  //         { path: 'displayfiles/fileCategories', element: <Suspense fallback={<LazyLoaderSuspense />}><ManageCategoriesInFiles /></Suspense> },
  //         { path: 'displayfiles/addcategories', element: <Suspense fallback={<LazyLoaderSuspense />}><AddCategoryInFiles /></Suspense> },
  //         { path: 'reminders', element: <ManageReminders /> },
  //       ],
  //     },
  //     {
  //       element: <SimpleLayout />,
  //       children: [
  //         { element: <Navigate to="/dashboard/home" />, index: true },
  //         { path: '404', element: <Page404 /> },
  //         { path: '*', element: <Navigate to="/404" /> },
  //       ],
  //     },
  //     {
  //       path: '*',
  //       element: <Navigate to="/404" replace />,
  //     },
  //   ]);

  return useRoutes(routes);

}