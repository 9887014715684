import {
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { API_SIGN, FETCH_TRANSACTION_ITEMS, FETCH_TRANSACTION_LEDGER } from '../../../variables/api-variables';
import { decryptToken } from '../../../components/security/securityFunctions';
import { apiCall } from '../../../components/functions/apiCall';
import NoData from '../../../components/no_data/NoData';
import { ShallowRouting } from '../../../components/routing/ShallowRouting';

const DisplayItems = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  const [searchString, setSearchString] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [rows, setRowsChange] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [page, setPage] = useState(urlSearchParams.has('page') ? parseInt(urlSearchParams.get('page'), 10) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [totalRows, setTotalRows] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let companyId = searchParams.get('company_id');

  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = userToken.company_id;
    }
  }

  const handlePageChange = (e, value) => {
    console.log(value);
    const existingUrlSearchParams =
      urlSearchParams.toString() !== '' && urlSearchParams.toString() !== null
        ? JSON.parse(`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
            return key === '' ? value : decodeURIComponent(value);
          })
        : null;
    setPage(value);
    navigate(ShallowRouting({ ...existingUrlSearchParams, page: value }), { replace: true });
    fetchItems(null, null, value.toString());
    // setPage(value);
  };

  const handleRowsPerPageChange = (value) => {
    console.log(value);
    if (value !== rowsPerPage && [15, 25, 50, 100].includes(value)) {
      const existingUrlSearchParams =
        (`{"${urlSearchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => {
          return key === '' ? value : decodeURIComponent(value);
        });
      navigate(ShallowRouting({ ...existingUrlSearchParams, limitRows: value }), { replace: true });
      setRowsPerPage(value);
      setPage(0);
      fetchItems(null, value, '0');
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value.length >= 3) {
      fetchItems(value, rowsPerPage, '0');
      setSearchString(value === '' ? null : value);
    }
    if (value.length === 0) {
      fetchItems(value, rowsPerPage, '0');
      setSearchString(value === '' ? null : value);
    }
  };

  const navigate = useNavigate();

  const handleEdit = (itemId) => {
    navigate(`/dashboard/transactions/addItems?company_id=${companyId}`, {
      state: {
        stock_id: itemId,
      },
    });
  };

  const fetchItems = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    // setting the loader for it
    setShowLoader(true);
    try {
      // API request for fetch stock
      const inputData = JSON.stringify({
        function_type: 'fetch_stock',
        company_id: companyId,
        search_string: searchStringForce || searchString,
        limit: rowsPerPageForce || rowsPerPage,
        page: !pageForce ? page + 1 : parseInt(pageForce, 10) + 1,
        api_signature: API_SIGN,
        jwt_token: userToken.userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_TRANSACTION_ITEMS, 'plain/text')
        .then((response) => {
          setShowLoader(false);
          console.log('STOCK: ', response);
          if (response.data.status === 1) {
            setTotalRows(parseInt(response.data.response.data.total_rows, 10));
            const tempVar = response.data.response.data.stock_info;
            setRowsChange([...tempVar]);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const columns = useMemo(() => [
    { id: 'id', name: 'Id', display: false },
    { id: 'item_name', name: 'Item Name', display: true },
    { id: 'current_qty', name: 'Current Qty', display: true },
    { id: 'action', name: 'Actions', display: true },
  ]);

  const isMobileScreen = useMediaQuery('(max-width:475px)');

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <OutlinedInput
              defaultValue=""
              fullWidth
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              }
              sx={{ maxWidth: 500 }}
              onChange={handleSearch}
            />
          </Card>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          {showLoader ? (
            <CircularProgress margintop={10} size={10} />
          ) : rows ? (
            rows.length > 0 ? (
              <Container style={{ padding: 0, maxWidth: '1440px' }}>
                <Paper sx={{ width: '100%' }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {columns.map((column) =>
                            column.display === true ? (
                              <TableCell key={column.id} className={isMobileScreen ? 'sticky-header' : ''}>
                                {column.name}
                              </TableCell>
                            ) : null
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows &&
                          rows.map((row, index) => {
                            // console.log(row);
                            const ItemId = row.id;
                            const ItemName = row.item_name;
                            const HsnCode = row.hsn_code;

                            return (
                              <TableRow key={index}>
                                {columns &&
                                  columns.map((column, index) => {
                                    const value = row[column.id];
                                    return column.display === true ? (
                                      column.id !== 'action' ? (
                                        <TableCell key={value}>{value}</TableCell>
                                      ) : (
                                        <TableCell key={ItemId} className={isMobileScreen ? 'sticky-column' : ''}>
                                          <IconButton key={'edit'} onClick={() => handleEdit(ItemId)}>
                                            <Edit sx={{ fontSize: 15 }} />
                                          </IconButton>
                                        </TableCell>
                                      )
                                    ) : null;
                                  })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    page={page}
                    count={totalRows}
                    // count={5}
                    rowsPerPage={rowsPerPage}
                    component="div"
                    onPageChange={(e, value) => handlePageChange(e, value)}
                    onRowsPerPageChange={(e) => {
                      handleRowsPerPageChange(e.target.value);
                    }}
                  />
                </Paper>
              </Container>
            ) : (
              <NoData />
            )
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayItems;
