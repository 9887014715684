import { Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import NoData from '../../../components/no_data/NoData';
import TransactionsCategoryStacked from './DisplayPerspective/CategoryStacked';
import TransactionsLedgerStacked from './DisplayPerspective/LedgersStacked';
import TransactionsDetailed from './DisplayPerspective/TransactionsDetails';
import palette from '../../../theme/palette';
import TransactionStats from './StatsCharts/TransactionsStats';

const TransactionsDisplay = ({
  filterValues,
  categoryOptions,
  data,
  displayType,
  onClickRow,
  onClickEdit,
  handlePageChange,
  handleRowsPerPageChange,
  throughOptions,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const [showWalletLedger, setShowWalletLedger] = useState(false);
  useEffect(() => {
    setSelectedCategory(categoryOptions.filter((element) => (element.category_id).toString() === filterValues.category)[0]?.name);
  }, [categoryOptions]);
  console.log(selectedCategory);

  useEffect(() => {
    if (filterValues.through !== 'all') {
      // checking for any other type of filters, like  category, ledger, narration, transactiontype
      if (
        filterValues.category === 'all' &&
        (filterValues.ledgername === null || filterValues.ledgername === '') &&
        filterValues.narration.trim() === '' &&
        filterValues.transactionType === 'all'
      ) {
        setShowWalletLedger(true);
      }
    }
  }, []);

  return (
    <>
      {parseInt(data.total_rows) > 0 ? (
        <Stack direction="column" padding={'10px'}>
          {filterValues.viewMode === 'stacked_ledger' ? (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h4" fontWeight={'500'} color={palette.grey[700]} gutterBottom>
                Ledger Summary
              </Typography>
            </Stack>
          ) : filterValues.viewMode === 'stacked_category' ? (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h4" fontWeight={'500'} color={palette.grey[700]} gutterBottom>
                Category Summary
              </Typography>
            </Stack>
          ) : filterValues.viewMode === 'no_stack' && showWalletLedger ? (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h4" fontWeight={'500'} color={palette.grey[700]}>
                Ledger (Wallet) :&nbsp;
              </Typography>
              <Typography variant="h4" fontWeight={'500'} color={palette.primary.main}>
                {throughOptions?.filter((element) => filterValues.through === element.id)[0]?.label}
              </Typography>
            </Stack>
          ) : (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h4" fontWeight={'500'} color={palette.grey[700]} gutterBottom>
                Day Book
              </Typography>
            </Stack>
          )}
          {filterValues.through !== 'all' && !showWalletLedger ? (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h6" fontWeight={'500'} color={palette.grey[700]}>
                Wallet :&nbsp;
              </Typography>
              <Typography variant="h6" fontWeight={'500'} color={palette.primary.main}>
                {throughOptions?.filter((element) => filterValues.through === element.id)[0]?.label}
              </Typography>
            </Stack>
          ) : (
            ''
          )}
          {filterValues.category !== 'all' ? (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h6" fontWeight={'500'} color={palette.grey[700]}>
                Category :&nbsp;
              </Typography>
              <Typography variant="h6" fontWeight={'500'} color={palette.primary.main}>
                {selectedCategory}
              </Typography>
            </Stack>
          ) : (
            ''
          )}
          {filterValues.ledgername !== null && filterValues.ledgername !== '' ? (
            <Stack direction={'row'} justifyContent={'start'}>
              <Typography variant="h6" fontWeight={'500'} color={palette.grey[700]}>
                Ledger :&nbsp;
              </Typography>
              <Typography variant="h6" fontWeight={'500'} color={palette.primary.main}>
                {filterValues?.ledgername?.ledgername}
              </Typography>
            </Stack>
          ) : (
            ''
          )}
        </Stack>
      ) : null}

      {parseInt(data?.total_rows) > 0 ? <TransactionStats data={data} /> : null}
      {parseInt(data.total_rows) > 0 ? (
        displayType === 'stacked_category' ? (
          <TransactionsCategoryStacked
            data={data}
            filterValues={filterValues}
            onClickRow={onClickRow}
            onClickEdit={onClickEdit}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        ) : displayType === 'stacked_ledger' ? (
          <TransactionsLedgerStacked
            data={data}
            filterValues={filterValues}
            onClickRow={onClickRow}
            onClickEdit={onClickEdit}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        ) : (
          <TransactionsDetailed
            filterValues={filterValues}
            data={data}
            onClickRow={onClickRow}
            onClickEdit={onClickEdit}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        )
      ) : (
        <NoData errormsg={'No data found for the selected criteria.'} />
      )}
    </>
  );
};

export default TransactionsDisplay;
