import { Box, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { indianFormatNumber } from '../../../../components/functions/data_functions';

const TransactionStats = ({ data }) => {
  const isMobileScreen = useMediaQuery('(max-width:475px)');
  const isLgScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <Grid container spacing={isMobileScreen ? 1 : 2} marginBottom={'20px'}>
      {data?.closing_balance || data?.opening_balance ? (
        <>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(135deg, rgba(57, 144, 60, 1), rgba(11, 102, 177, 1))',
                color: '#ffffff',
                borderRadius: '15px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.receipt_total ? indianFormatNumber(data.receipt_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Receipts
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: 'right' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.payment_total ? indianFormatNumber(data.payment_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Payments
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(135deg, rgba(8, 185, 173, 1), rgba(4, 111, 199, 1))',
                color: '#ffffff',
                borderRadius: '15px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.sales_total ? indianFormatNumber(data.sales_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Sales
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: 'right' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.purchase_total ? indianFormatNumber(data.purchase_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Purchase
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {data?.closing_balance ? (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                sx={{
                  minWidth: 'auto',
                  background: 'linear-gradient(135deg, rgba(142, 101, 255, 1), rgba(116, 46, 146, 1))',
                  color: '#ffffff',
                  borderRadius: '15px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.03)',
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
                  },
                }}
              >
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ flex: 1, textAlign: 'left' }}>
                    <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                      {data.opening_balance ? indianFormatNumber(data.opening_balance) : '0.00'}
                    </Typography>
                    <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                      Opening Balance
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1, textAlign: 'right' }}>
                    <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                      {data.closing_balance ? indianFormatNumber(data.closing_balance) : '0.00'}
                    </Typography>
                    <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                      Closing Balance
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(110deg, rgba(38, 129, 41, 0.7), rgba(11, 102, 177, 1))',
                color: '#ffffff',
                borderRadius: '15px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.receipt_total ? indianFormatNumber(data.receipt_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Receipts
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: 'right' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.payment_total ? indianFormatNumber(data.payment_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Payments
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(110deg, rgba(8, 185, 173, 1), rgba(4, 112, 199, 1))',
                color: '#ffffff',
                borderRadius: '15px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.03)',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
                },
              }}
            >
              <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.sales_total ? indianFormatNumber(data.sales_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Sales
                  </Typography>
                </Box>
                <Box sx={{ flex: 1, textAlign: 'right' }}>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600' }}>
                    {data.purchase_total ? indianFormatNumber(data.purchase_total) : '0.00'}
                  </Typography>
                  <Typography sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500 }}>
                    Purchase
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}

      {/* {data?.opening_balance ? (
        <>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(110deg, rgba(124, 135, 253, 0.7), rgba(5, 127, 241, 1))',
                color: '#ffffff',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}
                >
                  {data.opening_balance ? indianFormatNumber(data.opening_balance) : '0.00'}
                </Typography>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}
                >
                  Opening Balance
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(110deg, rgba(142, 101, 255, 0.6), rgba(116, 46, 146, 0.9))',
                color: '#ffffff',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}
                >
                  {data.closing_balance ? indianFormatNumber(data.closing_balance) : '0.00'}
                </Typography>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}
                >
                  Closing Balance
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : null} */}

      {/* {data?.closing_balance ? (
        <>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(110deg, rgba(124, 135, 253, 0.7), rgba(5, 127, 241, 1))',
                color: '#ffffff',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}
                >
                  {data.opening_balance ? indianFormatNumber(data.opening_balance) : '0.00'}
                </Typography>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}
                >
                  Opening Balance
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Card
              sx={{
                minWidth: 'auto',
                background: 'linear-gradient(110deg, rgba(142, 101, 255, 0.6), rgba(116, 46, 146, 0.9))',
                color: '#ffffff',
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 16 : 20, fontWeight: '600', textAlign: 'center' }}
                >
                  {data.closing_balance ? indianFormatNumber(data.closing_balance) : '0.00'}
                </Typography>
                <Typography
                  sx={{ fontSize: isLgScreen ? 16 : isMobileScreen ? 12 : 17, fontWeight: 500, textAlign: 'center' }}
                >
                  Closing Balance
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : null} */}
    </Grid>
  );
};

export default TransactionStats;