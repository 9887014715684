import { AddCircle, ArrowBack, Delete } from '@mui/icons-material';
import {
  Container,
  Card,
  Box,
  Stack,
  Button,
  CardContent,
  TextField,
  Grid,
  Typography,
  IconButton,
  Divider,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';
import FormAlerts from '../../components/form/FormAlerts';
import {
  ADD_TRANSACTION,
  ALTER_TRANSACTION,
  API_SIGN,
  FETCH_CATEGORY,
  FETCH_LEDGERS,
  FETCH_SPECIFIC_TRANSACTION,
  FETCH_WALLET,
} from '../../variables/api-variables';
import { apiCall } from '../../components/functions/apiCall';
import TransactionsInfo from './TransactionsInfo';
import { decryptToken } from '../../components/security/securityFunctions';
import { SkeletonLoaderSingleForm } from '../../components/skeleton_loader/skeletonLoader';
import CompanyTitle from '../../components/titles/CompanyTitle';
import DeleteTransation from './DeleteTransations';
import AddBill from './billing/AddBill';
import AddPurchaseBill from './purchase_bill/AddPurchaseBill';

const AddTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const userRole = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').role;
  const companyData = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}');
  const companyId = companyData.company?.company_id;
  // console.log(companyId);
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isCreate, setIsCreate] = useState(!state || !state.transactionId);
  const [fetchTransactionLoader, setFetchTransactionLoader] = useState(state?.transactionId !== null);
  const buttonTitle = isCreate ? 'Save' : 'Update';
  const [isLoading, setIsLoading] = useState(false);
  const [throughOptions, setThroughOptions] = useState([]);
  const [ledgerOptions, setLedgerOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const isMobileScreen = useMediaQuery('(max-width:475px)');
  // console.log(userRole);
  const allowedUserToChageDate = userRole === 'superadmin' || userRole === 'admin' ? false : true;

  const defaultCreateRow = {
    ledgername: '',
    transactiontype: 'receipt',
    amount: '0.00',
    through: '',
    remarks: '',
    category_id: '',
  };

  const fetchThrough = async () => {
    const inputData = JSON.stringify({
      function_type: 'fetch_payment_wallets',
      company_id: companyId,
      search_string: '',
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    console.log(inputData);
    apiCall(inputData, 'POST', FETCH_WALLET, 'plain/text').then((response) => {
      if (response.data.status === 1) {
        console.log('Through: ', response);
        const throughInfo = response.data.response.payment_info;
        if (throughInfo.length > 0) {
          const throughList = throughInfo.map((through) => ({
            id: through.name,
            label: `${through.name}`,
          }));
          setThroughOptions(throughList);
        } else {
          console.log(response);
        }
      }
    });
  };

  const [maxReachedLedgersString, setMaxReachedLedgersString] = useState(false);
  const fetchLedgers = async (searchString = '') => {
    let searchFurtherApi = 1;
    if (maxReachedLedgersString !== false) {
      if (Array.isArray(maxReachedLedgersString)) {
        // check here before proceeding and checking
        if (maxReachedLedgersString.some((str) => str.startsWith(searchString))) {
          searchFurtherApi = 0;
        }
      }
    }
    if (searchFurtherApi > 0) {
      try {
        const inputData = JSON.stringify({
          function_type: 'fetch_ledgers',
          company_id: companyId,
          exclude: '1',
          search_string: searchString,
          api_signature: API_SIGN,
          jwt_token: userToken,
        });
        console.log('Ledgers:', inputData);
        apiCall(inputData, 'POST', FETCH_LEDGERS, 'plain/text')
          .then((response) => {
            if (response.data.status === 1) {
              if (response.data.response.max_reached === 1) {
                if (!maxReachedLedgersString) {
                  setMaxReachedLedgersString([searchString]);
                } else {
                  setMaxReachedLedgersString([...maxReachedLedgersString, searchString]);
                }
              }

              // Merge the arrays
              let mergedArray = ledgerOptions.concat(response.data.response.ledgers_info);

              // Filter out duplicate objects based on ID
              let uniqueArray = mergedArray.filter(
                (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
              );
              setLedgerOptions(uniqueArray);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.error('Error fetching ledgers:', error.message);
          });
      } catch (error) {
        console.error('Error fetching ledgers:', error.message);
      }
    }
  };

  const fetchCategory = async () => {
    const inputData = JSON.stringify({
      function_type: 'fetch_ledger_categories',
      company_id: companyId,
      search_string: '',
      limit: 'all',
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    console.log('Category:', inputData);
    apiCall(inputData, 'POST', FETCH_CATEGORY, 'plain/text').then((response) => {
      console.log('Category: ', response);
      if (response.data.status === 1) {
        const categoryInfo = response.data.response.category_info;
        if (categoryInfo.length > 0) {
          const categoryList = categoryInfo.map((category) => ({
            id: category.category_id,
            label: `${category.name}`,
          }));
          setCategoryOptions(categoryList);
        } else {
          console.log(response);
        }
      }
    });
  };

  const handleAddClick = () => {
    formik.setFieldValue('rows', [...formik.values.rows, defaultCreateRow]);
  };

  const navigateBack = () => {
    if (location.state !== null && location.state.allowBack !== null && location.state.allowBack === true) {
      window.history.back();
    } else {
      navigate('/dashboard/transactions');
    }
  };

  const todayDate = new Date();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date_transaction: `${todayDate.getFullYear()}-${String(todayDate.getMonth() + 1).padStart(2, '0')}-${String(
        todayDate.getDate()
      ).padStart(2, '0')}`,
      rows: [defaultCreateRow],
    },
    validationSchema: Yup.object({
      date_transaction: Yup.date().required('Date is required'),
      rows: Yup.array().of(
        Yup.object().shape({
          ledgername: Yup.string().required('Title is required'),
          transactiontype: Yup.string().required('Transaction type is required'),
          amount: Yup.number().required('Amount is required').positive('Amount must be greater than 0.00'),
          through: Yup.string().required('Through is required'),
          remarks: Yup.string(),
          category_id: Yup.string(),
        })
      ),
    }),
    onSubmit: async (values, helpers) => {
      console.log('here');
      console.log(values);
      setIsLoading(true);
      setErrorMessage('');
      try {
        let payload = {
          function_type: isCreate ? 'create_transaction' : 'alter_transaction',
          company_id: companyId,
          date: values.date_transaction,
          transaction: values.rows.map((row) => ({
            transactiontype: row.transactiontype,
            ledgername: row.ledgername,
            amount: row.amount,
            through: row.through,
            remarks: row.remarks,
            category_id: row.category_id,
          })),
          api_signature: API_SIGN,
          jwt_token: userToken,
          transaction_id: isCreate ? null : state.transactionId,
        };

        const inputData = JSON.stringify(payload);
        console.log(inputData);
        // Make the API call
        // setIsLoading(true);
        apiCall(inputData, 'POST', isCreate ? ADD_TRANSACTION : ALTER_TRANSACTION, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              // console.log(response);
              enqueueSnackbar(isCreate ? response.data.response.successmsg : response.data.response, {
                variant: 'success',
                autoHideDuration: 2000,
              });
              if (isCreate) formik.resetForm();
              // resetForm();
              navigateBack();
            } else {
              setErrorMessage(response.data.response);
              if (typeof response.data.response === 'string') {
                enqueueSnackbar(response.data.response, {
                  variant: 'error',
                  autoHideDuration: 2000,
                });
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            enqueueSnackbar(error.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
            setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        enqueueSnackbar(err.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  const getTransactionSpecific = () => {
    const inputData = JSON.stringify({
      function_type: 'fetch_specific_transaction',
      company_id: companyId,
      transaction_id: state.transactionId,
      api_signature: API_SIGN,
      jwt_token: userToken,
    });
    apiCall(inputData, 'POST', FETCH_SPECIFIC_TRANSACTION, 'plain/text').then((response) => {
      if (response.data.status === 1) {
        console.log('Transaction: ', response);
        let transactionInfo = response.data.response.data;
        formik.setFieldValue('date_transaction', transactionInfo.date);
        formik.setFieldValue('rows', [
          {
            ledgername: transactionInfo.ledgerid,
            transactiontype: transactionInfo.transactiontype,
            amount: transactionInfo.amount,
            through: transactionInfo.through,
            remarks: transactionInfo.remarks,
            category_id: '',
          },
        ]);
        setFetchTransactionLoader(false);
      } else {
        console.log(response);
        enqueueSnackbar('Error in fetching transaction', { variant: 'error', autoHideDuration: 2000 });
      }
    });
  };

  useEffect(() => {
    fetchThrough();
    fetchLedgers('');
    fetchCategory();
    if (state?.transactionId !== null && state?.transactionId !== undefined) {
      console.log(state);
      console.log(state.transactionId);
      getTransactionSpecific();
    }
  }, []);

  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = (x = true) => {
    setIsDelete(x);
  };

  const selectedTransactionType = formik.values?.rows?.[0]?.transactiontype;
  console.log(selectedTransactionType);

  return (
    <>
      <Helmet>
        <title>Transactions | Rejoice Diary</title>
      </Helmet>
      {isDelete ? (
        <DeleteTransation
          open={isDelete}
          onClose={handleDelete}
          deleteId={state?.transactionId}
          navigateBack={navigateBack}
        />
      ) : null}

      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path="companies" />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <CompanyTitle />
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title={isCreate ? 'Create Transactions' : 'Update Transaction'} />
                <div>
                  {location.state && location.state.showCancelBtn ? (
                    <Button
                      color="info"
                      variant="text"
                      size="small"
                      sx={{ marginRight: '10px' }}
                      onClick={() => {
                        navigateBack();
                      }}
                    >
                      <ArrowBack />
                      &nbsp; Back
                    </Button>
                  ) : null}
                </div>
              </Stack>
              {/* form starting form here */}
              {!isCreate && fetchTransactionLoader ? (
                <SkeletonLoaderSingleForm />
              ) : (
                <Box>
                  <div>
                    <Card sx={{ p: isMobileScreen ? '20px 0px' : 2, margin: '15px 0px' }}>
                      {!isCreate && isMobileScreen ? (
                        <Stack direction={'row'} justifyContent={'end'} margin={'10px 0px'}>
                          <Button
                            variant="text"
                            color="error"
                            onClick={handleDelete}
                            style={{ marginLeft: 'auto', padding: '5px' }}
                          >
                            Delete
                            <Delete />
                          </Button>
                        </Stack>
                      ) : null}
                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <form noValidate onSubmit={formik.handleSubmit}>
                            <Grid container margin={'0px'} padding={'0px'}>
                              <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                  variant="outlined"
                                  label="Date"
                                  name="date_transaction"
                                  type="date"
                                  error={!!(formik.touched.date_transaction && formik.errors.date_transaction)}
                                  helperText={formik.touched.date_transaction && formik.errors.date_transaction}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{ marginBottom: '15px' }}
                                  value={formik.values.date_transaction}
                                  disabled={allowedUserToChageDate}
                                />
                              </Grid>
                              {!isCreate && !isMobileScreen ? (
                                <Grid item sm={6} md={6}>
                                  <Stack direction={'row'} justifyContent={'end'}>
                                    <Button
                                      variant="text"
                                      color="error"
                                      onClick={handleDelete}
                                      style={{ marginLeft: 'auto', padding: '5px' }}
                                    >
                                      Delete
                                      <Delete />
                                    </Button>
                                  </Stack>
                                </Grid>
                              ) : null}
                            </Grid>
                            {categoryOptions.length <= 0 || throughOptions.length <= 0 ? (
                              <SkeletonLoaderSingleForm />
                            ) : (
                              formik.values?.rows.map((row, index) => {
                                const values = formik.values;
                                const setFieldValue = formik.setFieldValue;
                                return (
                                  <TransactionsInfo
                                    key={index}
                                    formik={formik}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    rows={row}
                                    index={index}
                                    throughOptions={throughOptions}
                                    fetchLedgers={fetchLedgers}
                                    ledgerOptions={ledgerOptions}
                                    categoryOptions={categoryOptions}
                                    isCreate={isCreate}
                                    navigateBack={navigateBack}
                                    transactionId={state.transactionId}
                                  />
                                );
                              })
                            )}
                            {isCreate && selectedTransactionType !== 'sales' && selectedTransactionType !== 'purchase' ? (
                              <Button
                                variant="text"
                                color="info"
                                onClick={handleAddClick}
                                style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '20px', padding: '5px' }}
                              >
                                <AddCircle />
                                &nbsp; Add Row
                              </Button>
                            ) : (
                              ''
                            )}

                            {formik.errors.submit && (
                              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                                {formik.errors.submit}
                              </Typography>
                            )}
                            <FormAlerts errorMessage={errorMessage} />
                            {selectedTransactionType !== 'sales' && selectedTransactionType !== 'purchase' ? (
                              <Button
                                variant="contained"
                                type="submit"
                                disabled={isLoading}
                                sx={{ margin: isMobileScreen ? '10px 15px' : '20px 10px' }}
                              >
                                {isLoading ? <CircularProgress size={'20'} /> : buttonTitle}
                              </Button>
                            ) : null}
                          </form>
                        </Grid>
                      </Grid>
                      {selectedTransactionType === 'sales' && <AddBill transactionId={state?.transactionId} />}
                      {selectedTransactionType === 'purchase' && (
                        <AddPurchaseBill transactionId={state?.transactionId} />
                      )}
                    </Card>
                  </div>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default AddTransaction;
