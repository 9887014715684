import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { Delete, ExpandMore } from '@mui/icons-material';
import CustomAddButton from '../../../components/customAddButton/CustomAddButton';
import { apiCall, handleSuccessSubmit } from '../../../components/functions/apiCall';
import {
  ALTER_PURCHASE_TRANSACTION,
  API_SIGN,
  CREATE_PURCHASE_TRANSACTION,
  FETCH_STOCK,
  FETCH_TRANSACTION_LEDGER,
  FETCH_TRANSACTION_SALES,
  LAST_BILLING_SHIPPING_DATA,
  LAST_COMPANY_DATA,
} from '../../../variables/api-variables';
import { decryptToken } from '../../../components/security/securityFunctions';
import DialogGSTDiscountAllPurchase from './dialogBoxes/DialogGSTDiscountAllPurchase';
import DialogGSTDiscountPurchase from './dialogBoxes/DialogGSTDiscountPurchase';
import { indianFormatNumber } from '../../../components/functions/data_functions';
import { setSelectCompanyData } from '../../../store/slice/companyData';

const AddPurchaseBill = ({ transactionId }) => {
  const dispatch = useDispatch(); // Initialize the dispatch function
  const location = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const {userToken} = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  const company = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}').company.company_id;
  const [lastFormValues, setLastFormValues] = useState();
  const [formValues, setFormValues] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreate, setIsCreate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [gstDiscountDialog, setGstDiscountDialog] = useState(false);
  const [gstDiscountDialogAllOpen, setGstDiscountDialogAllOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(urlSearchParams.has('page') ? parseInt(urlSearchParams.get('page'), 10) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [stockDetails, setStockDetails] = useState([]);
  const [ledgerDetails, setLedgerDetails] = useState([]);
  const [ledgerName, setLedgerName] = useState([]);
  const [mobileCompany, setMobileCompany] = useState('');
  const [mobileBilling, setMobileBilling] = useState('');
  const [mobileShipping, setMobileShipping] = useState('');

  const mobileChangeCompany = (mobile) => {
    setMobileCompany(mobile);
  };

  const mobileChangeBilling = (mobile) => {
    setMobileBilling(mobile);
  };
  const mobileChangeShipping = (mobile) => {
    setMobileShipping(mobile);
  };

  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(
    today.getDate()
  ).padStart(2, '0')}`;

  const searchParams = new URLSearchParams(location.search);
  let companyId = searchParams.get('company_id');
  if (userToken.workflow_type === 'individual') {
    if (!companyId || companyId === '') {
      companyId = company;
    }
  }

  const cellStyle = {
    padding: 3,
  };

  const fetchTransactionPurchase = async () => {
    setIsLoading(true);
    try {
      const inputData = JSON.stringify({
        function_type: 'fetch_transaction_sales',
        company_id: company,
        transaction_id: transactionId,
        jwt_token: userToken,
        api_signature: API_SIGN,
        // export_type: explicitExportType != null ? explicitExportType : exportType,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_TRANSACTION_SALES, 'plain/text')
        .then((response) => {
          setIsLoading(false);
          console.log('Purchase transaction: ', response);
          if (response.data.status === 1) {
            const transactionInfo = response.data.response.transaction_info.transactiondata;
            const stockInfo = response.data.response.transaction_info.stock_info;
            const totalGstDiscount = response.data.response.transaction_info.total_gst_discount;

            setMobileCompany(transactionInfo.company_details.mobile);
            setMobileBilling(transactionInfo.billing_address.mobile);
            setMobileShipping(transactionInfo.shipping_address.mobile);

            // Map the response to form values
            setFormValues({
              date: transactionInfo.date,
              transactiontype: transactionInfo.transactiontype || 'purchase',
              narration: transactionInfo.remarks || '',
              // ledgername: transactionInfo.ledgerid || '',
              my_company_details: {
                company_name: transactionInfo.company_details.company_name,
                company_address: transactionInfo.company_details.company_address,
                email: transactionInfo.company_details.email,
                mobile: transactionInfo.company_details.mobile,
                gst: transactionInfo.company_details.gst,
                terms_conditions: transactionInfo.company_details.terms_conditions,
              },
              billing_shipping_same: transactionInfo.billing_shipping_bool === '1' ? 1 : 0,
              billing_address: {
                company_name: transactionInfo.billing_address.company_name,
                company_address: transactionInfo.billing_address.company_address,
                email: transactionInfo.billing_address.email,
                mobile: transactionInfo.billing_address.mobile,
                gst: transactionInfo.billing_address.gst,
              },
              shipping_address: {
                company_name: transactionInfo.shipping_address.company_name,
                company_address: transactionInfo.shipping_address.company_address,
                email: transactionInfo.shipping_address.email,
                mobile: transactionInfo.shipping_address.mobile,
                gst: transactionInfo.shipping_address.gst,
              },
              total_gst_discount: totalGstDiscount,
              stock_info: stockInfo.map((stock) => ({
                stock_name: stock.stock_name || '',
                hsn_code: stock.hsn_code || '',
                qty: stock.stock_transactions.qty || '',
                mrp: stock.stock_transactions?.mrp || '',
                discount: stock.stock_transactions?.discount || {},
                gst: stock.stock_transactions?.gst || {},
                other_tax: stock.stock_transactions?.other_tax || {},
                total_amount: stock.stock_transactions?.amount || '0.00',
              })),
              dispatch: {
                invoice_no: transactionInfo.dispatch_info.invoice_no || '',
                doc_no: transactionInfo.dispatch_info.doc_no || '',
                delivery_note: transactionInfo.dispatch_info.delivery_note || '',
                destination: transactionInfo.dispatch_info.destination || '',
                carrier_name: transactionInfo.dispatch_info.carrier_name || '',
                bill_of_lading: transactionInfo.dispatch_info.bill_of_lading || '',
                dispatch_date: transactionInfo.dispatch_info.dispatch_date || '',
                motor: transactionInfo.dispatch_info.motor || '',
                e_way_bill: transactionInfo.dispatch_info.e_way_bill || '',
              },
            });
            setIsLoading(false);
          } else {
            setIsLoading(false);
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (transactionId) {
      setIsCreate(false);
      fetchTransactionPurchase();
    }
  }, []);

  const fetchLedger = () => {
    // setting the loader for it
    setIsLoading(true);
    try {
      const inputData = JSON.stringify({
        function_type: 'fetch_ledgers',
        company_id: companyId || company,
        search_string: '',
        exclude: '1',
        limit: '20',
        page: '1',
        jwt_token: userToken,
        api_signature: API_SIGN,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_TRANSACTION_LEDGER, 'plain/text')
        .then((response) => {
          setIsLoading(false);
          console.log('LEDGER: ', response);
          if (response.data.status === 1) {
            setLedgerDetails(response.data.response.ledgers_info);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLedger();
  }, []);

  const fetchStock = (searchStringForce, rowsPerPageForce = null, pageForce = null) => {
    setIsLoading(true);
    try {
      // API request for fetch stock
      const inputData = JSON.stringify({
        function_type: 'fetch_stock',
        company_id: companyId || company,
        search_string: searchStringForce || searchString,
        limit: rowsPerPageForce || rowsPerPage,
        page: !pageForce ? page + 1 : parseInt(pageForce, 10) + 1,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', FETCH_STOCK, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            setTotalRows(parseInt(response.data.response.data.total_rows, 10));
            setStockDetails(response.data.response.data.stock_info);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLastCompanyData = () => {
    setIsLoading(true);
    try {
      // API request for fetch last company data
      const inputData = JSON.stringify({
        function_type: 'get_last_company',
        company_id: companyId || company,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', LAST_COMPANY_DATA, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            const companyData = response.data.response.data;
            setMobileCompany(companyData?.mobile);

            dispatch(
              setSelectCompanyData({
                lastCompanyData: {
                  companyName: companyData.company_name || '',
                  companyAddress: companyData.company_address || '',
                  companyEmail: companyData.email || '',
                  companyMobile: companyData.mobile || '',
                  companyGst: companyData.gst || '',
                },
              })
            );
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLastBillingShippingData = () => {
    setIsLoading(true);
    try {
      // API request for fetch last company data
      const inputData = JSON.stringify({
        function_type: 'get_billing_shipping',
        ledgername: ledgerName,
        company_id: companyId || company,
        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(inputData);
      apiCall(inputData, 'POST', LAST_BILLING_SHIPPING_DATA, 'plain/text')
        .then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.data.status === 1) {
            const billingShippingDataArray = response.data.response.data;

            if (billingShippingDataArray && billingShippingDataArray.length > 0) {
              const billingData = billingShippingDataArray[0];
              const shippingData = billingShippingDataArray[1]
                ? billingShippingDataArray[1]
                : billingShippingDataArray[0];

              setMobileBilling(billingData?.mobile);
              setMobileShipping(shippingData?.mobile);
              setLastFormValues((prevValues) => ({
                ...prevValues,
                billing_address: {
                  company_name: shippingData?.company_name || '',
                  company_address: billingData?.company_address || '',
                  email: billingData?.email || '',
                  mobile: billingData?.mobile || '',
                  gst: billingData?.gst || '',
                },
                shipping_address: {
                  company_name: shippingData?.company_name || '',
                  company_address: shippingData?.company_address || '',
                  email: shippingData?.email || '',
                  mobile: shippingData?.mobile || '',
                  gst: shippingData?.gst || '',
                },
              }));
            } else {
              console.log('No billing or shipping data available.');
            }
          } else {
            setLastFormValues((prevValues) => ({
              ...prevValues,
              billing_address: {
                company_name: '',
                company_address: '',
                email: '',
                mobile: '',
                gst: '',
              },
              shipping_address: {
                company_name: '',
                company_address: '',
                email: '',
                mobile: '',
                gst: '',
              },
            }));
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (ledgerName) {
      fetchLastBillingShippingData();
    }
  }, [ledgerName]);

  useEffect(() => {
    fetchLastCompanyData();
  }, []);

  useEffect(() => {
    fetchStock();
  }, []);

  const handleAddRow = () => {
    formik.setFieldValue('stock_info', [
      ...formik.values.stock_info,
      {
        stock_name: '',
        hsn_code: '',
        qty: '1',
        mrp: '',
        gst: {
          cgst: { percentage_bool: '', percentage: '', amount: '' },
          sgst: { percentage_bool: '', percentage: '', amount: '' },
          igst: { percentage_bool: '', percentage: '', amount: '' },
          cess: { percentage_bool: '', percentage: '', amount: '' },
        },
        discount: { percentage_bool: '', percentage: '', amount: '' },
        other_tax: { percentage_bool: '', percentage: '', amount: '' },
        total_amount: '0.00',
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    if (formik.values.stock_info.length === 1) {
      enqueueSnackbar("Can't delete, at least one row required.", { variant: 'error', autoHideDuration: 2000 });
    } else {
      const updatedStockInfo = formik.values.stock_info.filter((_, i) => i !== index);
      formik.setFieldValue('stock_info', updatedStockInfo);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedStockInfo = formik.values.stock_info.map((row, i) => (i === index ? { ...row, [field]: value } : row));
    formik.setFieldValue('stock_info', updatedStockInfo);
  };

  const saveGoBack = () => {
    // navigate back
    navigate('/dashboard/transactions');
  };

  const saveAddAnother = () => {
    navigate('/dashboard/transactions');
  };

  const myCompanyDetails = useSelector((state) => state.companyData.lastCompanyData);
  const termsConditionsList = useSelector((state) => state.companyData?.company_info || []);

  const selectedCompanyId = company;
  const selectedCompanyTerms =
    termsConditionsList?.find((company) => company.companyId === selectedCompanyId)?.termsConditions || '';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: formValues?.date || formattedDate,
      // ledgername: formValues?.ledgername || '',
      transactiontype: formValues?.transactiontype || 'purchase',
      narration: formValues?.narration || '',
      my_company_details: {
        company_name: formValues?.my_company_details?.company_name
          ? formValues?.my_company_details?.company_name
          : myCompanyDetails.companyName,
        company_address: formValues?.my_company_details?.company_address
          ? formValues?.my_company_details?.company_address
          : myCompanyDetails.companyAddress,
        email: formValues?.my_company_details?.email
          ? formValues?.my_company_details?.email
          : myCompanyDetails.companyEmail,
        mobile: formValues?.my_company_details?.mobile
          ? formValues?.my_company_details?.mobile
          : myCompanyDetails.companyMobile,
        gst: formValues?.my_company_details?.gst ? formValues?.my_company_details?.gst : myCompanyDetails.companyGst,
        terms_conditions: formValues?.my_company_details?.terms_conditions
          ? formValues?.my_company_details?.terms_conditions
          : selectedCompanyTerms,
      },
      billing_shipping_same: formValues?.billing_shipping_same || 0,
      billing_address: {
        company_name: formValues?.billing_address?.company_name
          ? formValues?.billing_address?.company_name
          : lastFormValues?.billing_address?.company_name,
        company_address: formValues?.billing_address?.company_address
          ? formValues?.billing_address?.company_address
          : lastFormValues?.billing_address?.company_address,
        email: formValues?.billing_address?.email
          ? formValues?.billing_address?.email
          : lastFormValues?.billing_address?.email,
        mobile: formValues?.billing_address?.mobile
          ? formValues?.billing_address?.mobile
          : lastFormValues?.billing_address?.mobile,
        gst: formValues?.billing_address?.gst ? formValues?.billing_address?.gst : lastFormValues?.billing_address?.gst,
      },
      shipping_address: {
        company_name: formValues?.shipping_address?.company_name
          ? formValues?.shipping_address?.company_name
          : lastFormValues?.shipping_address?.company_name,
        company_address: formValues?.shipping_address?.company_address
          ? formValues?.shipping_address?.company_address
          : lastFormValues?.shipping_address?.company_address,
        email: formValues?.shipping_address?.email
          ? formValues?.shipping_address?.email
          : lastFormValues?.shipping_address?.email,
        mobile: formValues?.shipping_address?.mobile
          ? formValues?.shipping_address?.mobile
          : lastFormValues?.shipping_address?.mobile,
        gst: formValues?.shipping_address?.gst
          ? formValues?.shipping_address?.gst
          : lastFormValues?.shipping_address?.gst,
      },
      total_gst_discount: {
        discount: {
          percentage_bool:
            formValues?.total_gst_discount?.discount?.percentage_bool !== undefined
              ? formValues.total_gst_discount.discount.percentage_bool
              : '',
          percentage: formValues?.total_gst_discount?.discount?.percentage || '',
          amount: formValues?.total_gst_discount?.discount?.amount || '',
        },
        gst: {
          cgst: {
            percentage_bool:
              formValues?.total_gst_discount?.gst?.cgst?.percentage_bool !== undefined
                ? formValues.total_gst_discount.gst.cgst.percentage_bool
                : '',
            percentage: formValues?.total_gst_discount?.gst?.cgst?.percentage || '',
            amount: formValues?.total_gst_discount?.gst?.cgst?.amount || '',
          },
          sgst: {
            percentage_bool:
              formValues?.total_gst_discount?.gst?.sgst?.percentage_bool !== undefined
                ? formValues.total_gst_discount.gst.sgst.percentage_bool
                : '',
            percentage: formValues?.total_gst_discount?.gst?.sgst?.percentage || '',
            amount: formValues?.total_gst_discount?.gst?.sgst?.amount || '',
          },
          igst: {
            percentage_bool:
              formValues?.total_gst_discount?.gst?.igst?.percentage_bool !== undefined
                ? formValues.total_gst_discount.gst.igst.percentage_bool
                : '',
            percentage: formValues?.total_gst_discount?.gst?.igst?.percentage || '',
            amount: formValues?.total_gst_discount?.gst?.igst?.amount || '',
          },
          cess: {
            percentage_bool:
              formValues?.total_gst_discount?.gst?.cess?.percentage_bool !== undefined
                ? formValues.total_gst_discount.gst.cess.percentage_bool
                : '',
            percentage: formValues?.total_gst_discount?.gst?.cess?.percentage || '',
            amount: formValues?.total_gst_discount?.gst?.cess?.amount || '',
          },
        },
        other_tax: {
          percentage_bool:
            formValues?.total_gst_discount?.other_tax?.percentage_bool !== undefined
              ? formValues.total_gst_discount.other_tax.percentage_bool
              : '',
          percentage: formValues?.total_gst_discount?.other_tax?.percentage || '',
          amount: formValues?.total_gst_discount?.other_tax?.amount || '',
        },
      },
      stock_info: formValues?.stock_info?.map((item, index) => ({
        stock_name: item?.stock_name || '',
        hsn_code: item?.hsn_code || '',
        qty: item?.qty || '1',
        mrp: item?.mrp || '',
        discount: {
          percentage_bool: item?.discount?.percentage_bool !== undefined ? item.discount.percentage_bool : '',
          percentage: item?.discount?.percentage || '',
          amount: item?.discount?.amount || '',
        },
        gst: {
          cgst: {
            percentage_bool: item?.gst?.cgst?.percentage_bool !== undefined ? item.gst.cgst.percentage_bool : '',
            percentage: item?.gst?.cgst?.percentage || '',
            amount: item?.gst?.cgst?.amount || '',
          },
          sgst: {
            percentage_bool: item?.gst?.sgst?.percentage_bool !== undefined ? item.gst.sgst.percentage_bool : '',
            percentage: item?.gst?.sgst?.percentage || '',
            amount: item?.gst?.sgst?.amount || '',
          },
          igst: {
            percentage_bool: item?.gst?.igst?.percentage_bool !== undefined ? item.gst.igst.percentage_bool : '',
            percentage: item?.gst?.igst?.percentage || '',
            amount: item?.gst?.igst?.amount || '',
          },
          cess: {
            percentage_bool: item?.gst?.cess?.percentage_bool !== undefined ? item.gst.cess.percentage_bool : '',
            percentage: item?.gst?.cess?.percentage || '',
            amount: item?.gst?.cess?.amount || '',
          },
        },
        other_tax: {
          percentage_bool: item?.other_tax?.percentage_bool !== undefined ? item.other_tax.percentage_bool : '',
          percentage: item?.other_tax?.percentage || '',
          amount: item?.other_tax?.amount || '',
        },
        total_amount: item?.total_amount || '0.00',
      })) || [
        {
          stock_name: '',
          hsn_code: '',
          qty: '1',
          discount: {
            percentage_bool: '',
            percentage: '',
            amount: '',
          },
          mrp: '',
          gst: {
            cgst: {
              percentage_bool: '',
              percentage: '',
              amount: '',
            },
            sgst: {
              percentage_bool: '',
              percentage: '',
              amount: '',
            },
            igst: {
              percentage_bool: '',
              percentage: '',
              amount: '',
            },
            cess: {
              percentage_bool: '',
              percentage: '',
              amount: '',
            },
          },
          other_tax: {
            percentage_bool: '',
            percentage: '',
            amount: '',
          },
          total_amount: '0.00',
        },
      ],
      dispatch: {
        invoice_no: formValues?.dispatch?.invoice_no || '',
        doc_no: formValues?.dispatch?.doc_no || '',
        delivery_note: formValues?.dispatch?.delivery_note || '',
        destination: formValues?.dispatch?.destination || '',
        carrier_name: formValues?.dispatch?.carrier_name || '',
        bill_of_lading: formValues?.dispatch?.bill_of_lading || '',
        dispatch_date: formValues?.dispatch?.dispatch_date || '',
        motor: formValues?.dispatch?.motor || '',
        e_way_bill: formValues?.dispatch?.e_way_bill || '',
      },
    },
    validationSchema: Yup.object({
      // dispatch: Yup.object().shape({
      //   invoice_no: Yup.string().required('Invoice no. is required'),
      // }),
      my_company_details: Yup.object({
        gst: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/, 'Invalid GST number'),
      }),
      billing_address: Yup.object({
        gst: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/, 'Invalid GST number'),
      }),
      shipping_address: Yup.object({
        gst: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/, 'Invalid GST number'),
        // .when('billing_shipping_same', {
        //   is: 0, // Only validate when billing and shipping addresses are not the same
        //   otherwise: Yup.string().nullable(), // Skip validation if billing and shipping addresses are the same
        // }),
      }),
      stock_info: Yup.array().of(
        Yup.object().shape({
          hsn_code: Yup.string().matches(/^\d{1,6}$/, 'HSN code must be a valid number with up to 6 digits'),
        })
      ),
    }),
    onSubmit: async (values, helpers) => {
      setIsLoading(true);
      setErrorMessage('');
      try {
        const generateConditionalObject = (values) => {
          const result = {};

          // Discount handling
          if (values.discount?.percentage_bool === 1) {
            result.discount = {
              percentage_bool: values.discount.percentage_bool,
              percentage: values.discount.percentage || null,
            };
          } else if (values.discount?.percentage_bool === 0) {
            result.discount = {
              percentage_bool: values.discount.percentage_bool,
              amount: values.discount.amount || null,
            };
          } else {
            // console.log('here1');
            result.discount = null;
          }

          // GST handling
          result.gst = {};
          ['cgst', 'sgst', 'igst', 'cess'].forEach((taxType) => {
            if (values.gst?.[taxType]?.percentage_bool === 1) {
              result.gst[taxType] = {
                percentage_bool: values.gst[taxType].percentage_bool,
                percentage: values.gst[taxType].percentage || null,
              };
            } else if (values.gst?.[taxType]?.percentage_bool === 0) {
              result.gst[taxType] = {
                percentage_bool: values.gst[taxType].percentage_bool,
                amount: values.gst[taxType].amount || null,
              };
            } else {
              // console.log('here2');
              result.gst[taxType] = null;
            }
          });

          // Remove null GST objects
          Object.keys(result.gst).forEach((key) => {
            if (result.gst[key] === null) delete result.gst[key];
          });
          if (Object.keys(result.gst).length === 0) result.gst = null;

          // Other Tax handling
          if (values.other_tax?.percentage_bool === 1) {
            result.other_tax = {
              percentage_bool: values.other_tax.percentage_bool,
              percentage: values.other_tax.percentage || null,
            };
          } else if (values.other_tax?.percentage_bool === 0) {
            result.other_tax = {
              percentage_bool: values.other_tax.percentage_bool,
              amount: values.other_tax.amount || null,
            };
          } else {
            // console.log('here3');
            result.other_tax = null;
          }
          return result;
        };

        const totalGstDiscount = generateConditionalObject(values.total_gst_discount);

        const hasTotalGstDiscount = Object.keys(totalGstDiscount).some(
          (key) => totalGstDiscount[key] !== null && Object.keys(totalGstDiscount[key]).length > 0
        );

        const payload = {
          function_type: isCreate ? 'create_transaction_purchase' : 'alter_transaction_purchase',
          company_id: companyId || company,
          date: values.date,
          // ledgername: values.ledgername,
          transactiontype: values.transactiontype,
          narration: values.narration,
          my_company_details: {
            company_address: values.my_company_details.company_address,
            company_name: values.my_company_details.company_name,
            email: values.my_company_details.email,
            mobile: values.my_company_details.mobile,
            gst: values.my_company_details.gst,
            terms_conditions: values.my_company_details.terms_conditions,
          },
          billing_shipping_same: values.billing_shipping_same,
          billing_address: {
            company_address: values.billing_address.company_address,
            company_name: values.billing_address.company_name,
            email: values.billing_address.email,
            mobile: values.billing_address.mobile,
            gst: values.billing_address.gst,
          },
          shipping_address:
            values.billing_shipping_same === 1
              ? null
              : {
                  company_address: values.shipping_address.company_address,
                  company_name: values.shipping_address.company_name,
                  email: values.shipping_address.email,
                  mobile: values.shipping_address.mobile,
                  gst: values.shipping_address.gst,
                },
          total_gst_discount: hasTotalGstDiscount ? totalGstDiscount : null,
          stock_info: values.stock_info.map((stock) => ({
            ...stock,
            ...generateConditionalObject(stock),
          })),
          dispatch: {
            invoice_no: values.dispatch.invoice_no,
            doc_no: values.dispatch.doc_no,
            delivery_note: values.dispatch.delivery_note,
            destination: values.dispatch.destination,
            carrier_name: values.dispatch.carrier_name,
            bill_of_lading: values.dispatch.bill_of_lading,
            dispatch_date: values.dispatch.dispatch_date,
            motor: values.dispatch.motor,
            e_way_bill: values.dispatch.e_way_bill,
          },
          api_signature: API_SIGN,
          jwt_token: userToken,
        };
        if (!isCreate) {
          payload.transaction_id = transactionId;
        }
        const inputData = JSON.stringify(payload);
        console.log(inputData);
        // Make the API call
        setIsLoading(true);
        apiCall(inputData, 'POST', isCreate ? CREATE_PURCHASE_TRANSACTION : ALTER_PURCHASE_TRANSACTION, 'plain/text')
          .then((response) => {
            console.log(response);
            if (response.data.status === 1) {
              enqueueSnackbar(isCreate ? 'Purchase bill added successfully. ' : 'Purchase bill updated successfully.', {
                variant: 'success',
              });
              handleSuccessSubmit(saveGoBack, saveAddAnother);
            } else {
              setErrorMessage(response.data.response);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            console.log('Error adding purchase bill:', error.message);
            setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err) {
        helpers.setStatus({ success: false });
        setErrorMessage(err.message);
        helpers.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  const [isNewValue, setIsNewValue] = useState(false);

  const handleAutocompleteChange = (event, value, matchedOption, index) => {
    if (value) {
      formik.setFieldValue(`stock_info[${index}].stock_name`, value.stock_name);
      setIsNewValue(false); // Reset isNewValue if a value from options is selected
    } else {
      formik.setFieldValue(`stock_info[${index}].stock_name`, event.target.value);
      setIsNewValue(event.target.value.trim() !== ''); // Set isNewValue to true if a new value is typed
    }
    if (matchedOption) {
      formik.setFieldValue(`stock_info[${index}].stock_name`, matchedOption.stock_name);
      setIsNewValue(false);
    }
  };

  const calculateGrandTotal = () => {
    let grandTotal = 0;
  
    formik.values.stock_info.forEach(element => {
      let stockBase = element.mrp * element.qty;
  
      if (element.discount && element.discount.percentage_bool !== '') {
        if (element.discount.percentage_bool === 1) {
          stockBase -= (stockBase * parseFloat(element.discount.percentage) / 100);
        } else {
          stockBase -= parseFloat(element.discount.amount);
        }
      }
  
      let taxCalculated = 0;
  
      if (element.gst && element.gst.cgst?.percentage_bool !== '') {
        if (element.gst.cgst.percentage_bool === 1) {
          taxCalculated += (stockBase * parseFloat(element.gst.cgst.percentage) / 100);
        } else {
          taxCalculated += parseFloat(element.gst.cgst.amount);
        }
      }
  
      if (element.gst && element.gst.sgst?.percentage_bool !== '') {
        if (element.gst.sgst.percentage_bool === 1) {
          taxCalculated += (stockBase * parseFloat(element.gst.sgst.percentage) / 100);
        } else {
          taxCalculated += parseFloat(element.gst.sgst.amount);
        }
      }
  
      if (element.gst && element.gst.igst?.percentage_bool !== '') {
        if (element.gst.igst.percentage_bool === 1) {
          taxCalculated += (stockBase * parseFloat(element.gst.igst.percentage) / 100);
        } else {
          taxCalculated += parseFloat(element.gst.igst.amount);
        }
      }
  
      if (element.gst && element.gst.cess?.percentage_bool !== '') {
        if (element.gst.cess.percentage_bool === 1) {
          taxCalculated += (stockBase * parseFloat(element.gst.cess.percentage) / 100);
        } else {
          taxCalculated += parseFloat(element.gst.cess.amount);
        }
      }
  
      if (element.other_tax && element.other_tax.percentage_bool !== '') {
        if (element.other_tax.percentage_bool === 1) {
          taxCalculated += (stockBase * parseFloat(element.other_tax.percentage) / 100);
        } else {
          taxCalculated += parseFloat(element.other_tax.amount);
        }
      }
  
      grandTotal += stockBase + taxCalculated;
    });
  
    if (formik.values.total_gst_discount?.discount?.percentage_bool !== '') {
      if (formik.values.total_gst_discount.discount.percentage_bool === 1) {
        grandTotal -= (grandTotal * parseFloat(formik.values.total_gst_discount.discount.percentage) / 100);
      } else {
        grandTotal -= parseFloat(formik.values.total_gst_discount.discount.amount);
      }
    }
  
    let taxCalculated = 0;
  
    if (formik.values.total_gst_discount?.gst?.cgst?.percentage_bool !== '') {
      if (formik.values.total_gst_discount.gst.cgst.percentage_bool === 1) {
        taxCalculated += (grandTotal * parseFloat(formik.values.total_gst_discount.gst.cgst.percentage) / 100);
      } else {
        taxCalculated += parseFloat(formik.values.total_gst_discount.gst.cgst.amount);
      }
    }
  
    if (formik.values.total_gst_discount?.gst?.sgst?.percentage_bool !== '') {
      if (formik.values.total_gst_discount.gst.sgst.percentage_bool === 1) {
        taxCalculated += (grandTotal * parseFloat(formik.values.total_gst_discount.gst.sgst.percentage) / 100);
      } else {
        taxCalculated += parseFloat(formik.values.total_gst_discount.gst.sgst.amount);
      }
    }
  
    if (formik.values.total_gst_discount?.gst?.igst?.percentage_bool !== '') {
      if (formik.values.total_gst_discount.gst.igst.percentage_bool === 1) {
        taxCalculated += (grandTotal * parseFloat(formik.values.total_gst_discount.gst.igst.percentage) / 100);
      } else {
        taxCalculated += parseFloat(formik.values.total_gst_discount.gst.igst.amount);
      }
    }
  
    if (formik.values.total_gst_discount?.gst?.cess?.percentage_bool !== '') {
      if (formik.values.total_gst_discount.gst.cess.percentage_bool === 1) {
        taxCalculated += (grandTotal * parseFloat(formik.values.total_gst_discount.gst.cess.percentage) / 100);
      } else {
        taxCalculated += parseFloat(formik.values.total_gst_discount.gst.cess.amount);
      }
    }
  
    if (formik.values.total_gst_discount?.other_tax?.percentage_bool !== '') {
      if (formik.values.total_gst_discount.other_tax.percentage_bool === 1) {
        taxCalculated += (grandTotal * parseFloat(formik.values.total_gst_discount.other_tax.percentage) / 100);
      } else {
        taxCalculated += parseFloat(formik.values.total_gst_discount.other_tax.amount);
      }
    }
  
    grandTotal += taxCalculated;
  
    return indianFormatNumber(parseFloat(grandTotal).toFixed(2));
  };

  const handleAmountCalculation = (index, newQty, newMRP) => {
    const row = formik.values.stock_info[index];

    let qty = 0;
    let mrp = 0;

    if (newQty === null) {
      qty = parseFloat(row.qty) || 1;
      mrp = newMRP !== '' && newMRP !== null ? parseFloat(newMRP) : 0;
    } else if (newMRP === null) {
      qty = newQty !== '' && newQty !== null ? parseFloat(newQty) : 1;
      mrp = parseFloat(row.mrp) || 0;
    }
    const amount = qty * mrp;
    formik.setFieldValue(`stock_info[${index}].total_amount`, amount.toFixed(2));
  };

  const handleDialogGSTDiscountAllClose = () => {
    formik.values.stock_info.forEach((item, index) => {
      formik.setFieldValue(`stock_info[${index}].discount`, null);
      formik.setFieldValue(`stock_info[${index}].gst`, null);
      formik.setFieldValue(`stock_info[${index}].other_tax`, null);
    });
    setGstDiscountDialogAllOpen(false);
  };
  
  const totalBaseAmount = formik.values.stock_info.reduce((acc, row) => acc + (parseFloat(row.total_amount) || 0), 0);

  const isEmptyDiscountObject = (discountObject) => {
    return (
      !discountObject ||
      (Object.keys(discountObject.discount).every((key) => !discountObject.discount[key]) &&
        Object.keys(discountObject.gst).every((gstKey) =>
          Object.keys(discountObject.gst[gstKey]).every((key) => !discountObject.gst[gstKey][key])
        ) &&
        !discountObject.other_tax) ||
      Object.keys(discountObject.other_tax).every((key) => !discountObject.other_tax[key])
    );
  };

  return (
    <>
      <Helmet>
        <title>Transactions | Rejoice Diary</title>
      </Helmet>
      <Box>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="outlined"
                label="Date"
                name="date"
                type="date"
                error={!!(formik.touched.date && formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginBottom: '10px' }}
                value={formik.values.date}
              />
            </Grid>

            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>My Company Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Company Name"
                      name="my_company_details.company_name"
                      error={
                        !!(
                          formik.touched.my_company_details?.company_name &&
                          formik.errors.my_company_details?.company_name
                        )
                      }
                      helperText={
                        formik.touched.my_company_details?.company_name &&
                        formik.errors.my_company_details?.company_name
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.company_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Company Address"
                      name="my_company_details.company_address"
                      error={
                        !!(
                          formik.touched.my_company_details?.company_address &&
                          formik.errors.my_company_details?.company_address
                        )
                      }
                      helperText={
                        formik.touched.my_company_details?.company_address &&
                        formik.errors.my_company_details?.company_address
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.company_address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Company Email"
                      name="my_company_details.email"
                      error={!!(formik.touched.my_company_details?.email && formik.errors.my_company_details?.email)}
                      helperText={formik.touched.my_company_details?.email && formik.errors.my_company_details?.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MuiTelInput
                      label="Mobile"
                      name="my_company_details.mobile"
                      type="mobile"
                      fullWidth
                      onChange={(e) => {
                        mobileChangeCompany(e);
                        formik.setFieldValue('my_company_details.mobile', e.replace(/\s/g, ''));
                      }}
                      error={!!(formik.touched.my_company_details?.mobile && formik.errors.my_company_details?.mobile)}
                      helperText={formik.touched.my_company_details?.mobile && formik.errors.my_company_details?.mobile}
                      onBlur={formik.handleBlur}
                      defaultCountry="IN"
                      sx={{ marginBottom: '15px' }}
                      value={mobileCompany}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="GSTIN"
                      name="my_company_details.gst"
                      error={!!(formik.touched.my_company_details?.gst && formik.errors.my_company_details?.gst)}
                      helperText={formik.touched.my_company_details?.gst && formik.errors.my_company_details?.gst}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.my_company_details?.gst}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Billing and Shipping Address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Billing Address Fields */}
                <Typography>Billing Address</Typography>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} sm={6} md={6}>
                  <Autocomplete
                      options={ledgerDetails}
                      freeSolo
                      fullWidth
                      getOptionLabel={(option) => option?.ledgername || ''}
                      onChange={(e, value) => {
                        const selectedLedgerName = value?.ledgername || '';
                        if (selectedLedgerName) {
                          setLedgerName(selectedLedgerName);
                          fetchLastBillingShippingData();
                        } 
                      }}
                      onInputChange={(e, newInputValue) => {
                        if (!newInputValue) {
                          formik.setFieldValue('billing_address.company_name', '');
                          formik.setFieldValue('billing_address.company_address', '');
                          formik.setFieldValue('billing_address.email', '');
                          formik.setFieldValue('billing_address.mobile', '');
                          formik.setFieldValue('billing_address.gst', '');
                          formik.setFieldValue('shipping_address.company_name', '');
                          formik.setFieldValue('shipping_address.company_address', '');
                          formik.setFieldValue('shipping_address.email', '');
                          formik.setFieldValue('shipping_address.mobile', '');
                          formik.setFieldValue('shipping_address.gst', '');
                        } else {
                          formik.setFieldValue('billing_address.company_name', newInputValue);
                        }
                      }}
                      value={
                        ledgerDetails?.find(
                          (ledger) => ledger?.ledgername === formik.values.billing_address?.company_name
                        ) || null
                      }
                      loading={isLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Billing Name"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '10px' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing Address"
                      name="billing_address.company_address"
                      value={formik.values.billing_address?.company_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={
                        !!(
                          formik.touched.billing_address?.company_address &&
                          formik.errors.billing_address?.company_address
                        )
                      }
                      helperText={
                        formik.touched.billing_address?.company_address &&
                        formik.errors.billing_address?.company_address
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing Email"
                      name="billing_address.email"
                      value={formik.values.billing_address?.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={!!(formik.touched.billing_address?.email && formik.errors.billing_address?.email)}
                      helperText={formik.touched.billing_address?.email && formik.errors.billing_address?.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MuiTelInput
                      label="Billing Mobile No."
                      name="billing_address.mobile"
                      type="mobile"
                      fullWidth
                      onChange={(e) => {
                        mobileChangeBilling(e);
                        formik.setFieldValue('billing_address.mobile', e.replace(/\s/g, ''));
                      }}
                      error={!!(formik.touched.billing_address?.mobile && formik.errors.billing_address?.mobile)}
                      helperText={formik.touched.billing_address?.mobile && formik.errors.billing_address?.mobile}
                      onBlur={formik.handleBlur}
                      defaultCountry="IN"
                      sx={{ marginBottom: '15px' }}
                      value={mobileBilling}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Billing GST"
                      name="billing_address.gst"
                      value={formik.values.billing_address?.gst}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      error={!!(formik.touched.billing_address?.gst && formik.errors.billing_address?.gst)}
                      helperText={formik.touched.billing_address?.gst && formik.errors.billing_address?.gst}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="billing_shipping_same"
                          checked={formik.values.billing_shipping_same === 1}
                          onChange={(event) =>
                            formik.setFieldValue('billing_shipping_same', event.target.checked ? 1 : 0)
                          }
                          error={!!(formik.touched.billing_shipping_same && formik.errors.billing_shipping_same)}
                          helperText={formik.touched.billing_shipping_same && formik.errors.billing_shipping_same}
                        />
                      }
                      label="Billing & Shipping Address Same"
                    />
                  </Grid>

                  {/* Shipping Address Fields */}
                  {formik.values.billing_shipping_same === 0 && (
                    <>
                      <Grid item xs={12} sm={12} md={12} mb={1}>
                        <Typography mt={2}>Shipping Address</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping Name"
                          name="shipping_address.company_name"
                          value={formik.values.shipping_address?.company_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={
                            !!(
                              formik.touched.shipping_address?.company_name &&
                              formik.errors.shipping_address?.company_name
                            )
                          }
                          helperText={
                            formik.touched.shipping_address?.company_name &&
                            formik.errors.shipping_address?.company_name
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping Address"
                          name="shipping_address.company_address"
                          value={formik.values.shipping_address?.company_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={
                            !!(
                              formik.touched.shipping_address?.company_address &&
                              formik.errors.shipping_address?.company_address
                            )
                          }
                          helperText={
                            formik.touched.shipping_address?.company_address &&
                            formik.errors.shipping_address?.company_address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping Email"
                          name="shipping_address.email"
                          value={formik.values.shipping_address?.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={!!(formik.touched.shipping_address?.email && formik.errors.shipping_address?.email)}
                          helperText={formik.touched.shipping_address?.email && formik.errors.shipping_address?.email}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <MuiTelInput
                          label="Shipping Mobile No."
                          name="shipping_address.mobile"
                          type="mobile"
                          fullWidth
                          onChange={(e) => {
                            mobileChangeShipping(e);
                            formik.setFieldValue('shipping_address.mobile', e.replace(/\s/g, ''));
                          }}
                          error={!!(formik.touched.shipping_address?.mobile && formik.errors.shipping_address?.mobile)}
                          helperText={formik.touched.shipping_address?.mobile && formik.errors.shipping_address?.mobile}
                          onBlur={formik.handleBlur}
                          defaultCountry="IN"
                          sx={{ marginBottom: '15px' }}
                          value={mobileShipping}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Shipping GST"
                          name="shipping_address.gst"
                          value={formik.values.shipping_address?.gst}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ marginBottom: '15px' }}
                          error={!!(formik.touched.shipping_address?.gst && formik.errors.shipping_address?.gst)}
                          helperText={formik.touched.shipping_address?.gst && formik.errors.shipping_address?.gst}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Dispatch Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Invoice No."
                      name="dispatch.invoice_no"
                      error={!!(formik.touched.dispatch?.invoice_no && formik.errors.dispatch?.invoice_no)}
                      helperText={formik.touched.dispatch?.invoice_no && formik.errors.dispatch?.invoice_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.invoice_no}
                      // required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Delivery Note"
                      name="dispatch.delivery_note"
                      error={!!(formik.touched.dispatch?.delivery_note && formik.errors.dispatch?.delivery_note)}
                      helperText={formik.touched.dispatch?.delivery_note && formik.errors.dispatch?.delivery_note}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.delivery_note}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Dispatch Doc No."
                      name="dispatch.doc_no"
                      error={!!(formik.touched.dispatch?.doc_no && formik.errors.dispatch?.doc_no)}
                      helperText={formik.touched.dispatch?.doc_no && formik.errors.dispatch?.doc_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.doc_no}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Destination"
                      name="dispatch.destination"
                      error={!!(formik.touched.dispatch?.destination && formik.errors.dispatch?.destination)}
                      helperText={formik.touched.dispatch?.destination && formik.errors.dispatch?.destination}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.destination}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Carrier name/Agent"
                      name="dispatch.carrier_name"
                      error={!!(formik.touched.dispatch?.carrier_name && formik.errors.dispatch?.carrier_name)}
                      helperText={formik.touched.dispatch?.carrier_name && formik.errors.dispatch?.carrier_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.carrier_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Bill of Lading/LR-RR No."
                      name="dispatch.bill_of_lading"
                      error={!!(formik.touched.dispatch?.bill_of_lading && formik.errors.dispatch?.bill_of_lading)}
                      helperText={formik.touched.dispatch?.bill_of_lading && formik.errors.dispatch?.bill_of_lading}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.bill_of_lading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Dispatch Date"
                      name="dispatch.dispatch_date"
                      type="date"
                      error={!!(formik.touched.dispatch?.dispatch_date && formik.errors.dispatch?.dispatch_date)}
                      helperText={formik.touched.dispatch?.dispatch_date && formik.errors.dispatch?.dispatch_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '10px' }}
                      value={formik.values.dispatch?.dispatch_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      variant="outlined"
                      label="Motor Vehicle No."
                      name="dispatch.motor"
                      error={!!(formik.touched.dispatch?.motor && formik.errors.dispatch?.motor)}
                      helperText={formik.touched.dispatch?.motor && formik.errors.dispatch?.motor}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginBottom: '15px' }}
                      value={formik.values.dispatch?.motor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography mb={2}>GST Details</Typography>
                    <TextField
                      variant="outlined"
                      label="Eway Bill Number"
                      name="dispatch.e_way_bill"
                      error={!!(formik.touched.dispatch?.e_way_bill && formik.errors.dispatch?.e_way_bill)}
                      helperText={formik.touched.dispatch?.e_way_bill && formik.errors.dispatch?.e_way_bill}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formik.values.dispatch?.e_way_bill}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ width: '100%' }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Item Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '40%' }}>Name of Item</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>HSN Code</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Qty</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>MRP</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>Amount</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>GST & Discount</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values?.stock_info?.map((row, index) => {
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell size="small" style={{ ...cellStyle, textAlign: 'right' }}>
                                <Autocomplete
                                  options={stockDetails}
                                  fullWidth
                                  freeSolo
                                  size="small"
                                  name={`stock_info[${index}].stock_name`}
                                  loading={isLoading}
                                  getOptionLabel={(option) => option?.item_name || ''}
                                  value={
                                    stockDetails.find(
                                      (item) => item.item_name === formik.values.stock_info[index]?.stock_name
                                    ) || null
                                  }
                                  onInputChange={(e, newInputValue) => {
                                    setSearchString(newInputValue);
                                    fetchStock(newInputValue);
                                  }}
                                  onChange={(e, value) => {
                                    formik.setFieldValue(
                                      `stock_info[${index}].stock_name`,
                                      value ? value.item_name : ''
                                    );
                                    formik.setFieldValue(`stock_info[${index}].hsn_code`, value ? value.hsn_code : '');
                                    // formik.setFieldValue(`stock_info[${ index }].qty`, value ? value.qty : '1');
                                    formik.setFieldValue(
                                      `stock_info[${index}].current_qty`,
                                      value ? value.current_qty : ''
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      fullWidth
                                      type="text"
                                      onBlur={formik.handleBlur}
                                      InputProps={{
                                        ...params.InputProps,
                                        onChange: (event) => {
                                          const newValue = event.target.value;
                                          const matchedOption = stockDetails.find(
                                            (option) => option.item_name === newValue
                                          );
                                          handleAutocompleteChange(event, null, matchedOption, index);
                                        },
                                      }}
                                    />
                                  )}
                                />
                                {formik.values.stock_info[index]?.current_qty ? (
                                  <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px' }}>
                                    Quantity: {formik.values.stock_info[index].current_qty}
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell size="small" style={cellStyle}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  error={
                                    formik.touched.stock_info?.[index]?.hsn_code &&
                                    !!formik.errors.stock_info?.[index]?.hsn_code
                                  }
                                  helperText={
                                    formik.touched.stock_info?.[index]?.hsn_code &&
                                    formik.errors.stock_info?.[index]?.hsn_code
                                  }
                                  value={row.hsn_code || ''}
                                  onChange={(e) => {
                                    let inputValue = e.target.value.substring(0, 6);
                                    formik.setFieldValue(`stock_info[${index}].hsn_code`, inputValue);
                                  }}
                                  fullWidth
                                />
                                {formik.values.stock_info[index]?.current_qty ? '\u00A0' : null}
                              </TableCell>
                              <TableCell size="small" style={cellStyle}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  value={row.qty || ''}
                                  onChange={(e) => {
                                    formik.setFieldValue(`stock_info[${index}].qty`, e.target.value);
                                    handleAmountCalculation(index, e.target.value, null); // Calculate amount on qty change
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: 'left' },
                                    },
                                  }}
                                  fullWidth
                                />
                                {formik.values.stock_info[index]?.current_qty ? '\u00A0' : null}
                              </TableCell>
                              <TableCell size="small" style={cellStyle}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={row.mrp || ''}
                                  onChange={(e) => {
                                    formik.setFieldValue(`stock_info[${index}].mrp`, e.target.value);
                                    handleAmountCalculation(index, null, e.target.value); // Calculate amount on mrp change
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: 'right' },
                                    },
                                  }}
                                  fullWidth
                                />
                                {formik.values.stock_info[index]?.current_qty ? '\u00A0' : null}
                              </TableCell>
                              <TableCell size="small" style={cellStyle}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  value={parseFloat(row.total_amount).toFixed(2) || ''}
                                  InputProps={{
                                    inputProps: {
                                      style: { textAlign: 'right' },
                                    },
                                  }}
                                  fullWidth
                                  disabled
                                />
                                {formik.values.stock_info[index]?.current_qty ? '\u00A0' : null}
                              </TableCell>
                              <TableCell size="small" style={{ padding: 3, textAlign: 'right' }}>
                                <Button
                                  onClick={() => {
                                    setGstDiscountDialog(index);
                                  }}
                                  size={'small'}
                                >
                                  Manage
                                </Button>
                                {formik.values.stock_info[index]?.current_qty ? '\u00A0' : null}

                                {/* // per item gst dialog */}
                                <DialogGSTDiscountPurchase
                                  open={gstDiscountDialog !== false}
                                  index={gstDiscountDialog}
                                  rowValues={
                                    gstDiscountDialog !== false ? formik.values.stock_info[gstDiscountDialog] : {}
                                  }
                                  setFieldValue={formik.setFieldValue}
                                  companyId={companyId || company}
                                  userToken={userToken}
                                  closeDialog={setGstDiscountDialog}
                                  handleInputChange={handleInputChange}
                                  // onTotalCalculated={(total) => handleTotalCalculated(gstDiscountDialog, total)}
                                />
                              </TableCell>
                              <TableCell size="small" style={{ textAlign: 'right' }}>
                                <IconButton size="small" onClick={() => handleRemoveRow(index)}>
                                  <Delete fontSize="15px" />
                                </IconButton>
                              </TableCell>
                            </TableRow>

                            {/* Display applied GST, Discount, and Other Tax for each item */}
                            {isEmptyDiscountObject(formik.values?.total_gst_discount) ? (
                              <TableRow>
                                <TableCell />
                                <TableCell
                                  size="small"
                                  style={{ ...cellStyle, textAlign: 'right', fontStyle: 'italic' }}
                                >
                                  <div style={{ marginTop: '4px' }}>
                                    {row?.discount && (row.discount.percentage || row.discount.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        Discount
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.cgst && (row.gst.cgst.percentage || row.gst.cgst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        CGST
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.sgst && (row.gst.sgst.percentage || row.gst.sgst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        SGST
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.igst && (row.gst.igst.percentage || row.gst.igst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        IGST
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.cess && (row.gst.cess.percentage || row.gst.cess.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        CESS
                                      </Typography>
                                    ) : null}

                                    {row?.other_tax && (row.other_tax.percentage || row.other_tax.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        Other Tax
                                      </Typography>
                                    ) : null}
                                  </div>
                                </TableCell>
                                <TableCell
                                  size="small"
                                  style={{ ...cellStyle, textAlign: 'right', fontStyle: 'italic' }}
                                >
                                  <div style={{ marginTop: '4px' }}>
                                    {row?.discount && (row.discount.percentage || row.discount.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.discount.percentage_bool
                                          ? `${parseFloat(row.discount.percentage).toFixed(2)
                                          }% `
                                          : null}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.cgst && (row.gst.cgst.percentage || row.gst.cgst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.cgst.percentage_bool
                                          ? `${parseFloat(row.gst.cgst.percentage).toFixed(2)
                                          }% `
                                          : null}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.sgst && (row.gst.sgst.percentage || row.gst.sgst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.sgst.percentage_bool
                                          ? `${parseFloat(row.gst.sgst.percentage).toFixed(2)}% `
                                          : null}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.igst && (row.gst.igst.percentage || row.gst.igst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.igst.percentage_bool
                                          ? `${parseFloat(row.gst.igst.percentage).toFixed(2)}% `
                                          : null}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.cess && (row.gst.cess.percentage || row.gst.cess.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.cess.percentage_bool
                                          ? `${parseFloat(row.gst.cess.percentage).toFixed(2)}% `
                                          : null}
                                      </Typography>
                                    ) : null}

                                    {row?.other_tax && (row.other_tax.percentage || row.other_tax.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.other_tax.percentage_bool
                                          ? `${parseFloat(row.other_tax.percentage).toFixed(2)}% `
                                          : null}
                                      </Typography>
                                    ) : null}
                                  </div>
                                </TableCell>
                                <TableCell
                                  size="small"
                                  style={{ ...cellStyle, textAlign: 'right', fontStyle: 'italic' }}
                                >
                                  <div style={{ marginTop: '4px' }}>
                                    {row?.discount && (row.discount.percentage || row.discount.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.discount.percentage_bool === 1
                                          ? `₹${indianFormatNumber(
                                            (row.qty * row.mrp * row.discount.percentage) / 100
                                          )
                                          } `
                                          : `₹${indianFormatNumber(row.discount.amount)} `}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.cgst && (row.gst.cgst.percentage || row.gst.cgst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.cgst.percentage_bool
                                          ? `${indianFormatNumber((row.qty * row.mrp * row.gst.cgst.percentage) / 100)} `
                                          : `₹${indianFormatNumber(row.gst.cgst.amount)} `}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.sgst && (row.gst.sgst.percentage || row.gst.sgst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.sgst.percentage_bool
                                          ? `${indianFormatNumber((row.qty * row.mrp * row.gst.sgst.percentage) / 100)} `
                                          : `₹${indianFormatNumber(row.gst.sgst.amount)} `}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.igst && (row.gst.igst.percentage || row.gst.igst.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.igst.percentage_bool
                                          ? `${indianFormatNumber((row.qty * row.mrp * row.gst.igst.percentage) / 100)} `
                                          : `₹${indianFormatNumber(row.gst.igst.amount)} `}
                                      </Typography>
                                    ) : null}

                                    {row?.gst?.cess && (row.gst.cess.percentage || row.gst.cess.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.gst.cess.percentage_bool
                                          ? `${indianFormatNumber((row.qty * row.mrp * row.gst.cess.percentage) / 100)} `
                                          : `₹${indianFormatNumber(row.gst.cess.amount)} `}
                                      </Typography>
                                    ) : null}

                                    {row?.other_tax && (row.other_tax.percentage || row.other_tax.amount) ? (
                                      <Typography variant="body2" color="textSecondary">
                                        {row.other_tax.percentage_bool
                                          ? `${indianFormatNumber(
                                            (row.qty * row.mrp * row.other_tax.percentage) / 100
                                          )
                                          } `
                                          : `₹${indianFormatNumber(row.other_tax.amount)} `}
                                      </Typography>
                                    ) : null}
                                  </div>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                              </TableRow>
                            ) : null}
                          </>
                        );
                      })}

                      {/* Display applied GST, Discount, and Other Tax for ALL items */}
                      {formik.values?.total_gst_discount ? (
                        <TableRow>
                          <TableCell />
                          <TableCell
                            size="small"
                            style={{ ...cellStyle, textAlign: 'right', fontStyle: 'italic', paddingTop: 20 }}
                          >
                            <div style={{ marginTop: '4px' }}>
                              {formik.values?.total_gst_discount?.discount &&
                                (formik.values?.total_gst_discount?.discount?.percentage ||
                                  formik.values?.total_gst_discount?.discount?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  Discount
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.cgst &&
                                (formik.values?.total_gst_discount?.gst?.cgst?.percentage ||
                                  formik.values?.total_gst_discount?.gst?.cgst?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  CGST
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.sgst &&
                                (formik.values?.total_gst_discount?.gst?.sgst?.percentage ||
                                  formik.values?.total_gst_discount?.gst?.sgst?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  SGST
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.igst &&
                                (formik.values?.total_gst_discount?.gst?.igst?.percentage ||
                                  formik.values?.total_gst_discount?.gst?.igst?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  IGST
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.cess &&
                                (formik.values?.total_gst_discount?.gst?.cess?.percentage ||
                                  formik.values?.total_gst_discount?.gst?.cess?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  CESS
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.other_tax &&
                                (formik.values?.total_gst_discount?.other_tax?.percentage ||
                                  formik.values?.total_gst_discount?.other_tax?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  Other Tax
                                </Typography>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell
                            size="small"
                            style={{ ...cellStyle, textAlign: 'right', fontStyle: 'italic', paddingTop: 20 }}
                          >
                            <div style={{ marginTop: '4px' }}>
                              {formik.values?.total_gst_discount?.discount &&
                                (formik.values?.total_gst_discount?.discount?.percentage ||
                                  formik.values?.total_gst_discount?.discount?.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.discount.percentage_bool
                                    ? `${parseFloat(formik.values.total_gst_discount.discount.percentage).toFixed(2)}% `
                                    : null}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.cgst &&
                                (formik.values?.total_gst_discount.gst.cgst.percentage ||
                                  formik.values?.total_gst_discount.gst.cgst.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.cgst.percentage_bool
                                    ? `${parseFloat(formik.values.total_gst_discount.gst.cgst.percentage).toFixed(2)}% `
                                    : null}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.sgst &&
                                (formik.values?.total_gst_discount.gst.sgst.percentage ||
                                  formik.values?.total_gst_discount.gst.sgst.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.sgst.percentage_bool
                                    ? `${parseFloat(formik.values.total_gst_discount.gst.sgst.percentage).toFixed(2)}% `
                                    : null}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.igst &&
                                (formik.values.total_gst_discount.gst.igst.percentage ||
                                  formik.values.total_gst_discount.gst.igst.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.igst.percentage_bool
                                    ? `${parseFloat(formik.values.total_gst_discount.gst.igst.percentage).toFixed(2)}% `
                                    : null}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.cess &&
                                (formik.values.total_gst_discount.gst.cess.percentage ||
                                  formik.values.total_gst_discount.gst.cess.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.cess.percentage_bool
                                    ? `${parseFloat(formik.values.total_gst_discount.gst.cess.percentage).toFixed(2)}% `
                                    : null}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.other_tax &&
                                (formik.values.total_gst_discount.other_tax.percentage ||
                                  formik.values.total_gst_discount.other_tax.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.other_tax.percentage_bool
                                    ? `${parseFloat(formik.values.total_gst_discount.other_tax.percentage).toFixed(2)}% `
                                    : null}
                                </Typography>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell
                            size="small"
                            style={{ ...cellStyle, textAlign: 'right', fontStyle: 'italic', paddingTop: 20 }}
                          >
                            <div style={{ marginTop: '4px' }}>
                              {formik.values?.total_gst_discount?.discount &&
                                (formik.values.total_gst_discount.discount.percentage ||
                                  formik.values.total_gst_discount.discount.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.discount.percentage_bool === 1
                                    ? `₹${indianFormatNumber(
                                      (totalBaseAmount * formik.values.total_gst_discount.discount.percentage) / 100
                                    )
                                    } `
                                    : `₹${indianFormatNumber(formik.values.total_gst_discount.discount.amount)} `}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.cgst &&
                                (formik.values.total_gst_discount.gst.cgst.percentage ||
                                  formik.values.total_gst_discount.gst.cgst.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.cgst.percentage_bool
                                    ? `${indianFormatNumber(
                                      (totalBaseAmount * formik.values.total_gst_discount.gst.cgst.percentage) / 100
                                    )
                                    } `
                                    : `₹${indianFormatNumber(formik.values.total_gst_discount.gst.cgst.amount)} `}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.sgst &&
                                (formik.values.total_gst_discount.gst.sgst.percentage ||
                                  formik.values.total_gst_discount.gst.sgst.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.sgst.percentage_bool
                                    ? `${indianFormatNumber(
                                      (totalBaseAmount * formik.values.total_gst_discount.gst.sgst.percentage) / 100
                                    )
                                    } `
                                    : `₹${indianFormatNumber(formik.values.total_gst_discount.gst.sgst.amount)} `}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.igst &&
                                (formik.values.total_gst_discount.gst.igst.percentage ||
                                  formik.values.total_gst_discount.gst.igst.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.igst.percentage_bool
                                    ? `${indianFormatNumber(
                                      (totalBaseAmount * formik.values.total_gst_discount.gst.igst.percentage) / 100
                                    )
                                    } `
                                    : `₹${indianFormatNumber(formik.values.total_gst_discount.gst.igst.amount)} `}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.gst?.cess &&
                                (formik.values.total_gst_discount.gst.cess.percentage ||
                                  formik.values.total_gst_discount.gst.cess.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.gst.cess.percentage_bool
                                    ? `${indianFormatNumber(
                                      (totalBaseAmount * formik.values.total_gst_discount.gst.cess.percentage) / 100
                                    )
                                    } `
                                    : `₹${indianFormatNumber(formik.values.total_gst_discount.gst.cess.amount)} `}
                                </Typography>
                              ) : null}

                              {formik.values?.total_gst_discount?.other_tax &&
                                (formik.values.total_gst_discount.other_tax.percentage ||
                                  formik.values.total_gst_discount.other_tax.amount) ? (
                                <Typography variant="body2" color="textSecondary">
                                  {formik.values.total_gst_discount.other_tax.percentage_bool
                                    ? `${indianFormatNumber(
                                      (totalBaseAmount * formik.values.total_gst_discount.other_tax.percentage) / 100
                                    )
                                    } `
                                    : `₹${indianFormatNumber(formik.values.total_gst_discount.other_tax.amount)} `}
                                </Typography>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      ) : null}
                    </TableBody>

                    {/* Display Grand Total */}
                    <TableFooter>
                      <TableRow>
                        <TableCell style={{ textAlign: 'right' }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Total :
                          </Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {calculateGrandTotal()}
                          </Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableFooter>

                    <Grid container justifyContent="flex-start" padding={2}>
                      <Button size="small" variant="contained" color="secondary" onClick={handleAddRow}>
                        Add Row
                      </Button>
                    </Grid>
                    <Grid container justifyContent="flex-start">
                      <Button size="small" variant="text" onClick={() => setGstDiscountDialogAllOpen(true)}>
                        Apply GST & Discount to All
                      </Button>

                      {/* Dialog for applying GST, Discount to all items */}
                      <DialogGSTDiscountAllPurchase
                        open={gstDiscountDialogAllOpen}
                        stockInfo={formik.values.stock_info}
                        totalGSTdiscount={formik.values.total_gst_discount}
                        setFieldValue={formik.setFieldValue}
                        closeDialog={handleDialogGSTDiscountAllClose}
                        // onTotalApplied={(total) => setCommonTotalAmount(total)}
                      />
                    </Grid>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Grid item xs={12} sm={12} md={12} mt={2}>
              <TextField
                variant="outlined"
                label="Narration"
                name="narration"
                multiline
                rows={2}
                error={!!(formik.touched.narration && formik.errors.narration)}
                helperText={formik.touched.narration && formik.errors.narration}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginBottom: '10px' }}
                value={formik.values.narration}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                variant="outlined"
                label="Terms & Condition"
                name="my_company_details.terms_conditions"
                multiline
                rows={5}
                error={
                  !!(
                    formik.touched.my_company_details?.terms_conditions &&
                    formik.errors.my_company_details?.terms_conditions
                  )
                }
                helperText={
                  formik.touched.my_company_details?.terms_conditions &&
                  formik.errors.my_company_details?.terms_conditions
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginBottom: '10px' }}
                value={formik.values.my_company_details?.terms_conditions}
              />
            </Grid>

            <CustomAddButton
              btnType={'submit'}
              buttonTitle={isCreate ? 'Add' : 'Update'}
              variant="contained"
              disabled={false}
              styleProps={{ margin: '20px 0px' }}
              onClick={null}
              isLoading={isLoading}
            />
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default AddPurchaseBill;