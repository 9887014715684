import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Box,
  Stack,
  useMediaQuery,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { decryptToken } from '../../components/security/securityFunctions';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';
import DashboardStats from './DashboardStats';
import DisplayReminders from '../reminders/DisplayReminders';


const DashboardHome = () => {
  const navigate = useNavigate();
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
  console.log(userToken)

  useEffect(() => {
    if (userToken.workflow_type === 'individual') {
      navigate(`/dashboard/companyDashboard?company_id=${userToken.company_id}`);
    }
  }, [userToken, navigate]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Rejoice Diary</title>
      </Helmet>

      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path={'/dashboard/home'} />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <TitleDisplay title={'Dashboard'} />
            </Stack>
            <Box sx={{ margin: '20px 0px', borderBottom: 'solid thin #f7f7f7' }}>
              <DashboardStats userToken={userToken} defaultCompany="all" />
            </Box>
            <Box sx={{ margin: '20px 0px 10px 5px' }}>
              <Typography variant="h4" fontWeight="600" gutterBottom>
                Reminders
              </Typography>
              <DisplayReminders />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default DashboardHome;
