import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Box, Divider } from '@mui/material';
import { decryptToken, encryptToken } from '../security/securityFunctions';
import { fetchCompany } from '../cookies/cookieFetchFunctions';
import palette from '../../theme/palette';

const CompanyTitle = ({ justText }) => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const urlDetailing =
    decryptToken(Cookies.get('url_detailing_rejoice_daybook')) &&
    decryptToken(Cookies.get('url_detailing_rejoice_daybook')) !== ''
      ? JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')))
      : {};
  const [loading, setLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    // get cookie and compare
    setLoading(true);
    getCompanyData();
  }, []);

  const fetchCompanyAndSetCookie = async (company_id, userToken) => {
    try {
      const response = await fetchCompany(company_id, userToken);
      console.log(response);
      if (response && response.data.status === 1) {
        const companyData = response.data.response.data;
        if (
          decryptToken(Cookies.get('url_detailing_rejoice_daybook')) !== '' &&
          decryptToken(Cookies.get('url_detailing_rejoice_daybook'))
        ) {
          Cookies.set(
            'url_detailing_rejoice_daybook',
            encryptToken(
              JSON.stringify({
                ...JSON.parse(decryptToken(Cookies?.get('url_detailing_rejoice_daybook'))),
                company: companyData,
              })
            )
          );
        } else {
          Cookies.set('url_detailing_rejoice_daybook', encryptToken(JSON.stringify({ company: companyData })));
        }
        setCompanyInfo(companyData);
        // Do not set the cookie here; it will be set in the second useEffect.
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getCompanyData = () => {
    const newCompanyId = urlSearchParams.get('company_id');
    if (newCompanyId && (!urlDetailing.company || newCompanyId !== urlDetailing.company.company_id.toString())) {
      setLoading(true);
      fetchCompanyAndSetCookie(newCompanyId, userToken);
    } else {
      // do nothing
      setCompanyInfo(urlDetailing.company);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Typography variant="h6" fontSize={17} color="#3B3D90">
        Loading...
      </Typography>
    );
  }

  return !justText ? (
    <Box marginTop={'10px'}>
      <Typography variant="h6" fontSize={15} sx={{ padding: '0px', margin: '0px' }} color="#3B3D90">
        {companyInfo !== null ? companyInfo.company_name : null}
      </Typography>
      <Typography variant="body2" sx={{ color: palette.grey[700], fontWeight: 600 }}>
        {companyInfo !== null ? companyInfo.company_address : null}
      </Typography>
      <Divider sx={{ color: '#f6f6f6', margin: '20px 0px' }} />
    </Box>
  ) : companyInfo !== null ? (
    (companyInfo.company_name, companyInfo.company_address)
  ) : null;
};

export default CompanyTitle;
