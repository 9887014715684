import {
    Paper,
    Table, TableHead, TableRow, TableCell, TableContainer, TableBody, TablePagination, useMediaQuery
} from '@mui/material';
import palette from '../../../../theme/palette';
import { indianFormatNumber } from '../../../../components/functions/data_functions';

const TransactionsCategoryStacked = ({ data, onClickRow, onClickEdit, filterValues, handlePageChange, handleRowsPerPageChange }) => {
    const isMobileScreen = useMediaQuery('(max-width:475px)');

    return (
        <>
            <TableContainer style={{ padding: '0px' }} component={Paper}>
                <Table size='medium'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Particulars</TableCell>
                            <TableCell align='right'>Inward</TableCell>
                            <TableCell align='right'>Outward</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.transaction_info.map((element) => {
                            return (
                                <TableRow key={element.category_id} onClick={() => { onClickRow(element.category_id) }}>
                                    <TableCell style={{ whiteSpace: 'normal' }}>{element.category_name}</TableCell>
                                    <TableCell align='right' style={{ background: parseFloat(element.receipt_total) === 0 ? 'transparent' : palette.success.lighter }}>{parseFloat(element.receipt_total) === 0 ? '' : indianFormatNumber(element.receipt_total)}</TableCell>
                                    <TableCell align='right' style={{ background: parseFloat(element.payment_total) === 0 ? 'transparent' : palette.error.lighter }}>{parseFloat(element.payment_total) === 0 ? '' : indianFormatNumber(element.payment_total)}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
                <TablePagination
                    size={isMobileScreen ? 'small' : 'medium'}
                    padding={isMobileScreen ? '0px' : '16px'}
                    rowsPerPageOptions={[2, 25, 50, 100]}
                    page={filterValues.page - 1}
                    count={parseInt(data.total_rows)}
                    // count={5}
                    rowsPerPage={parseInt(filterValues.limit)}
                    component="div"
                    onPageChange={(e, value) => handlePageChange(e, value)}
                    onRowsPerPageChange={(e) => {
                        handleRowsPerPageChange(e.target.value);
                    }}
                />
            </TableContainer>
        </>
    );
}

export default TransactionsCategoryStacked;