import { configureStore } from '@reduxjs/toolkit';

import companyDataReducer from './slice/companyData';

const store = configureStore({
  reducer: {
    companyData: companyDataReducer,
  },
});

export default store;