import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PercentageSwitch } from '../../../../components/switch/PercentageSwitch';
import { indianFormatNumber } from '../../../../components/functions/data_functions';

const DialogGSTDiscountPurchase = ({ open, index, rowValues, setFieldValue, closeDialog, onTotalCalculated }) => {
  const [values, setValues] = useState(rowValues);

  useEffect(() => {
    // console.log(rowValues);
    setValues(rowValues);
  }, [rowValues]);

  const handleClose = () => {
    closeDialog(false);
  };

  const handleSave = () => {
    const total = calculateTotal();

    // Send calculated total to parent via callback
    // onTotalCalculated(total);

    setFieldValue(`stock_info[${index}].gst`, values.gst);
    setFieldValue(`stock_info[${index}].discount`, values.discount);
    setFieldValue(`stock_info[${index}].other_tax`, values.other_tax);
    // setFieldValue(`stock_info[${index}].total_amount`, total);

    closeDialog(false);
  };

  const handleInputChange = (e, field, isGst = false) => {
    const { name, value } = e.target;

    if (isGst) {
      setValues((prevValues) => ({
        ...prevValues,
        gst: {
          ...prevValues.gst,
          [field]: {
            ...prevValues.gst[field],
            [name]: value,
            percentage_bool: name === 'amount' ? 0 : prevValues.gst[field].percentage_bool, // Automatically set percentage_bool to 0 when entering amount
          },
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [field]: {
          ...prevValues[field],
          [name]: value,
          percentage_bool: name === 'amount' ? 0 : prevValues[field].percentage_bool, // Automatically set percentage_bool to 0 when entering amount
        },
      }));
    }
  };

  const gstFields = ['cgst', 'sgst', 'igst', 'cess'];

  const calculateTotal = () => {
    // Step 1: Calculate the base amount by multiplying MRP with quantity
    let baseAmount = (values.mrp || 0) * (values.qty || 0);

    // Step 2: Calculate discount
    let discountAmount = 0;
    if (values.discount?.percentage_bool === 1) {
      discountAmount = (baseAmount * (values.discount.percentage || 0)) / 100;
    } else {
      discountAmount = values.discount?.amount || 0;
    }

    let discountedAmount = baseAmount - discountAmount;
    if (discountedAmount < 0) discountedAmount = 0;

    let gstAmount = 0;
    let finalAmount = discountedAmount;

    // Step 3: Calculate GST and other tax amounts
    gstFields.forEach((field) => {
      const gstValue = values.gst?.[field] || {};
      if (gstValue.percentage_bool === 1) {
        gstAmount += (finalAmount * (gstValue.percentage || 0)) / 100;
      } else {
        gstAmount += parseFloat(gstValue.amount || 0);
        // console.log(finalAmount);
      }
    });

    // Step 4: Calculate Other Tax
    if (values.other_tax?.percentage_bool === 1) {
      gstAmount += (finalAmount * (values.other_tax.percentage || 0)) / 100;
    } else {
      gstAmount += parseFloat(values.other_tax?.amount || 0);
    }

    // Step 5: Final total amount
    return gstAmount + finalAmount;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Item GST & Discounts</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Percentage/Amount</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Discount</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={values.discount?.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          discount: {
                            ...prev.discount,
                            percentage_bool: e.target.checked ? 1 : 0,
                          },
                        };
                        return newValue;
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={values.discount?.percentage_bool ? 'percentage' : 'amount'}
                    value={
                      values.discount?.percentage_bool
                        ? values.discount?.percentage || ''
                        : values.discount?.amount || ''
                    }
                    onChange={(e) => handleInputChange(e, 'discount')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment:
                        values.discount?.percentage_bool === 1 ? (
                          <InputAdornment position="end">%</InputAdornment>
                        ) : values.discount?.percentage_bool === 0 ? (
                          <InputAdornment position="end">₹</InputAdornment>
                        ) : (
                          ''
                        ),
                      inputProps: { style: { textAlign: 'right' } },
                    }}
                  />
                </TableCell>
              </TableRow>

              {gstFields?.map((field, idx) => {
                const gstValue = values.gst?.[field] || {};
                return (
                  <TableRow key={idx}>
                    <TableCell>{field.toUpperCase()}</TableCell>
                    <TableCell>
                      <PercentageSwitch
                        checked={gstValue?.percentage_bool === 1}
                        onChange={(e) =>
                          setValues((prev) => {
                            const newValue = {
                              ...prev,
                              gst: {
                                ...prev.gst,
                                [field]: {
                                  ...prev.gst[field],
                                  percentage_bool: e.target.checked ? 1 : 0,
                                },
                              },
                            };
                            return newValue;
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={gstValue.percentage_bool ? 'percentage' : 'amount'}
                        value={gstValue.percentage_bool ? gstValue.percentage || '' : gstValue.amount || ''}
                        onChange={(e) => handleInputChange(e, field, true)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment:
                            gstValue.percentage_bool === 1 ? (
                              <InputAdornment position="end">%</InputAdornment>
                            ) : gstValue.percentage_bool === 0 ? (
                              <InputAdornment position="end">₹</InputAdornment>
                            ) : (
                              ''
                            ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell>Other Tax</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={values.other_tax?.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => {
                        const newValue = {
                          ...prev,
                          other_tax: {
                            ...prev.other_tax,
                            percentage_bool: e.target.checked ? 1 : 0,
                          },
                        };
                        return newValue;
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={values.other_tax?.percentage_bool ? 'percentage' : 'amount'}
                    value={
                      values.other_tax?.percentage_bool
                        ? values.other_tax?.percentage || ''
                        : values.other_tax?.amount || ''
                    }
                    onChange={(e) => handleInputChange(e, 'other_tax')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment:
                        values.other_tax?.percentage_bool === 1 ? (
                          <InputAdornment position="end">%</InputAdornment>
                        ) : values.other_tax?.percentage_bool === 0 ? (
                          <InputAdornment position="end">₹</InputAdornment>
                        ) : (
                          ''
                        ),
                      inputProps: { style: { textAlign: 'right' } },
                    }}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                  Total
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>₹{indianFormatNumber(calculateTotal())}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogGSTDiscountPurchase;
