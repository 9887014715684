import React from 'react';
import Cookies from 'js-cookie';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { API_SIGN, DELETE_SPECIPIC_FILE } from '../../../variables/api-variables';
import { apiCall } from '../../functions/apiCall';
import { decryptToken } from '../../security/securityFunctions';


const DeleteMyFiles = ({ open, onClose, fileToDelete, viewDriveItems }) => {

  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}').userToken;
  const companyId = JSON.parse(decryptToken(Cookies.get('url_detailing_rejoice_daybook')) || '{}').company.company_id;
  const urlSearchParams = new URLSearchParams(window.location.search);

  const deleteFile = async () => {
    try {
      const input_data = JSON.stringify({
        function_type: "delete_specific_file",
        user_upload_id: fileToDelete.id,
        company_id: Number(companyId),

        api_signature: API_SIGN,
        jwt_token: userToken,
      });
      console.log(input_data);
      apiCall(input_data, "POST", DELETE_SPECIPIC_FILE, "plain/text")
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            enqueueSnackbar("File deleted successfully.", {
              variant: "success",
              autoHideDuration: 2000,
            });
            onClose();
            viewDriveItems();
          } else {
            enqueueSnackbar("Couldn't delete the file.", {
              variant: "error",
              autoHideDuration: 2000,
            });
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("An error occurred during file deletion.", { variant: "error", autoHideDuration: 2000 });
        });
    } catch (err) {
      console.log(err);
      enqueueSnackbar("An error occurred during file deletion.", { variant: "error", autoHideDuration: 2000 });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: 'red' }}>Delete</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you really want to delete {fileToDelete.filename}?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>No</Button>
        <Button
          onClick={() => {
            deleteFile();
            onClose();
          }}
          autoFocus
          sx={{ color: 'red' }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMyFiles