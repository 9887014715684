import Cookies from 'js-cookie';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardContent, Container, Stack } from '@mui/material';
import { decryptToken } from '../../../components/security/securityFunctions';
import TitleDisplay from '../../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../../components/breadCrumb/BreadCrumbs';
import CompanyTitle from '../../../components/titles/CompanyTitle';
import DisplayItems from './DisplayItems';

const ManageItems = () => {
  const userToken = JSON.parse(decryptToken(Cookies.get('user_data_rejoice_daybook')) || '{}');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    let companyId = searchParams.get('company_id');

    if (userToken.workflow_type === 'individual') {
      if (!companyId || companyId === '') {
        companyId = userToken.company_id;
      }
    }

    return (
        <>
            <Helmet>
                <title>Items | Rejoice Diary</title>
            </Helmet>

            <Container style={{ maxWidth: '1440px' }}>
                <BreadCrumbs path="companies" />
                <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
                    <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
                        <CompanyTitle />
                        <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <TitleDisplay title="Items" />
                            </Stack>
                        </Box>
                        <Box sx={{ margin: '30px 0px' }}>
                            <DisplayItems />
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
};

export default ManageItems;