import React, { useEffect, useState } from 'react';
import CopyrightIcon from '@mui/icons-material/Copyright';
import './footer.css';
import palette from '../../theme/palette';

const Footer = () => {
  const [showFooter, setShowFooter] = useState(true);

  return (
    <footer className="footer" style={{ display: showFooter ? 'block' : 'none', fontSize: 13 }}>
      <p>
        <a
          href="https://rejoicediary.in/copyrightPolicy.html"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: 'black', textDecoration: 'none' }}
        >
          Copyright <CopyrightIcon sx={{ fontSize: 14 }} /> 2024-25
        </a>{' '}
        | <a style={{ color: palette.primary.main }}>Rejoice Diary</a> <br /> Powered By{' '}
        <a
          href="http://rejoicesolutions.in"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: 'orange', textDecoration: 'none' }}
        >
          Rejoice Solutions
        </a>
        <br />
        <a
          href="/privacyPolicyWeb.html"
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: 'black', textDecoration: 'none' }}
        >
          Privacy Policy
        </a>
      </p>
    </footer>
  );
};

export default Footer;
