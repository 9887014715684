import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  Container,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Daybook from './DetailedTransactions/Daybook';
import LedgerView from './DetailedTransactions/LedgerView';
import CashbookView from './DetailedTransactions/CashbookView';

const TransactionsDetailed = ({
  data,
  onClickRow,
  onClickEdit,
  filterValues,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  // default view is daybook_no_filter
  const [displayType, setDisplayType] = useState('daybook');
  const [showOpening, setShowOpening] = useState(false);
  const isMobileScreen = useMediaQuery('(max-width:475px)');

  useEffect(() => {
    if (filterValues.through === 'all') {
      if (
        filterValues.ledgername !== '' &&
        filterValues.ledgername !== null &&
        filterValues.category === 'all' &&
        filterValues.transactionType === 'all' &&
        filterValues.narration === ''
      ) {
        // ledger view
        setDisplayType('ledger');
        setShowOpening(true);
      } else if (
        filterValues.ledgername !== '' &&
        filterValues.ledgername !== null &&
        (filterValues.category !== 'all' || (filterValues.transactionType === 'all' && filterValues.narration !== ''))
      ) {
        // ledger view
        setDisplayType('ledger');
      } else {
        // do something
      }
    } else if (
      (filterValues.ledgername !== '' || filterValues.ledgername !== null) &&
      filterValues.category === 'all' &&
      filterValues.transactionType === 'all' &&
      filterValues.narration === ''
    ) {
      // cashbook
      setDisplayType('cashbook');
      setShowOpening(true);
    } else if (
      (filterValues.ledgername !== '' || filterValues.ledgername !== null) &&
      filterValues.category !== 'all' &&
      filterValues.transactionType !== 'all' &&
      filterValues.narration !== ''
    ) {
      // cashbook
      setDisplayType('cashbook');
      setShowOpening(false);
    }
  }, []);

  return (
    <>
      {' '}
      {/* <Typography variant='h5'>Grouped Ledger Report</Typography> */}
      <TableContainer style={{ padding: '0px' }} component={Paper}>
        {displayType === 'daybook' ? (
          <Daybook data={data} onClickRow={onClickRow} onClickEdit={onClickEdit} showOpening={showOpening} />
        ) : displayType === 'ledger' ? (
          <LedgerView data={data} onClickRow={onClickRow} onClickEdit={onClickEdit} showOpening={showOpening} />
        ) : (
          <CashbookView
            data={data}
            onClickRow={onClickRow}
            onClickEdit={onClickEdit}
            showOpening={showOpening}
            selectedWalletId={filterValues.through}
          />
        )}
        {/* <Table size='medium'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Particulars</TableCell>
                            <TableCell align='right'>Receipt</TableCell>
                            <TableCell align='right'>Payment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.transaction_info.map((element) => {
                            return (
                                <TableRow key={`transaction${element.transaction_id}`} onClick={() => { onClickRow(element.transaction_id) }}>
                                    <TableCell >{element.date_transaction}</TableCell>
                                    <TableCell style={{ whiteSpace: 'normal' }}>{element.ledgername}</TableCell>
                                    <TableCell align='right'>{element.transactiontype === 'receipt' ? (parseFloat(element.amount) > 0) ? element.amount : '' : ''}</TableCell>
                                    <TableCell align='right'>{element.transactiontype === 'payment' ? (parseFloat(element.amount) > 0) ? element.amount : '' : ''}</TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table> */}
        <TablePagination
          size={isMobileScreen ? 'small' : 'medium'}
          padding={isMobileScreen ? '0px' : '16px'}
          rowsPerPageOptions={[25, 50, 100]}
          page={filterValues.page - 1}
          count={parseInt(data.total_rows)}
          // count={5}
          rowsPerPage={parseInt(filterValues.limit)}
          component="div"
          onPageChange={(e, value) => handlePageChange(e, value)}
          onRowsPerPageChange={(e) => {
            handleRowsPerPageChange(e.target.value);
          }}
        />
      </TableContainer>
    </>
  );
};

export default TransactionsDetailed;
