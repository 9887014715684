import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button, Snackbar, Box } from '@mui/material';

const CookieConsentBanner = () => {
  const [open, setOpen] = useState(false);

  // Check if the user has accepted cookies
  useEffect(() => {
    const cookieConsent = Cookies.get('cookie_consent');
    if (!cookieConsent) {
      setOpen(true); // Show the banner if consent is not found
    }
  }, []);

  // Handle consent acceptance
  const handleAcceptCookies = () => {
    // Set a cookie to indicate the user has accepted cookies
    Cookies.set('cookie_consent', 'true', { expires: 365 }); // Consent lasts for 1 year
    setOpen(false); // Hide the banner after acceptance
  };

  return (
    <>
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          message={
            <Box>
              We use cookies to improve your experience on our site. By accepting, you consent to our use of cookies.
            </Box>
          }
          action={
            <Button color="primary" onClick={handleAcceptCookies}>
              Accept All Cookies
            </Button>
          }
        />
      )}
    </>
  );
};

export default CookieConsentBanner;