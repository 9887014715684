export const API_URL = 'https://api.rejoicediary.in';
// export const API_URL = 'https://daybook.rejoicebilldesk.in';
// export const FRONTENDURL = "http://localhost:3000";
export const FRONTENDURL = "http://rejoicediary.in";
export const API_SIGN = 'WR5yGWg6mG';
export const SIGNIN = '/user_management/sign_in.php';
export const REGISTER = '/user_management/create_workspace.php';
export const CHK_USER = '/user_management/check_username_availability.php';

// Delete Account Settings
export const DELETE_PASSWORD_CONFIRM = '/user_management/settings/settings.php';

// Company
export const FETCH_COMPANY = '/company_management/fetch_companies.php';
export const ADD_COMPANY = '/company_management/create_company.php';
export const SPECIFIC_COMPANY = '/company_management/fetch_specific_companies.php';
export const DELETE_COMPANY = '/company_management/delete_company.php';

// Grant Privileges
export const GRANT_PRIVILEGES = "/user_management/grant_privileges.php";
export const FETCH_PRIVILEGES = "/user_management/fetch_user_privileges.php";

// Categories
export const FETCH_CATEGORY = '/transaction_management/ledger_categories/fetch_category.php';
export const ADD_CATEGORY = '/transaction_management/ledger_categories/create_category.php';
export const DELETE_LEDGER_CATEGORY = "/transaction_management/ledger_categories/delete_category.php"

// Ledger Heads
export const FETCH_LEDGERHEADS = '/transaction_management/ledger_categories/fetch_ledgerheads.php';

// Transactions
export const ADD_TRANSACTION = '/transaction_management/transaction/create_transaction.php';
export const ALTER_TRANSACTION = '/transaction_management/transaction/alter_transaction.php';
export const FETCH_TRANSACTION = '/transaction_management/transaction/fetch_transaction.php';
export const FETCH_SPECIFIC_TRANSACTION = '/transaction_management/transaction/fetch_specific_transaction.php';
export const DELETE_TRANSATION = "/transaction_management/transaction/delete_transaction.php";
export const CREATE_SALES_TRANSACTION = '/transaction_management/transaction/create_transaction_sales.php';
export const ALTER_SALES_TRANSACTION = '/transaction_management/transaction/alter_transaction_sales.php';
export const FETCH_TRANSACTION_SALES = '/transaction_management/transaction/fetch_transaction_sales.php';
export const LAST_COMPANY_DATA = '/transaction_management/transaction/get_last_company.php';
export const LAST_BILLING_SHIPPING_DATA = '/transaction_management/transaction/get_billing_shipping.php';

export const CREATE_PURCHASE_TRANSACTION = '/transaction_management/transaction/create_transaction_purchase.php';
export const ALTER_PURCHASE_TRANSACTION = '/transaction_management/transaction/alter_transaction_purchase.php';

// Stock
export const FETCH_STOCK = '/transaction_management/stock_management/fetch_stock.php';


// Users
export const ADD_USER = '/user_management/create_user.php'
export const FETCH_USER = "/user_management/fetch_users.php"
export const ALTER_USER = "/user_management/alter_user.php"
export const SPECIFIC_USER = '/user_management/fetch_specific_user.php';

// Fetch Function Codes and Module
export const FETCH_CODES = "/user_management/profiles/fetch_function_codes.php";

// Function Codes
export const FETCH_FUNCTION_CODES = "/user_management/profiles/fetch_function_codes.php";

// Reminder 
export const CREATE_REMINDER = "/user_management/reminder/create_reminders.php"
export const FETCH_REMINDER = "/user_management/reminder/fetch_reminders.php"
export const FETCH_SPECIFIC_REMINDER = "/user_management/reminder/fetch_specific_reminder.php"
export const UPDATE_REMINDER_STATUS = "/user_management/reminder/update_reminders_status.php"
export const DELETE_REMINDER = "/user_management/reminder/delete_reminder.php"

// Forgot Password
export const FORGOT_PASSWORD = "/user_management/forgot_password/forgot_password.php"

// Wallet api
export const FETCH_WALLET = "/transaction_management/payment_wallets/fetch_payment_wallets.php"
export const CREATE_UPDATE_WALLET = "/transaction_management/payment_wallets/create_payment_wallets.php";
export const DELETE_SPECIFIC_WALLET = "/transaction_management/payment_wallets/delete_payment_wallets.php";

// Ledgers
export const FETCH_LEDGERS = '/transaction_management/transaction/fetch_ledgers.php';

// file Upload
export const UPLOAD_FILES = "/drive_management/upload_to_drive.php";
export const FETCH_ALL_CATEGORY = "/transaction_management/ledger_categories/fetch_category.php"
export const VIEWS_DRIVE_FILES = "/drive_management/view_drive_items.php"
export const UPDATE_DRIVE_FILES = "/drive_management/update_to_drive_specific_file.php"
export const DELETE_SPECIPIC_FILE = "/drive_management/delete_drive_item.php"
export const ADD_CATEGORY_IN_FILES = "/transaction_management/upload_categories/upload_category.php"
export const FETCH_FILES_CATEGORY = "/transaction_management/upload_categories/fetch_category.php"
export const UPDATE_FILES_CATEGORY = "/transaction_management/upload_categories/upload_category.php"
export const DELETE_FILES_CATEGORY = "/transaction_management/upload_categories/delete_category.php"

// Transaction ledger 
export const FETCH_TRANSACTION_LEDGER = "/transaction_management/transaction/fetch_ledgers.php"
export const EDIT_TRANSACTION_LEDGER = "/transaction_management/transaction/edit_ledger.php"

// Transaction items
export const EDIT_TRANSACTION_ITEMS = "/transaction_management/stock_management/create_stock.php"
export const FETCH_TRANSACTION_ITEMS = '/transaction_management/stock_management/fetch_stock.php';
export const FETCH_SPECIFIC_TRANSACTION_ITEMS = '/transaction_management/stock_management/fetch_specific_stock.php';

// Dashboard and Stats
export const FETCH_DASHBOARD_STATS = "/dashboard/dashboard_stats.php"
export const FETCH_DASHBOARD_CHARTS = "/dashboard/dashboard_chart.php"

// Upgrade to enterprise
export const UPGRADE_TO_ENTERPRISE = "/user_management/upgrade_enterprise/upgrade_enterprise.php";

// Support Form
export const SUPPORT_FORM = '/user_management/mail_send.php';