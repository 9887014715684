import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardContent, Container, Dialog, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import TitleDisplay from '../../components/title_display/TitleDisplay';
import BreadCrumbs from '../../components/breadCrumb/BreadCrumbs';
import AddReminder from './AddReminder';
import DisplayReminders from './DisplayReminders';

const ManageReminders = () => {
  const navigate = useNavigate();
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [reminderComponent, setReminderComponent] = useState(null);
  const [reloadDisplayReminderList, setReloadDisplayReminderList] = useState(0);

  const reloadDisplayList = () => {
    setReloadDisplayReminderList(reloadDisplayReminderList + 1);
  }

  const displayAddReminder = (x = null) => {
    setShowAddReminder(true);
    if (x === null) {
      // create
      setReminderComponent(
        <AddReminder
          close={() => {
            setShowAddReminder(false);
          }}
          displayAddReminder={displayAddReminder}
          reloadDisplayList={reloadDisplayList}
        />
      );
    } else {
      setReminderComponent(
        <AddReminder
          reminderId={x}
          close={() => {
            setShowAddReminder(false);
          }}
          displayAddReminder={displayAddReminder}
          reloadDisplayList={reloadDisplayList}
        />
      );
    }
  };

  return (
    <>
      <Helmet>
        {' '}
        <title>Reminders | Rejoice Diary</title>
      </Helmet>
      <Container style={{ maxWidth: '1440px' }}>
        <BreadCrumbs path={'reminders'} />
        <Card variant="outlined" sx={{ boxShadow: 3, paddingBottom: 0 }}>
          <CardContent sx={{ paddingBottom: 1, paddingTop: 2 }}>
            <Box sx={{ p: 0, margin: '10px 0px 10px 0px' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TitleDisplay title="Reminders" />
                <Button variant="contained" size="medium" onClick={() => displayAddReminder()}>
                  <AddIcon />
                  &nbsp; Add
                </Button>
              </Stack>
            </Box>

            {
              // code for add reminder
              showAddReminder ? (
                <Dialog
                  open
                  fullScreen
                  sx={{ padding: '0px' }}
                  onClose={() => {
                    setShowAddReminder(false);
                  }}
                >
                  {reminderComponent}
                </Dialog>
              ) : null
            }

            <Box sx={{ margin: '30px 0px' }}>
              <DisplayReminders displayAddReminder={displayAddReminder} key={reloadDisplayReminderList} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ManageReminders;
