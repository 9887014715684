import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,

} from "@mui/material";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { formatBytes } from "../../../components/functions/data_functions";
// import { formatBytes } from "src/functions/data-functions";

const Notification = ({ files, progress }) => {
  const [visible, setVisible] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Card style={{ position: "fixed", bottom: "80px", right: "20px", maxWidth: "300px", backgroundColor: "#f0f0f0" }}>
      <List dense>
        {files.map((file, index) => (
          <ListItem key={index} style={{ alignItems: "flex-start" }}>
            <Stack

              spacing={1}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "flex-start",
                flexDirection: "row"
              }}
            >

              <ListItemText
                primary={file.name}
                secondary={`Size: ${formatBytes(file.size)}`}
                primaryTypographyProps={{ style: { fontSize: "14px" } }}
                secondaryTypographyProps={{ style: { fontSize: "11px" } }}
              />
              {progress < 100 ? (
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Typography style={{ marginRight: 10 }}>{`${progress}%`}</Typography>
                  <CircularProgress size={20} variant="determinate" value={progress} />
                </Box>
              ) : (
                <CheckCircleOutlineRoundedIcon sx={{ color: "green" }} />

              )}
            </Stack>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default Notification;
