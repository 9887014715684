import { formatPhoneNumberIntl } from "react-phone-number-input"

export const displayMobileNumber = (x) => {
    return <>
        <p>
            {formatPhoneNumberIntl(x)}
        </p>
    </>;

}

export function formatTimeString(timeString) {
  let timeParts = timeString.split(":");
  let hours = parseInt(timeParts[0], 10);
  let minutes = parseInt(timeParts[1], 10);
  let seconds = parseInt(timeParts[2], 10);

  let jsTime = new Date();
  jsTime.setHours(hours);
  jsTime.setMinutes(minutes);
  jsTime.setSeconds(seconds);
  return jsTime;
}

export function formatTime(timeString) {
  const [hours, minutes] = timeString.split(':');
  return `${hours}:${minutes}`;
}

export const getDateYmd = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const localDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = localDate.split('/');
    return `${year}-${month}-${day}`;
}

export const getDatedmY = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const localDate = new Intl.DateTimeFormat('en-US', options).format(date);
    const [month, day, year] = localDate.split('/');
    return `${day}-${month}-${year}`;
}

export function getDayNameShort(x) {
    switch (x) {
      case 0:
        return "Sun";
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      default:
        return "";
    }
  }
  
  export function getDayName(x) {
    switch (x) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "";
    }
  }