import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  InputAdornment,
  TableHead,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PercentageSwitch } from '../../../../components/switch/PercentageSwitch';
import { indianFormatNumber } from '../../../../components/functions/data_functions';

const DialogGSTDiscountAllPurchase = ({ open, stockInfo, setFieldValue, closeDialog, totalGSTdiscount, onTotalApplied }) => {
  const [values, setValues] = useState(totalGSTdiscount);

  useEffect(() => {
    if (open) {
      setValues(totalGSTdiscount);
    }
  }, [open, stockInfo, totalGSTdiscount]);

  const gstFields = ['cgst', 'sgst', 'igst', 'cess'];

  const calculateTotal = () => {
    let totalBeforeDiscount = 0;
    stockInfo.forEach((item) => {
      totalBeforeDiscount += item.mrp * item.qty;
    });

    const discountAmount =
      values.discount.percentage_bool === 1
        ? (totalBeforeDiscount * values.discount.percentage) / 100
        : parseFloat(values.discount.amount || 0);

    const discountedTotal = totalBeforeDiscount - discountAmount;

    const gstAmount = gstFields.reduce((acc, field) => {
      const gstValue = values.gst[field] || {};
      return (
        acc +
        (gstValue.percentage_bool === 1
          ? (discountedTotal * gstValue.percentage) / 100
          : parseFloat(gstValue.amount || 0))
      );
    }, 0);

    const otherTaxAmount =
      values.other_tax.percentage_bool === 1
        ? (discountedTotal * values.other_tax.percentage) / 100
        : parseFloat(values.other_tax.amount || 0);

    // onTotalApplied(discountedTotal + gstAmount + otherTaxAmount);
    return discountedTotal + gstAmount + otherTaxAmount;
  };

  const handleInputChange = (type, field) => (e) => {
    setValues((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: e.target.value,
        percentage_bool: field === 'amount' ? 0 : prev[type].percentage_bool,
      },
    }));
  };

  const handleAmountChange = (type, key, field) => (e) => {
    setValues((prev) => {
      const newValue = {
        ...prev,
        [type]: {
          ...prev[type],
          [key]: {
            ...prev[type][key],
            [field]: e.target.value,
            percentage_bool: field === 'amount' ? 0 : prev[type][key].percentage_bool,
          },
        },
      };
      return newValue;
    });
  };

  const handleApplyToAll = () => {
    const { gst, discount, other_tax } = values;

    stockInfo.forEach((item) => {
      const itemTotal = item.mrp * item.qty;
      const discountAmount =
        discount.percentage_bool === 1 ? (itemTotal * discount.percentage) / 100 : parseFloat(discount.amount || 0);
      const discountedTotal = itemTotal - discountAmount;
      const gstAmount = gstFields.reduce((acc, field) => {
        const gstValue = gst[field] || {};
        return (
          acc +
          (gstValue.percentage_bool === 1
            ? (discountedTotal * gstValue.percentage) / 100
            : parseFloat(gstValue.amount || 0))
        );
      }, 0);
      const otherTaxAmount =
        other_tax.percentage_bool === 1
          ? (discountedTotal * other_tax.percentage) / 100
          : parseFloat(other_tax.amount || 0);

      const totalAmount = discountedTotal + gstAmount + otherTaxAmount;
      return { ...item, totalAmount };
    });
    // const total = calculateTotal();
    // onTotalApplied(total);

    setFieldValue('total_gst_discount', { gst, discount, other_tax });
    closeDialog(false);
  };

  return (
    <Dialog open={open} onClose={() => closeDialog(false)}>
      <DialogTitle>Apply GST & Discount to All Items</DialogTitle>
      <DialogContent dividers>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Percentage/Amount</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Discount</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={values.discount.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        discount: { ...prev.discount, percentage_bool: e.target.checked ? 1 : 0 },
                      }))
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={values.discount.percentage_bool ? 'percentage' : 'amount'}
                    value={
                      values.discount.percentage_bool ? values.discount.percentage || '' : values.discount.amount || ''
                    }
                    onChange={handleInputChange('discount', values.discount.percentage_bool ? 'percentage' : 'amount')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: values.discount.percentage_bool ? (
                        <InputAdornment position="end">%</InputAdornment>
                      ) : (
                        <InputAdornment position="end">₹</InputAdornment>
                      ),
                      inputProps: { style: { textAlign: 'right' } },
                    }}
                  />
                </TableCell>
              </TableRow>

              {gstFields.map((field, idx) => {
                const gstValue = values.gst[field] || {};
                return (
                  <TableRow key={idx}>
                    <TableCell>{field.toUpperCase()}</TableCell>
                    <TableCell>
                      <PercentageSwitch
                        checked={gstValue?.percentage_bool === 1}
                        onChange={(e) =>
                          setValues((prev) => ({
                            ...prev,
                            gst: {
                              ...prev.gst,
                              [field]: {
                                ...prev.gst[field],
                                percentage_bool: e.target.checked ? 1 : 0,
                              },
                            },
                          }))
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name={gstValue.percentage_bool === 1 ? 'percentage' : 'amount'}
                        value={gstValue.percentage_bool ? gstValue.percentage || '' : gstValue.amount || ''}
                        // onChange={handleInputChange('gst', field)}
                        onChange={handleAmountChange(
                          'gst',
                          field,
                          values.gst[field]?.percentage_bool === 1 ? 'percentage' : 'amount'
                        )}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment:
                            gstValue.percentage_bool === 1 ? (
                              <InputAdornment position="end">%</InputAdornment>
                            ) : (
                              <InputAdornment position="end">₹</InputAdornment>
                            ),
                          inputProps: { style: { textAlign: 'right' } },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell>Other Tax</TableCell>
                <TableCell>
                  <PercentageSwitch
                    checked={values.other_tax.percentage_bool === 1}
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        other_tax: {
                          ...prev.other_tax,
                          percentage_bool: e.target.checked ? 1 : 0,
                        },
                      }))
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={values.other_tax.percentage_bool === 1 ? 'percentage' : 'amount'}
                    value={
                      values.other_tax.percentage_bool
                        ? values.other_tax.percentage || ''
                        : values.other_tax.amount || ''
                    }
                    onChange={handleInputChange(
                      'other_tax',
                      values.other_tax.percentage_bool ? 'percentage' : 'amount'
                    )}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment:
                        values.other_tax.percentage_bool === 1 ? (
                          <InputAdornment position="end">%</InputAdornment>
                        ) : (
                          <InputAdornment position="end">₹</InputAdornment>
                        ),
                      inputProps: { style: { textAlign: 'right' } },
                    }}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                  Total
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                  ₹{indianFormatNumber(calculateTotal())}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(false)}>Cancel</Button>
        <Button onClick={handleApplyToAll}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGSTDiscountAllPurchase;