import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   // companyId: '',
//   lastCompanyData: {
//     companyName: '',
//     companyAddress: '',
//     companyEmail: '',
//     companyMobile: '',
//     companyGst: '',
//     termsConditions: '',
//   },
//   // Add any other initial state properties here
// };

// const companyData = createSlice({
//   name: 'companyData',
//   initialState,
//   reducers: {
//     setSelectCompanyData(state, action) {
//       const { company_info } = action.payload;
//       if (company_info.length > 0) {
//         // Assuming you want to use the first company in the list
//         const company = company_info[0];
//         state.lastCompanyData = {
//           ...state.lastCompanyData,
//           companyName: company.company_name,
//           companyAddress: company.company_address,
//           companyEmail: company.company_email,
//           companyMobile: company.company_mobile,
//           companyGst: company.company_gst,
//           termsConditions: company.terms_conditions || '', // Update this line
//         };
//       }
//     },
//   },
// });

// //   reducers: {
// //     setSelectCompanyData(state, action) {
// //       return {
// //         ...state,
// //         ...action.payload,
// //       };
// //     },
// //     invalidateCompanyData(state) {
// //       return initialState;
// //     },
// //   },

// export const { setSelectCompanyData, invalidateCompanyData } = companyData.actions;

// export default companyData.reducer;
// Redux Slice

const initialState = {
  lastCompanyData: {
    companyName: '',
    companyAddress: '',
    companyEmail: '',
    companyMobile: '',
    companyGst: '',
  },
  company_info: [],
};

const companyData = createSlice({
  name: 'companyData',
  initialState,
  reducers: {
    // Reducer for setting lastCompanyData
    setSelectCompanyData: (state, action) => {
      const { lastCompanyData } = action.payload;

      if (lastCompanyData) {
        state.lastCompanyData = {
          companyName: lastCompanyData?.companyName || '',
          companyAddress: lastCompanyData?.companyAddress || '',
          companyEmail: lastCompanyData?.companyEmail || '',
          companyMobile: lastCompanyData?.companyMobile || '',
          companyGst: lastCompanyData?.companyGst || '',
        };
      }

      //   if (company_info?.length > 0) {
      //     // Set the termsConditions separately if needed for specific company logic
      //     console.log(company_info);
      //     state.company_info = company_info.map((company) => ({
      //       companyId: company.company_id,
      //       termsConditions: company.terms_conditions || '',
      //     }));
      //     console.log(state.company_info);
      //   } else {
      //     state.company_info = []; // Ensure it's an empty array if no company info exists
      //   }
    },
    setSelectTermsConditions: (state, action) => {
      const { company_info } = action.payload;

      if (company_info?.length > 0) {
        console.log(company_info);
        state.company_info = company_info.map((company) => ({
          companyId: company.company_id,
          termsConditions: company.terms_conditions || '',
        }));
        console.log(state.company_info);
      } else {
        state.company_info = []; // Ensure it's an empty array if no company info exists
      }
    },
  },
});

export const { setSelectCompanyData, setSelectTermsConditions } = companyData.actions;
export default companyData.reducer;
